import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { SchemaFieldTitle } from 'components';
import islatlong from 'validator/lib/isLatLong';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';

const LatLongInput = ({ value, onChange, precision, format, separator, disabled }) => {
    const [latLongValue, setLatLongValue] = useState(value);

    const onLatLongValueChange = (event) => {
        const inputValue = event.target.value.trim();
        const values = inputValue.split(separator);

        if (values.length === 2) {
            const [lat, long] = values;
            const formattedLat = parseFloat(lat).toFixed(precision);
            const formattedLong = parseFloat(long).toFixed(precision);
            const newValue = `${formattedLat}${separator}${formattedLong}`;
            setLatLongValue(newValue);
            onChange(newValue);
        } else {
            setLatLongValue(inputValue);
            onChange(inputValue);
        }
    };

    return (
        <Input
            value={latLongValue}
            onChange={onLatLongValueChange}
            placeholder={`Enter latitude${separator}longitude in ${format} format`}
            disabled={disabled}
        />
    );
};

function FormLatLongField({
    fieldErrors,
    fieldId,
    name,
    description,
    isRequired,
    precision,
    format,
    separator,
    disabled = false,
}) {
    const validateLatLong = (_, value) => {
        if (value) {
            const values = value.split(separator);

            if (values.length !== 2) {
                return Promise.reject(
                    `Please enter both latitude and longitude separated by '${separator}'`
                );
            }

            const [lat, long] = values;
            const parsedLat = parseFloat(lat);
            const parsedLong = parseFloat(long);

            if (isNaN(parsedLat) || isNaN(parsedLong)) {
                return Promise.reject('Latitude and longitude must be numeric values');
            }

            if (parsedLat < -90 || parsedLat > 90) {
                return Promise.reject(
                    'Latitude must be between -90 and 90 degrees'
                );
            }

            if (parsedLong < -180 || parsedLong > 180) {
                return Promise.reject(
                    'Longitude must be between -180 and 180 degrees'
                );
            }

            if (!islatlong(`${parsedLat}${separator}${parsedLong}`)) {
                return Promise.reject(
                    `Invalid format. Please enter latitude and longitude in '${format}' format with a precision of ${precision} decimal places`
                );
            }

            return Promise.resolve();
        }

        return Promise.resolve();
    };

    return (
        <Form.Item
            key={fieldId}
            name={[fieldId]}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={[]}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {disabled && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
            }
            tooltip={description}
            rules={[
                {
                    required: isRequired,
                    message: 'Latitude and longitude are required',
                },
                {
                    validator: validateLatLong,
                },
            ]}
            {...fieldErrors}
        >
            <LatLongInput
                precision={precision}
                format={format}
                separator={separator}
                disabled={disabled}
            />
        </Form.Item>
    );
}

export default FormLatLongField;
