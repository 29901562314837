import React, { useState } from 'react';
import { Input } from 'antd';
import isFloat from 'validator/lib/isFloat';
import { Form } from 'antd';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';
import SchemaFieldTitle from '../SchemaFieldTitle';

const LatitudeInput = ({ value, onChange, precision, disabled }) => {
    const [latitude, setLatitude] = useState(value);

    const onLatitudeChange = (event) => {
        const inputValue = event.target.value.trim();
        if (inputValue === '') {
            setLatitude('');
            onChange('');
            return;
        }
        const formattedLat = parseFloat(inputValue).toFixed(precision);
        setLatitude(formattedLat);
        onChange(formattedLat);
    };

    return (
        <Input
            value={latitude}
            onChange={onLatitudeChange}
            placeholder={`Enter latitude with precision of ${precision} decimal places`}
            disabled={disabled}
        />
    );
};
function FormLatitudeField({
    fieldErrors,
    fieldId,
    name,
    description,
    isRequired,
    precision,
    disabled = false,
    isPII,
}) {
    const validateLatitude = (_, value) => {
        if (value) {
            const parsedLat = parseFloat(value);

            if (isNaN(parsedLat)) {
                return Promise.reject('Latitude must be a numeric value');
            }

            if (parsedLat < -90 || parsedLat > 90) {
                return Promise.reject('Latitude must be between -90 and 90 degrees');
            }
            
            const decimalPart = value.toString().split('.')[1];
            const decimalPlaces = decimalPart ? decimalPart.length : 0;
            if (decimalPlaces > precision) {
                return Promise.reject(`Latitude cannot have more than ${precision} decimal places`);
            }

            return Promise.resolve();
        }

        return Promise.resolve();
    };

    return (
        <Form.Item
            key={fieldId}
            name={[fieldId]}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <SchemaFieldTitle
                                        name={name}
                                        tags={[]}
                                        orientation="horizontal"
                                        tagSummary={false}
                                    />
                                    {disabled && (
                                        <Tooltip title="Masked data due to PII restriction"> 
                                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                                        </Tooltip>
                                    )}
                                </div>
            }
            tooltip={description}
            rules={[
                {
                    required: isRequired,
                    message: 'Latitude is required',
                },
                {
                    validator: validateLatitude,
                },
            ]}
            {...fieldErrors}
        >
            <LatitudeInput precision={precision} disabled={disabled || isPII} />
        </Form.Item>
    );
}

export default FormLatitudeField;

