import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { withRouter } from "react-router";
import _ from 'lodash';
import { showError, showSuccess } from 'common/ToastNotifications';
import { Select } from "antd";
import CypressTestIds from '../cypress/CypressTestIds';

const { Option } = Select;

class DataTagSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingDataTags: true,
            businessAreaId: this.props.businessAreaId
                || (this.props.match &&
                    this.props.match.params &&
                    this.props.match.params.businessarea &&
                    this.props.userBusinessAreas &&
                    this.props.userBusinessAreas[this.props.match.params.businessarea.toLowerCase()] &&
                    this.props.userBusinessAreas[this.props.match.params.businessarea.toLowerCase()].businessAreaId)
        }
    }

    componentWillMount() {
        if (this.state.businessAreaId) {
            this.props.getDataTagsByBusinessAreaId(this.state.businessAreaId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fetchDataTagsOnBusinessAreaChanged(prevProps);
        this.getDataTagsByBusinessAreaIdResultChanged(prevProps);
    }

    fetchDataTagsOnBusinessAreaChanged(prevProps) {
        if (this.props.businessAreaId && this.props.businessAreaId !== prevProps.businessAreaId) {
            this.setState({
                fetchingDataTags: true,
                businessAreaId: this.props.businessAreaId
            });
            this.props.getDataTagsByBusinessAreaId(this.props.businessAreaId);
        }
    }

    getDataTagsByBusinessAreaIdResultChanged = (prevProps) => {
        if (this.props.getDataTagsByBusinessAreaIdResult && this.props.getDataTagsByBusinessAreaIdResult !== prevProps.getDataTagsByBusinessAreaIdResult
            && this.props.getDataTagsByBusinessAreaIdResult.businessAreaId === this.state.businessAreaId) {
            if (!this.props.getDataTagsByBusinessAreaIdResult.success) {
                if (this.props.getDataTagsByBusinessAreaIdResult.code !== "PERMISSION_DENIED") {
                    showError("Could not able to get data tags at this moment.");
                }
            }
            this.setState({
                fetchingDataTags: false
            });
        }
    }

    onDataTagChange = (selectedValues) => {
        let selectedDataTags = [];
        for (let value of selectedValues) {
            if (this.props.dataTagsByBusinessAreaIdResult.ItemsDict && this.props.dataTagsByBusinessAreaIdResult.ItemsDict[value]) {
                let selectedDataTag = this.props.dataTagsByBusinessAreaIdResult.ItemsDict[value];
                selectedDataTags.push({ tagName: selectedDataTag.tagName, tagId: selectedDataTag.tagId });
            }
        }
        this.props.onChange(selectedDataTags);
    }

    render() {
        return <Select
            defaultValue={!this.state.fetchingDataTags && this.props.dataTagsByBusinessAreaIdResult.Items && this.props.dataTagsByBusinessAreaIdResult.Items.length > 0 ? (this.props.defaultValue && this.props.defaultValue.map(value => value.tagId)) : []}
            value={!this.state.fetchingDataTags && this.props.dataTagsByBusinessAreaIdResult.Items && this.props.dataTagsByBusinessAreaIdResult.Items.length > 0 ? (this.props.value && this.props.value.map(value => value.tagId)) : []}
            onChange={this.onDataTagChange}
            disabled={this.props.disabled}
            mode={this.props.mode}
            placeholder={this.props.placeholder}
            loading={this.state.fetchingDataTags}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_DATA_DATA_TAGS_SELECT}>
            {
                !this.state.fetchingDataTags && this.props.dataTagsByBusinessAreaIdResult && this.props.dataTagsByBusinessAreaIdResult.Items
                    ?
                    this.props.dataTagsByBusinessAreaIdResult.Items.map((dataTag, index) => <Option key={dataTag.tagId} value={dataTag.tagId}>{`${dataTag.tagName}${dataTag.tagDescription && ` (${dataTag.tagDescription})`}`}</Option>)
                    :
                    <></>
            }
        </Select>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userBusinessAreas: state.schemaModels.userBusinessAreas,
        getDataTagsByBusinessAreaIdResult: state.dataTags.getDataTagsByBusinessAreaIdResult,
        dataTagsByBusinessAreaIdResult: state.dataTags.dataTagsByBusinessAreaIdResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataTagsByBusinessAreaId: (businessAreaId) => dispatch(actions.getDataTagsByBusinessAreaIdRequest(businessAreaId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTagSelect));