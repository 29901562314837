import { Handle, Node, NodeProps } from "@xyflow/react";
import { Select } from "antd";
import convertFormulaBuilderToString from "common/convertFormulaBuilderToString";
import { useAppSelector } from "hooks";
import { Parser } from "hot-formula-parser";
import React, { memo } from "react";
import FormulaBuilder from "../FormulaBuilder";
import insertBuilderFunction from "./utils/insertBuilderFunction";

export type IDataMappedNodeDataType = {
    sourceColumns: string[];
    sourceIds: string[];
    targetIds: string[];
    field: IDataMappingField;
    onDataChange: (data: IDataMappingField) => void;
};

export type DataMappedNodeType = Node<
    IDataMappedNodeDataType,
    "dataMappedNode"
>;

type IDataMappingOption = {
    value: IDataMappingType;
    label: string;
};

const dataMappingOptions: IDataMappingOption[] = [
    {
        value: "DATA_COLUMN",
        label: "Data column",
    },
    {
        value: "FORMULA",
        label: "Formula",
    },
];

function DataMappedNode({
    data,
    isConnectable,
    targetPosition,
    sourcePosition,
}: NodeProps<DataMappedNodeType>) {
    const [mappingType, setMappingType] = React.useState<IDataMappingType>(
        data.field.mappingType,
    );
    const { data: sourceData, row } = useAppSelector(
        (state) => state.dataMapperFlow,
    );
    const [builder, setBuilder] = React.useState<IFormulaBuilder | undefined>(
        data.field.builder,
    );
    const [sampleDataCol, setSampleDataCol] = React.useState<string>("");
    const [sampleComputed, setSampleComputed] = React.useState<string>("");

    React.useEffect(() => {
        setMappingType(data.field.mappingType);
    }, [data.field.mappingType]);

    React.useEffect(() => {
        if (!sourceData || !data.sourceColumns) {
            return;
        }

        if (mappingType === "DATA_COLUMN") {
            const samples: string[] = [];
            data.sourceColumns.forEach((sc) => {
                if (sourceData.length > 0 && sourceData[row][sc]) {
                    samples.push(sourceData[row][sc]);
                }
            });
            setSampleDataCol(samples.join(" "));
        }
    }, [data, sourceData, row, mappingType]);

    React.useEffect(() => {
        if (!sourceData || !builder) {
            return;
        }

        if (mappingType === "FORMULA") {
            const derivedFormula = convertFormulaBuilderToString(builder);
            const parser = new Parser();
            derivedFormula.variables.forEach((variable) => {
                parser.setVariable(
                    variable.name,
                    sourceData[row][variable.value] || "Unknown Variable",
                );
            });
            const computed = parser.parse(derivedFormula.formula);

            setSampleComputed(`${computed.result}`);
        }
    }, [builder, mappingType, row, sourceData]);

    const handleBuilder = React.useCallback(
        (newFunction: IFormulaFunction, parentId?: string) => {
            if (!builder) {
                const newBuilder: IFormulaBuilder = {
                    formulaFunction: newFunction,
                };
                setBuilder(newBuilder);

                const derivedFormula =
                    convertFormulaBuilderToString(newBuilder);

                data.onDataChange({
                    ...data.field,
                    mappingType: "FORMULA",
                    value: derivedFormula.formula,
                    builder: builder,
                    variables: derivedFormula.variables,
                });
            } else {
                const changedBuilder: IFormulaBuilder =
                    structuredClone(builder);
                if (parentId) {
                    insertBuilderFunction(
                        changedBuilder,
                        parentId,
                        newFunction,
                    );
                }
                setBuilder(changedBuilder);

                const derivedFormula =
                    convertFormulaBuilderToString(changedBuilder);

                data.onDataChange({
                    ...data.field,
                    mappingType: "FORMULA",
                    value: derivedFormula.formula,
                    builder: builder,
                    variables: derivedFormula.variables,
                });
            }
        },
        [builder, data],
    );

    const handleResetBuilder = React.useCallback(() => {
        setBuilder(undefined);
        setSampleComputed("");
    }, []);

    return (
        <>
            {isConnectable && targetPosition && (
                <Handle
                    id="handle-mapped-input"
                    type="target"
                    position={targetPosition}
                    className="!-left-2 !top-8 !size-4 !bg-slate-500"
                />
            )}
            <div className="flex min-h-16 min-w-40 flex-row items-start gap-3 rounded-lg bg-slate-100 p-3">
                <div className="flex flex-col gap-2">
                    {data.sourceIds.length === 1 ? (
                        <div className="flex flex-row items-center gap-2">
                            <span className="text-gray-600">Mapping Type:</span>
                            <Select
                                className="nodrag flex-1"
                                size="small"
                                defaultValue="DATA_COLUMN"
                                onChange={(value: IDataMappingType) =>
                                    setMappingType(value)
                                }
                                value={mappingType}
                                options={dataMappingOptions}
                            />
                        </div>
                    ) : (
                        <span
                            style={{
                                fontSize: "0.8em",
                                alignSelf: "flex-start",
                                color: "#999",
                            }}
                        >
                            Mapping type:{" "}
                            <span className="text-gray-600">
                                {data.field.mappingType}
                            </span>
                        </span>
                    )}
                    {mappingType === "FORMULA" && (
                        <FormulaBuilder
                            sourceColumns={data.sourceColumns}
                            builder={builder}
                            onSelect={handleBuilder}
                            onReset={handleResetBuilder}
                        />
                    )}
                    {((mappingType === "FORMULA" && sampleComputed) ||
                        (mappingType === "DATA_COLUMN" && sampleDataCol)) && (
                        <div className="flex flex-row gap-2 self-stretch">
                            <span className="text-gray-600">Sample:</span>
                            <span className="flex-1">
                                {mappingType === "FORMULA"
                                    ? sampleComputed
                                    : sampleDataCol}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {isConnectable && sourcePosition && (
                <Handle
                    id="handle-mapped-output"
                    type="source"
                    position={sourcePosition}
                    className="!-right-2 !top-8 !size-4 !bg-slate-500"
                />
            )}
        </>
    );
}

export default memo(DataMappedNode);
