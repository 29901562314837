import React from 'react';
import { Form, Space, Button, Table, Checkbox, Row, Col, Switch, Tooltip } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { ProhibitedArea, PaginatedListContainer, FullHeightContainerLayout } from "components";
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Column } = Table;

const BusinessAreaList = ({ permission, businessAreaList, onAddBusinessArea, onViewBusinessArea, onEditBusinessArea, onDeleteBusinessArea, onShowBusinessAreaHistory, onActivateDeactivateBusinessArea, onPageSelect }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={true}
                    content={
                        <PaginatedListContainer
                            items={businessAreaList}
                            render={({ paginatedItems }) => (
                                <Table size="small"
                                    rowKey="businessAreaId"
                                    dataSource={paginatedItems}
                                    scroll={{ y: "100vh" }}
                                    className="container-height-100"
                                    pagination={false}>
                                    <Column
                                        width="6em"
                                        render={(value, businessArea, index) => <>
                                        <Tooltip title ='Edit Business Area'>
                                            <Icon
                                                disabled={businessArea.type === "SYSTEM" || !permission.canEdit}
                                                color='blue'
                                                name='pencil'
                                                className="action-cursor"
                                                data-testid={CypressTestIds.EDIT_BUSINESS_AREA_BUTTON}
                                                onClick={() => onEditBusinessArea(businessArea.businessAreaId)} /> 
                                                </Tooltip>
                                                <Tooltip title ='View Business Area'>
                                            <Icon
                                                disabled={!permission.canView}
                                                color='blue'
                                                name='eye'
                                                className="action-cursor"
                                                data-testid={CypressTestIds.VIEW_BUSINESS_AREA_BUTTON}
                                                onClick={() => onViewBusinessArea(businessArea.businessAreaId)} />
                                                </Tooltip>
                                                <Tooltip title ='View Business Area History'>
                                            <Icon
                                                disabled={!permission.canView}
                                                color='blue'
                                                name='history'
                                                className="action-cursor"
                                                data-testid={CypressTestIds.HISTORY_BUSINESS_AREA_BUTTON}
                                                onClick={() => onShowBusinessAreaHistory(businessArea.businessAreaId)} />
                                                </Tooltip>
                                        </>}
                                    />
                                    <Column
                                        width="15%"
                                        dataIndex={"name"}
                                        title={"Business Area"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                     width="10%"
                                        dataIndex={"description"}
                                        title={"Description"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                        width="8%"
                                        dataIndex={"usersCount"}
                                        title={"Users"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="8%"
                                        dataIndex={"appClientsCount"}
                                        title={"Credentials"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <>{value || 0}</>}
                                    />
                                     <Column
                                        width="8%"
                                        dataIndex={"systemConnectionsCount"}
                                        title={"System Connections"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="8%"
                                        dataIndex={"dataDomainCount"}
                                        title={"Domains"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="8%"
                                        dataIndex={"schemasCount"}
                                        title={"Schemas"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="8%"
                                        dataIndex={"dataSetCount"}
                                        title={"DataSets"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => <>{value || 0}</>}
                                    />
                                    <Column
                                        width="10%"
                                        dataIndex="active"
                                        title={"Active"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, businessArea, index) => {
                                            return <Switch
                                                checked={value}
                                                disabled={businessArea.type === "SYSTEM" || !permission.canEdit}
                                                data-testid={CypressTestIds.ACTIVATE_BUSINESS_AREA_SWITCH}
                                                onClick={(checked, event) => onActivateDeactivateBusinessArea(businessArea.businessAreaId, businessArea.version, checked)}>
                                            </Switch>;
                                        }}
                                    />
                                    <Column
                                        width="5%"
                                        render={(value, businessArea, index) => {
                                            return (
                                                
                                                    <Tooltip title ='Delete Business Area'>
                                                <Icon
                                                    disabled={businessArea.type === "SYSTEM" || !permission.canDelete}
                                                    color='blue'
                                                    name='trash alternate'
                                                    className="action-cursor"
                                                    data-testid={CypressTestIds.DELETE_BUSINESS_AREA_BUTTON}
                                                    onClick={() => onDeleteBusinessArea(businessArea)} /> </Tooltip> 
                                            );
                                        }}
                                    />
                                </Table>
                            )}>
                        </PaginatedListContainer>
                    }
                    footer={
                        <Row className="table-footer-row">
                            <Col span={24} className="footer-right-column">
                                <Button
                                    disabled={!permission.canAdd}
                                    type="primary"
                                    onClick={onAddBusinessArea}
                                    data-testid={CypressTestIds.ADD_BUSINESS_AREA_BUTTON}>
                                    <PlusOutlined />  Add Business Area
                                </Button>
                            </Col>
                        </Row>
                    }
                    footerStyle={{ paddingTop: "1rem" }}>

                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
}

export default BusinessAreaList;