import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Switch, Space, Button, Table, Checkbox, Tooltip } from "antd";
import { TableOutlined, FolderViewOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import moment from 'moment';

const { Meta } = Card;
const { Text, Link } = Typography;
const { Column } = Table;

const JdbcTableList = ({ tables, onTableSelected }) => {
    const [jdbcTables, setJdbcTables] = useState(tables);

    useEffect(() => {
        setJdbcTables(tables);
    }, [tables]);

    const onTableItemClick = (table, index) => {
        let currentJdbcTables = [...jdbcTables];
        for (let i = 0; i < currentJdbcTables.length; i++) {
            if (index === i) {
                currentJdbcTables[i] = { ...currentJdbcTables[i], selected: true };
            }
            else {
                currentJdbcTables[i] = { ...currentJdbcTables[i], selected: false };
            }
        }
        setJdbcTables(currentJdbcTables);
        onTableSelected(table);
    }

    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={false}
                    content={
                        <Table size="small"
                            rowKey="path"
                            dataSource={jdbcTables}
                            pagination={false}
                            scroll={{ y: "100vh" }}
                            className="container-height-100">
                            <Column
                                dataIndex="name"
                                title="Name"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, table, index) => {
                                    return <Tooltip placement="topLeft" title={value}>
                                        <Space>
                                            {table.Type === "BASE TABLE" ? <TableOutlined /> : <FolderViewOutlined />}
                                            <Link onClick={() => onTableItemClick(table, index)}>
                                                {table.Name}
                                            </Link>
                                        </Space>
                                    </Tooltip>
                                }}
                            />
                            <Column
                                dataIndex="type"
                                title="Type"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, table, index) => {
                                    return <Tooltip placement="topLeft" title={value}>
                                        <Text>{toTitleCase(table.Type)}</Text>
                                    </Tooltip>
                                }}
                            />
                        </Table>}>

                </FullHeightContainerLayout>
            </Col>
        </Row>
    );
}

function toTitleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
}

export default JdbcTableList;