import React from 'react';

const UploadStatus = ({ fileName, status }) => (
  <div
    className={`mt-2 p-2 rounded-lg ${
      status.status === 'success' 
        ? 'bg-green-100 text-green-800' 
        : status.status === 'error'
        ? 'bg-red-100 text-red-800'
        : 'bg-blue-100 text-blue-800'
    }`}
  >
    <div className="flex justify-between items-center">
      <span className="truncate max-w-[80%]">{fileName}</span>
      <span>{status.message}</span>
    </div>
  </div>
);

export default UploadStatus;