import React, {useEffect} from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { message } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(FilePondPluginImagePreview);

const FilePondUploader = ({
  pond,
  setPond,
  allowMultiple,
  maxFiles,
  acceptedFileTypes,
  fileTypeDescription,
  maxFileSize,
  disabled,
  handleProcessFile,
  handleLoadFile,
  uploadProgress,
  onCancelUpload,
  uploadStatus,
  error,
  labelFileTypeNotAllowed,
  labelMaxFileSizeExceeded,
  labelIdle
}) => {
  const getFileExtensions = (mimeTypes) => {
    const mimeToExt = {
      'image/jpeg': '.jpg,.jpeg',
      'image/png': '.png',
      'image/gif': '.gif',
      'image/webp': '.webp',
      'application/pdf': '.pdf',
      'application/msword': '.doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
      'application/vnd.ms-excel': '.xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
      'application/vnd.ms-powerpoint': '.ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx'
    };

    return mimeTypes
      .map(mime => mimeToExt[mime])
      .filter(Boolean)
      .join(',');
  };
  
  return (
    <div className="border-2 border-dashed rounded-lg p-4">
      <FilePond
        ref={setPond}
        allowMultiple={allowMultiple}
        maxFiles={maxFiles}
        acceptedFileTypes={getFileExtensions(acceptedFileTypes)}
        fileValidateTypeLabelExpectedTypes={`Only ${fileTypeDescription} are allowed`}
        maxFileSize={maxFileSize}
        disabled={disabled}
        allowRevert={true}
        instantUpload={true}
        beforeAddFile={(file) => {
          const fileType = file.fileType;
          if (!acceptedFileTypes.includes(fileType)) {
            message.error(`File type not allowed. Only ${fileTypeDescription} are accepted.`);
            return false;
          }
          return true;
        }}
        onremovefile={(error, file) => {
          const fileName = file.filename || file.file.name;
          if (fileName) {
            onCancelUpload(fileName);
          }
        }}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            const controller = {
              abort: () => {
                abort();
                onCancelUpload(file.name);
              }
            };

            handleProcessFile(fieldName, file, metadata, load, error, progress);

            return controller;
          },
          load: handleLoadFile,
          revert: null
        }}
        labelFileTypeNotAllowed={labelFileTypeNotAllowed}
        labelMaxFileSizeExceeded={labelMaxFileSizeExceeded}
        labelIdle={labelIdle}
        className="file-pond-custom"
        styleItemPanelAspectRatio={0}
        stylePanelLayout="compact"
      />

      {error && (
        <div className="mt-2 p-2 bg-red-100 text-red-800 rounded">
          {error}
        </div>
      )}

      {Array.from(uploadProgress.entries()).map(([fileName, progress]) => (
        <div key={fileName} className="bg-gray-100 rounded-md p-2 mt-2">
          <div className="flex justify-between items-center mb-1">
            <span className="text-sm truncate max-w-[70%]">{fileName}</span>
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">{Math.round(progress)}%</span>
              <button
                onClick={() => onCancelUpload(fileName)}
                className="text-gray-500 hover:text-red-500 transition-colors p-1"
                aria-label="Cancel upload"
              >
                <CloseCircleFilled className="text-lg" />
              </button>
            </div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-blue-500 h-2 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      ))}

      {uploadStatus && Array.from(uploadStatus.entries())
        .filter(([_, status]) => status.status !== 'uploading')
        .map(([fileName, status]) => (
        <div key={fileName} className={`mt-2 p-2 rounded ${
          status.status === 'success' 
            ? 'bg-green-100 text-green-800' 
            : status.status === 'error'
            ? 'bg-red-100 text-red-800'
            : 'bg-blue-100 text-blue-800'
        }`}>
          <div className="flex justify-between items-center">
            <span className="truncate max-w-[80%]">{fileName}</span>
            <span>{status.message}</span>
          </div>
        </div>
      ))}

      <style>
        {`
          .file-pond-custom .filepond--drop-label {
            color: #374151;
            font-size: 0.875rem;
          }
          .file-pond-custom .filepond--panel-root {
            border: 2px dashed #e5e7eb;
            background-color: #f9fafb;
          }
          .filepond--credits {
            display: none !important;
          }
          .filepond--item {
            margin: 0;
            padding: 0;
            height: 0 !important;
            opacity: 0;
            pointer-events: none;
          }
          .filepond--item-panel {
            display: none !important;
          }
          .filepond--file-wrapper {
            display: none !important;
          }
          .filepond--file {
            display: none !important;
          }
          .filepond--file-action-button {
            cursor: pointer !important;
          }
          .filepond--file-info {
            display: none !important;
          }
          .filepond--file-status {
            display: none !important;
          }
          .filepond--file-action-button {
            display: none !important;
          }
          .filepond--list-scroller {
            margin: 0 !important;
            padding: 0 !important;
            height: 0 !important;
          }
          .filepond--list {
            margin: 0 !important;
            padding: 0 !important;
            height: 0 !important;
          }
          .filepond--drop-label {
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>
    </div>
  );
};

export default FilePondUploader;