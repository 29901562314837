import React from 'react';
import FilePreview from '../FilePreview';

const AttachmentsList = ({ 
  files, 
  onPreview, 
  onRemove, 
  loadingPreview 
}) => {
  if (files.length === 0) return null;

  return (
    <div className="border rounded-lg p-4 bg-gray-50">
      <h3 className="text-sm font-medium mb-3">Attachments</h3>
      <div className="space-y-2">
        {files.map((file) => (
          <FilePreview
            key={file.key}
            file={file}
            onPreview={onPreview}
            onRemove={onRemove}
            loadingPreview={loadingPreview[file.key]}
          />
        ))}
      </div>
    </div>
  );
};

export default AttachmentsList;