import React from 'react';
import { FilePdfOutlined, FileImageOutlined, FileOutlined } from '@ant-design/icons';

const FileIcon = ({ fileType, className = "flex-shrink-0" }) => {
  if (fileType?.startsWith('image/')) {
    return <FileImageOutlined className={`text-blue-500 ${className}`} />;
  } else if (fileType === 'application/pdf') {
    return <FilePdfOutlined className={`text-red-500 ${className}`} />;
  }
  return <FileOutlined className={`text-gray-500 ${className}`} />;
};

export default FileIcon;