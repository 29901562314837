import React from "react";
import { Form, InputNumber, Checkbox, Tooltip } from "antd";
import { Segment } from 'semantic-ui-react'
import { InfoCircleTwoTone } from '@ant-design/icons';
import CypressTestIds from "../../../cypress/CypressTestIds";

const AttachmentFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors, dataType }) => {
    const showDimensions = dataType === "image" || dataType === "attachment";
    
    return (
        <Segment.Group>
            {showDimensions && (
                <>
                    <Segment>
                        <Form.Item
                            label="Max Height (px)"
                            name={["fields", selectedFieldRow.name, "maxHeight"]}
                            rules={[{ type: 'number', min: 0, message: "Height must be a positive number" }]}
                            initialValue={1000}>
                            <InputNumber
                                min={0}
                                disabled={readOnly}
                                style={{ width: "100%" }}
                                data-testid={CypressTestIds.SCHEMA_ATTACHMENT_MAXHEIGHT_INPUT}
                            />
                        </Form.Item>
                    </Segment>

                    <Segment>
                        <Form.Item
                            label="Max Width (px)"
                            name={["fields", selectedFieldRow.name, "maxWidth"]}
                            rules={[{ type: 'number', min: 0, message: "Width must be a positive number" }]}
                            initialValue={1000}>
                            <InputNumber
                                min={0}
                                disabled={readOnly}
                                style={{ width: "100%" }}
                                data-testid={CypressTestIds.SCHEMA_ATTACHMENT_MAXWIDTH_INPUT}
                            />
                        </Form.Item>
                    </Segment>
                </>
            )}

            <Segment>
                <Form.Item
                    label="Max File Size (MB)"
                    name={["fields", selectedFieldRow.name, "maxSize"]}
                    rules={[{ type: 'number', min: 0, message: "File size must be a positive number" }]}
                    initialValue={10}>
                    <InputNumber
                        min={0}
                        disabled={readOnly}
                        style={{ width: "100%" }}
                        data-testid={CypressTestIds.SCHEMA_ATTACHMENT_MAXSIZE_INPUT}
                    />
                </Form.Item>
            </Segment>

            <Segment>
                <Form.Item
                    label="Multi Value"
                    name={["fields", selectedFieldRow.name, "isMultivalue"]}
                    valuePropName="checked"
                    initialValue={false}>
                    <Checkbox
                        disabled={readOnly}
                        data-testid={CypressTestIds.SCHEMA_ATTACHMENT_MULTIVALUE_CHECKBOX}>
                        Allow multiple attachments
                    </Checkbox>
                </Form.Item>
            </Segment>

            <Segment>
                <Form.Item
                    label="PII"
                    name={["fields", selectedFieldRow.name, "isPII"]}
                    valuePropName="checked"
                    initialValue={false}>
                    <Checkbox
                        disabled={readOnly}
                        data-testid={CypressTestIds.SCHEMA_ATTACHMENT_PII_CHECKBOX}>
                        <Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data">
                            <InfoCircleTwoTone />
                        </Tooltip>
                    </Checkbox>
                </Form.Item>
            </Segment>
        </Segment.Group>
    );
};

export default AttachmentFieldProperties;