import { Position } from "@xyflow/react";
import { DataMappedNodeType } from "../DataMappedNode";

interface ICreateDataMappedNode {
    targetId: string;
    positionX: number;
    sourceColumns: string[];
    sourceIds: string[];
    field: IDataMappingField;
    onDataChange: (data: IDataMappingField) => void;
}

export default function createDataMappedNode({
    targetId,
    positionX,
    sourceColumns,
    sourceIds,
    field,
    onDataChange,
}: ICreateDataMappedNode): DataMappedNodeType {
    return {
        id: `${targetId}-mapped-data`,
        type: "dataMappedNode",
        parentId: targetId,
        targetPosition: Position.Left,
        position: {
            x: positionX,
            y: 0,
        },
        data: {
            sourceColumns,
            sourceIds,
            targetIds: [targetId],
            field: field,
            onDataChange: onDataChange,
        },
    };
}
