import React, { useRef, useState } from 'react';
import { Card, Input, Button, Typography, Tooltip, Menu, Space, Row, Col } from "antd";
import { DislikeTwoTone, LikeTwoTone, EditTwoTone } from "@ant-design/icons";
import { FullHeightContainerLayout } from 'components';
import { v4 as uuidv4 } from 'uuid';

const { TextArea } = Input;
const { Title, Paragraph, Text, Link } = Typography;

/** @type {import('./types').NaturalLanguageQueryViewer} */
function NaturalLanguageQueryViewer({ naturalLanguageText, dslQuery, error, showQueryEditor, onClickQueryFeedback, onEditQuery }) {
    const [accuracy, setAccuracy] = useState(-1);

    const onClickAccuracy = (accuracy) => {
        setAccuracy(accuracy);
        onClickQueryFeedback && onClickQueryFeedback({ accuracy });
    }

    return <FullHeightContainerLayout
        showHeader={true}
        showFooter={true}
        header={
            <Paragraph strong={true}>{naturalLanguageText}</Paragraph>
        }
        headerStyle={{ lineHeight: "unset" }}
        content={
            <>
                {dslQuery && <TextArea readOnly={true} value={JSON.stringify(dslQuery, null, 2)} autoSize />}
                {error && <Text type="danger">{error}</Text>}
            </>
        }
        footer={
            <Space direction={"horizontal"} style={{ paddingTop: "1rem" }} size="large">
                <Tooltip title={"It's a flawless query"}><LikeTwoTone style={{ fontSize: "1.5rem" }} twoToneColor={accuracy === 100 ? "orange" : null} onClick={() => onClickAccuracy(100)} /></Tooltip>
                <Tooltip title={"It's an imperfect query"}><DislikeTwoTone style={{ fontSize: "1.5rem" }} twoToneColor={accuracy === 0 ? "orange" : null} onClick={() => onClickAccuracy(0)} /></Tooltip>
                {showQueryEditor && <Tooltip title={"Edit in Build My Query"}><EditTwoTone style={{ fontSize: "1.5rem" }} onClick={onEditQuery} /></Tooltip>}
            </Space>
        }>
    </FullHeightContainerLayout>

}

export default NaturalLanguageQueryViewer