import React, { useState, useEffect } from "react";
import { Steps, Button, Divider } from "antd";
import {
    BusinessAreaSystemConnections,
    SystemConnectionAwsS3Buckets,
    SystemConnectionAwsS3BucketContentList,
    SystemConnectionJdbcTables,
} from "containers";

const { Step } = Steps;

const DEFAULT_STEPS = [
    { key: "Select System Connection", title: "Select System Connection" },
];

/** @type {import('./types').ISystemConnectionSelector} */
const SystemConnectionSelector = ({
    businessAreaId,
    onConnectionSelectionComplete,
    allowedFileTypes = [],
    allowNewConnectionDestination = false,
    readSelectedDestination = true,
}) => {
    const [stepItems, setStepItems] = useState(DEFAULT_STEPS);
    const [current, setCurrent] = useState(0);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [stepResult, setStepResult] = useState([]);

    useEffect(() => {
        if (selectedConnection) {
            switch (selectedConnection.systemConnection.type) {
                case "aws_s3":
                    setStepItems(
                        DEFAULT_STEPS.concat([
                            {
                                key: "Select AWS S3 Bucket File",
                                title: "Select AWS S3 Bucket File",
                            },
                        ]),
                    );
                    break;
                case "jdbc":
                    setStepItems(
                        DEFAULT_STEPS.concat([
                            {
                                key: "Select JDBC Table",
                                title: "Select JDBC Table",
                            },
                        ]),
                    );
                    break;
                default:
                    setStepResult([]);
                    setStepItems(DEFAULT_STEPS);
                    break;
            }
        }
    }, [selectedConnection]);

    const next = () => {
        if (selectedConnection) {
            if (current === 0) {
                setCurrent(1);
            } else {
                setCurrent(stepResult.length + 1);
            }
        }
    };

    const prev = () => {
        setCurrent(current - 1);
        let currentStepResult = [...stepResult];
        if (currentStepResult.length === current) {
            currentStepResult.splice(currentStepResult.length - 1, 1);
            setStepResult(currentStepResult);
        }
        onConnectionSelectionComplete(null);
    };

    const onStepResultChanged = (step, result) => {
        let currentStepResult = [...stepResult];
        if (currentStepResult.length - 1 < step) {
            currentStepResult.push(null);
        }
        if (result) {
            currentStepResult[step] = result;
        } else {
            currentStepResult.splice(currentStepResult.length - 1, 1);
        }
        setStepResult(currentStepResult);

        if (
            selectedConnection.systemConnection.type === "aws_s3" &&
            currentStepResult.length === 1
        ) {
            const { file, bucket } = currentStepResult[0];
            if (file && bucket) {
                onConnectionSelectionComplete({
                    connectionId:
                        selectedConnection.systemConnection.connectionId,
                    connectionName: selectedConnection.systemConnection.name,
                    connectionType: "aws_s3",
                    bucket,
                    ...file,
                });
            } else {
                onConnectionSelectionComplete(null);
            }
        }
    };

    const onFileSelected = (file) => {
        if (file) {
            let currentStepResult = stepResult[0];
            if (currentStepResult.bucket) {
                onStepResultChanged(0, { ...currentStepResult, file });
            } else {
                onStepResultChanged(0, {
                    bucket: currentStepResult.Name,
                    file,
                });
            }
        } else {
            onStepResultChanged(0, { Name: stepResult[0].bucket });
        }
    };

    const getContent = (current) => {
        if (current === 0) {
            return (
                <BusinessAreaSystemConnections
                    businessAreaId={businessAreaId}
                    onConnectionSelected={setSelectedConnection}
                ></BusinessAreaSystemConnections>
            );
        } else {
            switch (selectedConnection.systemConnection.type) {
                case "aws_s3":
                    if (current === 1) {
                        if (stepResult.length === 0) {
                            return (
                                <SystemConnectionAwsS3Buckets
                                    connectionId={
                                        selectedConnection.systemConnection
                                            .connectionId
                                    }
                                    onBucketSelected={(bucket) =>
                                        onStepResultChanged(0, bucket)
                                    }
                                ></SystemConnectionAwsS3Buckets>
                            );
                        } else {
                            return (
                                <SystemConnectionAwsS3BucketContentList
                                    connectionId={
                                        selectedConnection.systemConnection
                                            .connectionId
                                    }
                                    bucket={stepResult[0].Name}
                                    onFileSelected={(file) =>
                                        onFileSelected(file)
                                    }
                                    goToBuckets={() =>
                                        onStepResultChanged(0, null)
                                    }
                                    allowNewKey={allowNewConnectionDestination}
                                    readSelectedFile={readSelectedDestination}
                                    allowedFileTypes={allowedFileTypes}
                                ></SystemConnectionAwsS3BucketContentList>
                            );
                        }
                    }
                    break;
                case "jdbc":
                    if (current === 1) {
                        return (
                            <SystemConnectionJdbcTables
                                connectionId={
                                    selectedConnection.systemConnection
                                        .connectionId
                                }
                                onBucketSelected={(bucket) =>
                                    onStepResultChanged(0, bucket)
                                }
                            ></SystemConnectionJdbcTables>
                        );
                    }
                    break;
                default:
                    return <></>;
            }
        }
    };

    return (
        <div className="flex size-full flex-col gap-3">
            <Steps current={current} size="small">
                {stepItems.map((item) => (
                    <Step key={item.key} title={item.title} />
                ))}
            </Steps>
            <div className="flex-1">{getContent(current)}</div>
            <div className="flex justify-end">
                {current === 0 || current < stepItems.length - 1 ? (
                    <Button type="primary" onClick={next}>
                        Next
                    </Button>
                ) : (
                    <></>
                )}
                {current > 0 ? <Button onClick={prev}>Previous</Button> : <></>}
            </div>
            <Divider className="mb-4 mt-0" />
        </div>
    );
};

export default SystemConnectionSelector;
