import React from "react";
import { Checkbox, Space, Button, Table, Tooltip, Row, Col, Dropdown, Menu } from "antd";
import { PlusOutlined, WarningTwoTone, DatabaseTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";
import { CloudDownloadOutlined } from '@ant-design/icons';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Column } = Table;

const DataSetList = ({ dataSetList = [], allowCreateNew, parentSchema, onCreateNewDataSet, onEditDataSet, onDeleteDataSet, onViewDataSet, onViewDataSetHistory, onIndexDataSet, onExportToFileClick, onExportToSystemConnectionClick, onPageSelect, emptyText, permission, exportPermission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <FullHeightContainerLayout
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="dataSetId"
                            dataSource={dataSetList}
                            locale={emptyText && { emptyText }}
                            scroll={{ y: "100vh" }}
                            rowSelection={{
                                type: 'radio'
                            }}
                            pagination={{
                                position: ["bottomRight"],
                                defaultPageSize: 100,
                                pageSize: 100,
                                responsive: true,
                                showSizeChanger: false
                            }}
                            onChange={(pagination, filters, sorter, extra) => extra.action === "paginate" && onPageSelect && onPageSelect(pagination)}
                            className="container-height-85 schema-list-table"
                            data-testid={CypressTestIds.DATASET_LIST_TABLE}>
                            <Column
                                width="6rem"
                                render={(value, dataSet, index) => <div style={{ marginLeft: "-1rem" }}>
                                    {
                                        permission.canDelete && dataSet.runningJobsCount && dataSet.runningJobsCount > 0
                                            ?
                                            <Tooltip placement="topLeft" title="A job is running. DataSet is locked for delete.">
                                                <Icon
                                                    color='blue'
                                                    name='lock' />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                    <Icon
                                        disabled={!permission.canEdit}
                                        color='blue'
                                        name='pencil'
                                        className="action-cursor"
                                        onClick={() => onEditDataSet(dataSet.dataSetId)} data-testid={CypressTestIds.DATASET_LIST_EDIT_BUTTON}/>
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='eye'
                                        className="action-cursor"
                                        onClick={() => onViewDataSet(dataSet.dataSetId)} data-testid={CypressTestIds.DATASET_LIST_VIEW_BUTTON}/>
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='history'
                                        className="action-cursor"
                                        onClick={() => onViewDataSetHistory(dataSet.dataSetId)} data-testid={CypressTestIds.DATASET_LIST_HISTORY_BUTTON}/>
                                    {dataSet.erroredRecordsCount ? <Tooltip placement="topLeft" title={`Contains ${dataSet.erroredRecordsCount} error row(s)`}><WarningTwoTone twoToneColor="#FF0000" /></Tooltip> : <></>}
                                </div>}
                            />
                            <Column
                                width="17%"
                                dataIndex={"dataSetName"}
                                title={"DataSet"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="17%"
                                dataIndex={"schemaName"}
                                title={"Schema"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={parentSchema}>{parentSchema}</Tooltip>}
                            />
                            <Column
                                width="10%"
                                dataIndex={"recordCount"}
                                title={"Records"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="10%"
                                dataIndex={"shared"}
                                title={"Shared"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => {
                                    return (
                                        <Checkbox key={dataSet.schemaId} checked={value}></Checkbox>
                                    );
                                }}
                            />
                            <Column
                                width="20%"
                                dataIndex={"updatedDate"}
                                title={"Last Updated"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => {
                                    let dateValue = moment.utc(value).toDate().toLocaleString();
                                    return <Tooltip placement="topLeft" title={dateValue}>{dateValue}</Tooltip>
                                }}
                            />
                            <Column
                                width="20%"
                                dataIndex={"createdByEmail"}
                                title={"Created By"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataSet, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="6rem"
                                render={(value, dataSet, index) => {
                                    return (
                                        <>
                                            <Tooltip title="Index dataset">
                                                <DatabaseTwoTone
                                                    disabled={!permission.canEdit}
                                                    className="action-cursor"
                                                    onClick={() => onIndexDataSet(dataSet)} data-testid={CypressTestIds.DATASET_LIST_INDEX_DATASET_DATABASETWOTONE}/>
                                            </Tooltip>
                                            <Tooltip title="Export the dataset to a file or destination">
                                                <Dropdown
                                                    disabled={!exportPermission || !exportPermission.canView}
                                                    data-testid={CypressTestIds.DATASET_LIST_EXPORT_DROPDOWN}
                                                    overlay={<Menu>
                                                        <Menu.Item>
                                                            <a onClick={() => onExportToFileClick && onExportToFileClick(dataSet)}
                                                                data-testid={CypressTestIds.DATASET_LIST_EXPORT_TO_FILE}>
                                                                Export to a file
                                                            </a>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <a onClick={() => onExportToSystemConnectionClick && onExportToSystemConnectionClick(dataSet)}
                                                                data-testid={CypressTestIds.DATASET_LIST_EXPORT_TO_DESTINATION}>
                                                                Export to a destination
                                                            </a>
                                                        </Menu.Item>
                                                    </Menu>}
                                                    placement="bottomRight">
                                                    <Icon
                                                        color='blue'
                                                        name='cloud download'
                                                        className="action-cursor" data-testid={CypressTestIds.DATASET_LIST_CLOUD_DOWNLOAD}/>
                                                </Dropdown>
                                            </Tooltip>
                                            <Icon
                                                disabled={!permission.canDelete || (dataSet.runningJobsCount && dataSet.runningJobsCount > 0)}
                                                color='blue'
                                                name='trash alternate'
                                                className="action-cursor"
                                                onClick={() => onDeleteDataSet(dataSet)} data-testid={CypressTestIds.DATASET_LIST_DELETE_BUTTON}/>
                                        </>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </div>}
            footer={
                <Row className="table-footer-row">
                    <Col span={24} className="footer-right-column">
                        <Button
                            disabled={!permission.canAdd || !allowCreateNew}
                            type="primary"
                            onClick={onCreateNewDataSet} data-testid={CypressTestIds.DATASET_LIST_CREATE_NEW_DATASET_BUTTON}>
                            <PlusOutlined /> Create a new data set
                        </Button>
                    </Col>
                </Row>
            }
            showFooter={true}>
        </FullHeightContainerLayout>
    );
};

export default DataSetList;