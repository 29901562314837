import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Utility function to efficiently merge Tailwind CSS classes in JS without style conflicts.
 * @param inputs
 * @returns
 */
export default function mergeClassNames(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}
