import { InboxOutlined } from "@ant-design/icons";
import { Typography, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { showError } from "common/ToastNotifications";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import Papaparse from "papaparse/papaparse";
import React from "react";
import DataPreview from "../DataPreview";
import { IFileDraggerProps } from "./types";

const { Text } = Typography;

const { Dragger } = Upload;

const FileDragger = React.forwardRef(
    (
        {
            hasHeader = true,
            disabled = false,
            showPreview = true, // Defaults to showPreview
            onCancelUpload,
            onReadingFile,
            onReadFileComplete,
            onReadFileError,
            dataSchemaColumns = [],
        }: IFileDraggerProps,
        ref,
    ) => {
        const [file, setFile] = React.useState<null | RcFile>(null);
        const [data, setData] = React.useState<
            null | PapaparseDataNoHeader | PapaparseDataWithHeader
        >(null);
        const [columns, setColumns] = React.useState<null | string[]>(null);
        const [isReadingFile, setIsReadingFile] =
            React.useState<boolean>(false);

        const onFileReadComplete = React.useCallback(
            (uploadedFile: RcFile, results: PapaparseResult) => {
                if (results) {
                    const resultData = results.data;

                    //Remove last empty row.
                    if (resultData && resultData.length > 0) {
                        const lastRow = resultData[resultData.length - 1];
                        const lastRowValues = Object.values(lastRow);
                        if (!lastRowValues.some((value) => !value === false)) {
                            resultData.splice(resultData.length - 1, 1);
                        }
                    }

                    const resultColumns =
                        results.meta?.fields?.filter((field) => field) ?? null;
                    const resultMeta = results.meta ?? null;

                    setFile(uploadedFile);
                    setColumns(resultColumns);
                    setData(resultData);
                    setIsReadingFile(false);
                    onReadFileComplete &&
                        onReadFileComplete({
                            file: uploadedFile,
                            data: resultData,
                            columns: resultColumns,
                            error: null,
                            meta: resultMeta,
                        });
                }
            },
            [onReadFileComplete],
        );

        const onFileReadError = React.useCallback(
            (uploadedFile: RcFile, error: string | null) => {
                setFile(uploadedFile);
                setColumns(null);
                setData(null);
                setIsReadingFile(false);
                onReadFileError &&
                    onReadFileError({
                        file: uploadedFile,
                        data: null,
                        columns: null,
                        error: error,
                        meta: null,
                    });
            },
            [onReadFileError],
        );

        const onUploadFile = React.useCallback(
            (uploadedFile: RcFile) => {
                // Only accept CSV file for now
                const isCSV =
                    uploadedFile.type === "text/csv" ||
                    uploadedFile.name.toLowerCase().endsWith(".csv");
                if (!isCSV) {
                    showError(
                        "Selected file is not a CSV file. Please try again.",
                    );
                    return;
                }

                setIsReadingFile(true);
                onReadingFile && onReadingFile();
                Papaparse.parse(uploadedFile, {
                    delimiter: ",",
                    header: hasHeader,
                    preview: 100,
                    encoding: "UTF-8",
                    worker: true,
                    complete: (result: PapaparseResult) =>
                        onFileReadComplete(uploadedFile, result),
                    error: (error: string | null) =>
                        onFileReadError(uploadedFile, error),
                });
                return false;
            },
            [hasHeader, onFileReadComplete, onFileReadError, onReadingFile],
        );

        const onFilePreviewClose = React.useCallback(() => {
            // On close preview, clear previous data
            setFile(null);
            setData(null);
            setColumns(null);
            onCancelUpload && onCancelUpload();
        }, [onCancelUpload]);

        // Allow the parent to clear file in FileDragger
        React.useImperativeHandle(ref, () => ({
            clearFile() {
                setFile(null);
                setData(null);
                setColumns(null);
            },
        }));

        return (
            <>
                <LoadingOverlay
                    busy={isReadingFile}
                    message={"Preparing preview..."}
                    spinner
                />
                {data ? (
                    showPreview && (
                        <DataPreview
                            title={
                                <>
                                    <Text>{`${file?.name ?? "No file name"} - `}</Text>
                                    <Text type="secondary">
                                        {`Data Preview (${data.length} Records)`}
                                    </Text>
                                </>
                            }
                            data={data as Record<string, React.ReactNode>[]}
                            columns={columns ?? []}
                            dataSchemaColumns={dataSchemaColumns}
                            onClose={onFilePreviewClose}
                            closeLabel="Cancel"
                            closeTooltip="Cancel and upload another file"
                        ></DataPreview>
                    )
                ) : (
                    <Dragger
                        accept=".csv"
                        name="file"
                        multiple={false}
                        showUploadList={false}
                        disabled={disabled}
                        beforeUpload={onUploadFile}
                        className="flex-1"
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                            Support for a singleupload.
                        </p>
                    </Dragger>
                )}
            </>
        );
    },
);

export default FileDragger;
