import React from "react";
import { Space, Tooltip } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import _ from 'lodash';
import {
    FormDateDisplayField,
    FormDateTimeDisplayField,
    FormPhoneDisplayField,
    FormTimeDisplayField
} from 'components';
import AttachmentCell from "components/atoms/AttachmentCell";

const FormDisplayField = ({ fieldError, dataType, value, disabled = false, ...props }) => {
    let fieldComponent;
    if (!fieldError) {
        switch (dataType) {
            case "datetime":
                fieldComponent = <FormDateTimeDisplayField dataType={dataType} value={value} disabled={disabled} {...props} />;
                break;
            case "date":
                fieldComponent = <FormDateDisplayField dataType={dataType} value={value} disabled={disabled} {...props} />;
                break;
            case "time":
                fieldComponent = <FormTimeDisplayField dataType={dataType} value={value} disabled={disabled} {...props} />;
                break;
            case "phone":
                fieldComponent = <FormPhoneDisplayField dataType={dataType} value={value} disabled={disabled} {...props} />;
                break;
                case "attachment":
                case "image":
                case "document":
                    fieldComponent = <AttachmentCell dataType={dataType} value={value} disabled={disabled} {...props} />;
                    break;
            default:
                fieldComponent = <>{value}</>;
                break;
        }
    }
    return <>{fieldError ? <Tooltip placement="topLeft" title={fieldError}><WarningTwoTone twoToneColor="#FF0000" /></Tooltip> : <></>} {fieldComponent}</>
}

export default FormDisplayField;