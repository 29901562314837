import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Row, Col, Button, Space, Tabs } from 'antd';
import LABELS from 'constants/labels';
import actions from 'store/actions';
import * as EmailValidator from 'email-validator';
import { RolePermissionList, RoleUserList, RoleAppClientList, ProhibitedArea, FullHeightContainerLayout } from "components";
import { showError, showSuccess, showInfo } from 'common/ToastNotifications';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import moment from 'moment';
import _ from 'lodash';
import flatten from 'flat';
import { keyGenerator } from 'common/Utility';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { TabPane } = Tabs;

class ManageRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            readOnlyView: this.props.action === "view",
            formErrors: {}
        };
        this.defaultRole = {
            name: '',
            description: '',
            permissions: [],
            users: []
        };
        this.emptyObject = {};
        this.formRef = React.createRef();
    }

    componentDidMount() {
        switch (this.props.action) {
            case "view":
            case "edit":
                this.populateRole();
                break;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formErrors && this.props.formErrors !== prevProps.formErrors) {
            this.setState({
                formErrors: this.props.formErrors
            });
        }
        if (this.props.role && prevProps.role !== this.props.role) {
            this.populateRole();
        }
    }

    populateRole = () => {
        if (this.props.role && this.formRef.current) {
            switch (this.props.action) {
                case "view":
                case "edit":
                    this.formRef.current.resetFields();
                    this.formRef.current.setFieldsValue(this.props.role)
                    break;
            }
        }
    }

    onFormSubmit = (role) => {
        if (!this.isSaveAllowed()) {
            return;
        }

        if (!this.validateRole(role)) {
            return;
        }

        if (this.props.action === "edit") {
            let updatedRoleData = {
                role: null,
                newUsers: [],
                deletedUsers: [],
                updatedUsers: [],
                newAppClients: [],
                deletedAppClients: [],
                updatedAppClients: [],
                newPermissions: [],
                deletedPermissions: [],
                updatedPermissions: []
            };
            let originalRole = { ...this.props.role };
            let updatedRole = { ...role };

            let updatedUserData = this.getModifiedRoleUsers(originalRole.users, updatedRole.users);
            let updatedAppClientData = this.getModifiedRoleAppClients(originalRole.appClients, updatedRole.appClients);
            let updatedPermissionData = this.getModifiedRolePermissions(originalRole.permissions, updatedRole.permissions);

            updatedRoleData = { ...updatedRoleData, ...updatedUserData, ...updatedAppClientData, ...updatedPermissionData };

            delete originalRole.users;
            delete originalRole.appClients;
            delete updatedRole.users;
            delete updatedRole.appClients;
            delete originalRole.permissions;
            delete updatedRole.permissions;

            updatedRole = { ...originalRole, ...updatedRole };
            if (_.isEqual(originalRole, updatedRole) === false) {
                updatedRoleData.role = updatedRole;
            }

            if (updatedRoleData.role ||
                updatedRoleData.newUsers.length > 0 ||
                updatedRoleData.deletedUsers.length > 0 ||
                updatedRoleData.updatedUsers.length > 0 ||
                updatedRoleData.newAppClients.length > 0 ||
                updatedRoleData.deletedAppClients.length > 0 ||
                updatedRoleData.updatedAppClients.length > 0 ||
                updatedRoleData.newPermissions.length > 0 ||
                updatedRoleData.deletedPermissions.length > 0 ||
                updatedRoleData.updatedPermissions.length > 0) {
                this.props.updateRole(this.props.role.roleId, updatedRoleData);
            }
        }
        else {
            let users = role.users;
            let appClients = role.appClients;
            let permissions = role.permissions;

            delete role.users;
            delete role.appClients;
            delete role.permissions;

            this.props.createRole({ ...role, active: true }, permissions, users, appClients);
        }
    }

    getModifiedRoleUsers = (originalUsers, modifiedUsers) => {
        let updatedRoleData = {
            newUsers: [],
            deletedUsers: [],
            updatedUsers: []

        }
        if (originalUsers && modifiedUsers) {
            let originalRoleUsersDict = originalUsers.reduce((userObject, user) => {
                userObject[user.key] = user;
                return userObject;
            }, {});

            let updatedRoleUsersDict = {};
            let newRoleUsersIndexMappings = {};
            let updatedRoleUsersIndexes = {};
            for (let index = 0; index < modifiedUsers.length; index++) {
                let roleUser = { ...modifiedUsers[index] };
                if (roleUser.key) {
                    updatedRoleUsersDict[roleUser.key] = roleUser;
                    updatedRoleUsersIndexes[roleUser.key] = index;
                }
                else {
                    newRoleUsersIndexMappings[updatedRoleData.newUsers.length] = index;
                    updatedRoleData.newUsers.push(roleUser);
                }
            }

            let updatedRoleUsersIndexMapping = {};
            for (let userKey in originalRoleUsersDict) {
                if (!updatedRoleUsersDict[userKey]) {
                    updatedRoleData.deletedUsers.push(originalRoleUsersDict[userKey]);
                }
                else if (_.isEqual(originalRoleUsersDict[userKey], updatedRoleUsersDict[userKey]) === false) {
                    updatedRoleUsersIndexMapping[updatedRoleData.updatedUsers.length] = updatedRoleUsersIndexes[userKey];
                    updatedRoleData.updatedUsers.push(updatedRoleUsersDict[userKey]);
                }
            }
        }
        return updatedRoleData;
    }

    getModifiedRoleAppClients = (originalAppClients, modifiedAppClients) => {
        let updatedRoleData = {
            newAppClients: [],
            deletedAppClients: [],
            updatedAppClients: []
        }
        if (originalAppClients && modifiedAppClients) {
            let originalRoleAppClientDict = originalAppClients.reduce((appClientObject, appClient) => {
                appClientObject[appClient.key] = appClient;
                return appClientObject;
            }, {});

            let updatedRoleAppClientDict = {};
            let newRoleAppClientsIndexMappings = {};
            let updatedRoleAppClientsIndexes = {};
            for (let index = 0; index < modifiedAppClients.length; index++) {
                let roleAppClient = { ...modifiedAppClients[index] };
                if (roleAppClient.key) {
                    updatedRoleAppClientDict[roleAppClient.key] = roleAppClient;
                    updatedRoleAppClientsIndexes[roleAppClient.key] = index;
                }
                else {
                    newRoleAppClientsIndexMappings[updatedRoleData.newAppClients.length] = index;
                    updatedRoleData.newAppClients.push(roleAppClient);
                }
            }

            let updatedRoleAppClientsIndexMapping = {};
            for (let appClientKey in originalRoleAppClientDict) {
                if (!updatedRoleAppClientDict[appClientKey]) {
                    updatedRoleData.deletedAppClients.push(originalRoleAppClientDict[appClientKey]);
                }
                else if (_.isEqual(originalRoleAppClientDict[appClientKey], updatedRoleAppClientDict[appClientKey]) === false) {
                    updatedRoleAppClientsIndexMapping[updatedRoleData.updatedAppClients.length] = updatedRoleAppClientsIndexes[appClientKey];
                    updatedRoleData.updatedAppClients.push(updatedRoleAppClientDict[appClientKey]);
                }
            }
        }
        return updatedRoleData;
    }

    getModifiedRolePermissions = (originalPermissions, modifiedPermissions) => {
        let updatedRoleData = {
            newPermissions: [],
            deletedPermissions: [],
            updatedPermissions: []
        }

        if (originalPermissions && modifiedPermissions) {
            let originalRolePermissionsDict = originalPermissions.reduce((permissionObject, permission) => {
                permissionObject[permission.key] = permission;
                return permissionObject;
            }, {});

            let updatedRolePermissionsDict = {};
            let newRolePermissionsIndexMappings = {};
            let updatedRolePermissionsIndexes = {};
            for (let index = 0; index < modifiedPermissions.length; index++) {
                let rolePermission = { ...modifiedPermissions[index] };
                if (rolePermission.key) {
                    updatedRolePermissionsDict[rolePermission.key] = rolePermission;
                    updatedRolePermissionsIndexes[rolePermission.key] = index;
                }
                else {
                    newRolePermissionsIndexMappings[updatedRoleData.newPermissions.length] = index;
                    updatedRoleData.newPermissions.push(rolePermission);
                }
            }

            let updatedRolePermissionsIndexMapping = {};
            for (let permissionKey in originalRolePermissionsDict) {
                if (!updatedRolePermissionsDict[permissionKey]) {
                    updatedRoleData.deletedPermissions.push(originalRolePermissionsDict[permissionKey]);
                }
                else if (_.isEqual(originalRolePermissionsDict[permissionKey], updatedRolePermissionsDict[permissionKey]) === false) {
                    updatedRolePermissionsIndexMapping[updatedRoleData.updatedPermissions.length] = updatedRolePermissionsIndexes[permissionKey];
                    updatedRoleData.updatedPermissions.push(updatedRolePermissionsDict[permissionKey]);
                }
            }
        }
        return updatedRoleData;
    }

    validateRole = (role) => {
        if (Object.keys(this.state.formErrors).length > 0) {
            return false;
        }
        let formErrors = { ...this.state.formErrors };
        if (role.users && role.users.length > 0) {
            for (let index = 0; index < role.users.length; index++) {
                if (!role.users[index].userId) {
                    formErrors[`users.${index}.userId`] = "Please select a user";
                }
            }
        }

        if (role.permissions && role.permissions.length > 0) {
            for (let index = 0; index < role.permissions.length; index++) {
                if (!role.permissions[index].name) {
                    formErrors[`permissions.${index}.name`] = "Please select a permission";
                }
            }
        }

        if (Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors
            });
            return false;
        }
        return true;
    }

    onPermissionChange = (value, field) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`permissions.${field.name}.name`];
        let permissions = this.formRef.current.getFieldValue("permissions");
        for (let index = 0; index < permissions.length; index++) {
            if (index !== field.name && permissions[index].name === value) {
                formErrors[`permissions.${field.name}.name`] = "Permission already added";
                break;
            }
        }
        let selectedPermission = this.props.permissionMasterData.find(item => item.name === value);
        if (selectedPermission) {
            permissions[field.name].name = selectedPermission.name;
            permissions[field.name].resourceName = selectedPermission.resourceName;
            permissions[field.name].resourceId = selectedPermission.resourceId;
            permissions[field.name].effect = selectedPermission.effect;
            permissions[field.name].condition = selectedPermission.condition;
            permissions[field.name].canAdd = true;
            permissions[field.name].canView = true;
            permissions[field.name].canEdit = true;
            permissions[field.name].canDelete = true;
            if (selectedPermission.readOnly) {
                permissions[field.name].canAdd = false;
                permissions[field.name].canEdit = false;
                permissions[field.name].canDelete = false;
            }
            this.formRef.current.setFieldsValue({
                permissions
            });
        }
        this.setState({
            formErrors
        });
    }

    onUserChange = (value, field) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`users.${field.name}.userId`];
        let users = this.formRef.current.getFieldValue("users");
        for (let index = 0; index < users.length; index++) {
            if (index !== field.name && users[index].userId === value) {
                formErrors[`users.${field.name}.userId`] = "User already added";
                break;
            }
        }
        let selectedUser = this.props.userList.find(item => item.userId === value);
        if (selectedUser) {
            users[field.name].userId = selectedUser.userId;
            users[field.name].email = selectedUser.email;
            users[field.name].firstName = selectedUser.firstName;
            users[field.name].lastName = selectedUser.lastName;
            users[field.name].version = selectedUser.version;
            this.formRef.current.setFieldsValue({
                users
            });
        }
        this.setState({
            formErrors
        });
    }

    onAppClientChange = (value, field) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`appClients.${field.name}.clientId`];
        let appClients = this.formRef.current.getFieldValue("appClients");
        for (let index = 0; index < appClients.length; index++) {
            if (index !== field.name && appClients[index].clientId === value) {
                formErrors[`appClients.${field.name}.clientId`] = "Credential already added";
                break;
            }
        }
        let selectedAppClient = this.props.appClientList.find(item => item.clientId === value);
        if (selectedAppClient) {
            appClients[field.name].clientId = selectedAppClient.clientId;
            appClients[field.name].clientName = selectedAppClient.clientName;
            appClients[field.name].active = selectedAppClient.active;
            appClients[field.name].version = selectedAppClient.version;
            this.formRef.current.setFieldsValue({
                appClients
            });
        }
        this.setState({
            formErrors
        });
    }

    onDeletePermission = (field, callback = null) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`permissions.${field.name}.name`];
        this.setState({
            formErrors
        });
        if (callback) {
            callback();
        }
    }

    onDeleteUser = (field, callback = null) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`users.${field.name}.userId`];
        this.setState({
            formErrors
        });
        if (callback) {
            callback();
        }
    }

    onDeleteAppClient = (field, callback = null) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`appClients.${field.name}.clientId`];
        this.setState({
            formErrors
        });
        if (callback) {
            callback();
        }
    }

    validatePermissionsAndUsers = () => {
        for (let permission of this.state.permissions) {
            if (!permission.name || Object.keys(permission.errors).length > 0) {
                return false;
            }
        }
        for (let user of this.state.users) {
            if (!user.userId) {
                return false;
            }
        }
        return true;
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    getFormErrors = fieldName => {
        let formErrors = { ...this.props.formErrors, ...this.state.formErrors };
        if (formErrors[fieldName]) {
            return {
                help: formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    render() {
        if (!this.props.permission || !(this.props.permission.canEdit || this.props.permission.canAdd || this.props.permission.canView)) {
            return <ProhibitedArea></ProhibitedArea>
        }
        return (
            <Form
                ref={this.formRef}
                name="basic"
                initialValues={this.defaultRole}
                onFinish={this.onFormSubmit}
                onValuesChange={this.props.onValuesChanged}
                style={{ height: "100%" }}>
                <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }} wrap={false}>
                    <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={true}
                            header={<Row>
                                <Col span={8} style={{ textAlign: 'left' }}>
                                    <Form.Item
                                        {...this.getFormErrors("name")}
                                        label="Role Name"
                                        name={["name"]}
                                        validateFirst={true}
                                        rules={nameRules}>
                                        <Input readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_NAME_INPUT}/>
                                    </Form.Item>
                                </Col>
                                <Col span={16} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                    <Form.Item
                                        {...this.getFormErrors("description")}
                                        label="Role Description"
                                        name={["description"]}
                                        rules={descriptionRules}>
                                        <Input readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_DESCRIPTION_INPUT}/>
                                    </Form.Item>
                                </Col>
                            </Row>}

                            contentStyle={{ paddingBottom: "5px" }}
                            content={
                                <Tabs defaultActiveKey="1" style={{ height: "100%" }}>
                                    <TabPane tab="Permissions" key="1" style={{ height: "100%" }} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_TAB_PERMISSIONS_TAB}>
                                        <Form.List name={["permissions"]}>
                                            {(fields, { add, remove }) => {
                                                return <RolePermissionList
                                                    readOnly={this.isReadOnlyView()}
                                                    permissions={fields}
                                                    permissionMasterData={this.props.permissionMasterData}
                                                    formRef={this.formRef}
                                                    add={add}
                                                    remove={remove}
                                                    onPermissionChange={this.onPermissionChange}
                                                    onDeletePermission={this.onDeletePermission}
                                                    getFormErrors={this.getFormErrors} />;
                                            }}
                                        </Form.List>
                                    </TabPane>
                                    <TabPane tab="Users" key="2" style={{ height: "100%" }} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_TAB_USERS_TAB}>
                                        <Form.List name={["users"]}>
                                            {(fields, { add, remove }) => {
                                                return <RoleUserList
                                                    readOnly={this.isReadOnlyView()}
                                                    userList={this.props.userList}
                                                    users={fields}
                                                    formRef={this.formRef}
                                                    add={add}
                                                    remove={remove}
                                                    onUserChange={this.onUserChange}
                                                    onDeleteUser={this.onDeleteUser}
                                                    getFormErrors={this.getFormErrors} />;
                                            }}
                                        </Form.List>
                                    </TabPane>
                                    <TabPane tab="Credentials" key="3" style={{ height: "100%" }} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_TAB_CREDENTIALS_TAB}>
                                        <Form.List name={["appClients"]}>
                                            {(fields, { add, remove }) => {
                                                return <RoleAppClientList
                                                    readOnly={this.isReadOnlyView()}
                                                    appClientList={this.props.appClientList}
                                                    appClients={fields}
                                                    formRef={this.formRef}
                                                    add={add}
                                                    remove={remove}
                                                    onAppClientChange={this.onAppClientChange}
                                                    onDeleteAppClient={this.onDeleteAppClient}
                                                    getFormErrors={this.getFormErrors} />;
                                            }}
                                        </Form.List>
                                    </TabPane>
                                </Tabs>}
                            footer={
                                <Row>
                                    <Col span={24} className="footer-right-column">
                                        <Space>
                                            <Button onClick={this.props.onCancel} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_CANCEL_BUTTON}>Cancel</Button>
                                            <Button type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()} data-testid={CypressTestIds.ROLES_MANAGE_ROLE_SAVE_BUTTON}>Save</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            }>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default ManageRole