import React from "react";
import { Space, Table, Typography } from "antd";
import { FullHeightContainerLayout } from 'components';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Title, Text } = Typography;
const { Column } = Table;

const DomainDataPreviewList = ({
    recordCount,
    domainDataList,
    loading,
    tableHeight = 30,
    tableClasses = [],
    showRecordsHeader = true,
    onPageSelect,
    contentStyle = {},
    headerStyle = {},
    style = {}
}) => {
    return (
        <FullHeightContainerLayout
            contentStyle={contentStyle}
            style={style}
            headerStyle={headerStyle}
            showFooter={false}
            showHeader={showRecordsHeader}
            header={<Space direction="horizontal">
                <Text type="secondary">Number of records: </Text>
                <Text type="success">{recordCount || 0}</Text>
            </Space>}
            content={
                <Table
                data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_DATA_DOMAIN_DATA_PREVIEW_LIST}
                    dataSource={domainDataList}
                    size="small"
                    rowKey="domainDataId"
                    scroll={tableHeight ? { y: `${tableHeight}vh` } : false}
                    loading={loading}
                    className={tableClasses.join(" ")}
                    pagination={{
                        position: ["topRight"],
                        defaultPageSize: 100,
                        pageSize: 100,
                        responsive: true,
                        showSizeChanger: false
                    }}
                    onChange={(pagination, filters, sorter, extra) => extra.action === "paginate" && onPageSelect && onPageSelect(pagination)}>
                    <Column
                        width="10%"
                        title="No."
                        key="index"
                        render={(value, item, index) => item.sNo || index + 1}
                    />
                    <Column
                        width="30%"
                        dataIndex={"code"}
                        title={"Code"}
                        render={(value, data, index) => <>{value}</>}
                    />
                    <Column
                        width="60%"
                        dataIndex={"description"}
                        title={"Description"}
                        render={(value, data, index) => <>{value}</>}
                    />
                </Table>
            }>
        </FullHeightContainerLayout>
    );
}

export default DomainDataPreviewList;