import React from 'react';
import { Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const PreviewOverlay = ({ isVisible, onToggle, children }) => {
  return (
    <div className="relative mt-6">
      <div className="flex justify-center mb-4">
        <Button
          type='primary'
          icon={isVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={onToggle}
          className="flex items-center hover:scale-105 transition-transform"
          size="large"
        >
          Preview Tag
        </Button>
      </div>
      <div
        className={`
          fixed inset-0 bg-gray-900/30 backdrop-blur-sm transition-all duration-300
          ${isVisible ? 'opacity-100 z-50' : 'opacity-0 -z-10 pointer-events-none'}
        `}
        onClick={onToggle}
      >
        <div
          className={`
            absolute right-0 top-0 h-full w-full max-w-3xl bg-gray-50 shadow-2xl
            transform transition-transform duration-300 ease-out
            ${isVisible ? 'translate-x-0' : 'translate-x-full'}
          `}
          onClick={e => e.stopPropagation()}
        >
          <div className="bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Live Preview</h2>
              <p className="text-sm text-gray-500">Preview updates as you type</p>
            </div>
            <Button
              type="default"
              danger
              onClick={onToggle}
              size="large"
              className="hover:bg-red-50"
            >
              <div className='text-base font-semibold'> 
                Close Preview
              </div>
            </Button>
          </div>
          <div className="p-6 h-[calc(100vh-5rem)] overflow-y-auto">
            <div className="max-w-2xl mx-auto">
              <div className="mb-6">
                <div className="p-3 bg-blue-50 border-l-4 border-blue-500 rounded">
                  <p className=" text-base text-blue-700 font-medium">
                    This is a live preview of how your data tag will appear in card view to users.
                    Make changes in the form to see them reflected here immediately.
                  </p>
                </div>
              </div>
              <div className="w-[400px] mx-auto">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewOverlay;