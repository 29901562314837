import React, { useEffect, useState } from "react";
import { Form, Empty, Spin, Button } from "antd";
import { Header } from 'semantic-ui-react';
import _ from 'lodash';
import { FormField, FullHeightContainerLayout } from 'components';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUnmaskedDataByUUIDRequest } from "store/dataObjects/actions";

const ReadOnlyFormBuilder = ({ selectedDataObject, schemaFields, onSave}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [updatedFields, setUpdatedFields] = useState({});
    const unmaskedData = useSelector((state) => state.dataObjects.unmaskedData);

    useEffect(() => {
        if (selectedDataObject?.dataObjectId) {
            setLoading(true);
            dispatch(getUnmaskedDataByUUIDRequest(selectedDataObject.dataObjectId));
        }
    }, [selectedDataObject, dispatch]);

    useEffect(() => {
        if (unmaskedData) {
            setLoading(false);
            form.setFieldsValue(unmaskedData.dataObject);
        }
    }, [unmaskedData, form]);    

    const dataToRender = unmaskedData?.dataObject || selectedDataObject;
    const fieldsToRender = unmaskedData?.schemaModel?.fields || schemaFields;
    
    const handleValuesChange = (changedValues, allValues) => {
        const changes = {};
        Object.keys(changedValues).forEach((key) => {
            if (!_.isEqual(changedValues[key], dataToRender[key])) {
                changes[key] = changedValues[key];
            }
        });
        setUpdatedFields((prev) => ({ ...prev, ...changes }));
    };

    const handleSave = () => {
        if (onSave) {
            onSave(updatedFields); 
        }
    };

    if (!dataToRender) {
        return (
            <>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Dataset record selected" />
            </>
        );
    }    

    return (
        <FullHeightContainerLayout
            showHeader={false}
            header={
                <Header as='h5' dividing style={{ marginBottom: 12 }}>
                    Dataset Record
                </Header>
            }
            content={
                loading ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Spin size="large" tip="Loading data..." />
                    </div>
                ) : (
                    <Form
                        form={form}
                        initialValues={dataToRender}
                        name="readonly-form"
                        layout="vertical"
                        className="readonly-form-builder"
                        onValuesChange={handleValuesChange}
                    >
                       {fieldsToRender?.map((field) => (
                        <FormField
                            key={field.fieldId}
                            {...field}
                            value={dataToRender[field.fieldId]}
                            readOnly={false}
                        />
                        ))}
                    </Form>
                )
            }
            footer={
                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                    <Button type="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            }
        />
    );
};

export default ReadOnlyFormBuilder;
