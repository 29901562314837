import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Alert, Modal, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { ChangePassword, ModalLoadingOverlay } from 'components';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import CypressTestIds from '../cypress/CypressTestIds';

const { Title, Text, Link } = Typography;

class UserChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userPasswordUpdated: false,
            codeExpired: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updateUserPasswordResult && this.props.updateUserPasswordResult !== prevProps.updateUserPasswordResult) {
            if (this.props.updateUserPasswordResult.success) {
                this.setState({
                    userPasswordUpdated: true
                })
            }
            else {
                if (this.props.updateUserPasswordResult.code === "ExpiredCodeException") {
                    this.setState({
                        codeExpired: true
                    })
                }
                else {
                    if (this.props.updateUserPasswordResult.fields) {
                        this.setState({
                            updatePasswordErrors: this.props.updateUserPasswordResult.fields
                        });
                    }
                    else {
                        showError("Update password failed. Please contact your account administrator.");
                    }
                }
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    updateUserPassword = (newPassword) => {
        this.setState({
            busy: true,
            busyMessage: "Updating password..."
        });
        this.props.updateUserPassword(this.props.username, this.props.code, newPassword);
    }


    validChangePasswordOptions = () => {
        if (this.props.code && this.props.username && this.props.clientId && this.props.region && this.props.email) {
            return true;
        }
        return false;
    }

    goBackToLoginPage = () => {
        this.props.history.push(AppPaths.LOGIN);
    }

    render() {
        return (
            <>
                {this.state.userPasswordUpdated ||  this.state.codeExpired ?
                    <Modal
                        centered
                        closable={false}
                        footer={null}
                        forceRender={true}
                        keyboard={false}
                        maskClosable={false}
                        visible={this.state.userPasswordUpdated ||  this.state.codeExpired}>
                        <>
                            <Row className="full-height-container center-align-items">
                                <Col span={24}>
                                    {this.state.codeExpired ?
                                        <Alert
                                            message={<Text data-testid={CypressTestIds.USER_CHANGE_PASSWORD_ERROR_MESSAGE}>Password reset link expired</Text>}
                                            description="Please go back to login page and click forgot password again to resend password reset link"
                                            type="error"
                                            showIcon
                                        />
                                        :
                                        <Alert
                                            message={<Text data-testid={CypressTestIds.USER_CHANGE_PASSWORD_SUCCESS_MESSAGE}>Password update successfully</Text>}
                                            description="Please go back to login page and login with new password"
                                            type="success"
                                            showIcon
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className="middle-row full-height-container center-align-items">
                                <Col span={24}>
                                    <Button data-testid={CypressTestIds.USER_RESET_PASSWORD_BACKTOLOGIN_BUTTON} block type="primary" onClick={this.goBackToLoginPage}>Go back to login</Button>
                                </Col>
                            </Row>
                        </>
                    </Modal> :
                    <></>}
                <ModalLoadingOverlay busy={this.state.busy} message={this.state.busyMessage}></ModalLoadingOverlay>
                <Row className="full-height-container middle-row">
                    <Col className="full-height-container" span={24}>
                        <Row className="full-height-container center-align-items">
                            <Col span={24}>
                                <Row>
                                    <Col span={24} className="full-height-container center-align-items">
                                        <Title level={3}>Change Password</Title>
                                    </Col>
                                </Row>
                                {
                                    this.validChangePasswordOptions() ?
                                        <Row className="middle-row">
                                            <Col span={24}>
                                                <ChangePassword onUpdateUserPassword={this.updateUserPassword} formErrors={this.state.updatePasswordErrors}></ChangePassword>
                                            </Col>
                                        </Row>
                                        :
                                        <Row className="middle-row">
                                            <Col span={24} className="full-height-container center-align-items">
                                                <Alert
                                                    message={<Title level={4}><Text type="danger" data-testid={CypressTestIds.USER_CHANGE_PASSWORD_ERROR_MESSAGE}>Invalid password change link</Text></Title>}
                                                    description="The password change link is broken. Please contact support."
                                                    type="error"
                                                    showIcon
                                                />
                                            </Col>
                                        </Row>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        updateUserPasswordResult: state.userChangePassword.updateUserPasswordResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserPassword: (username, code, newPassword) => dispatch(actions.updateUserPasswordRequest(username, code, newPassword))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserChangePassword));