export const setDataMapperFlowAction = (
    payload: IDataMapperFlowPayload,
): IDataMapperFlowAction => ({
    type: "IDATA_MAPPER_FLOW",
    payload: payload,
});

export const setDataMapperFlowClearAction = (): IDataMapperFlowClearAction => ({
    type: "IDATA_MAPPER_FLOW_CLEAR",
});
