import { PropsWithChildren } from "react";

export default function DataObjectBulkUploadLayout({
    children,
}: PropsWithChildren) {
    return (
        <div className="relative flex h-full w-full flex-1 flex-col gap-4">
            {children}
        </div>
    );
}

function Header({ children }: PropsWithChildren) {
    return (
        <header
            data-testid="bulk-upload-layout-header"
            className="flex flex-col gap-4 md:flex-row"
        >
            {children}
        </header>
    );
}

function Footer({ children }: PropsWithChildren) {
    return (
        <footer
            data-testid="bulk-upload-layout-footer"
            className="flex items-center justify-end gap-2"
        >
            {children}
        </footer>
    );
}
export { Footer, Header };
