import {
    CREATE_DATA_OBJECTS_UPLOAD_JOB_SUCCESS,
    CREATE_DATA_OBJECTS_UPLOAD_JOB_FAILURE,

    GET_DATA_OBJECTS_BY_DATASET_ID_SUCCESS,
    GET_DATA_OBJECTS_BY_DATASET_ID_FAILURE,

    GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_SUCCESS,
    GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_FAILURE,

    GET_DATA_OBJECT_SUCCESS,
    GET_DATA_OBJECT_FAILURE,

    CREATE_DATA_OBJECT_SUCCESS,
    CREATE_DATA_OBJECT_FAILURE,

    UPDATE_DATA_OBJECT_SUCCESS,
    UPDATE_DATA_OBJECT_FAILURE,

    DELETE_DATA_OBJECT_SUCCESS,
    DELETE_DATA_OBJECT_FAILURE,

    GET_DATA_OBJECT_PERMISSIONS_SUCCESS,
    GET_DATA_OBJECT_PERMISSIONS_FAILURE,

    GET_DATA_OBJECT_BY_UUID_SUCCESS,
    GET_DATA_OBJECT_BY_UUID_FAILURE,

    GET_DATA_OBJECT_SHARED_LINK_SUCCESS,
    GET_DATA_OBJECT_SHARED_LINK_FAILURE,

    GET_UNMASKED_DATA_BY_UUID_SUCCESS,
    GET_UNMASKED_DATA_BY_UUID_FAILURE,

    UPDATE_DATA_OBJECT_VALIDATION_ERRORS_REQUEST
} from "./actions";

const initialState = {
    createDataObjectsUploadJobResult: {
        success: true
    },
    getDataObjectsByDataSetIdResult: {
        success: true
    },
    getDataObjectsByDataSetIdNextPageResult: {
        success: true
    },
    dataObjectsByDataSetIdResult: {
        Items: []
    },
    getDataObjectResult: {
        success: true
    },
    dataObject: null,
    createDataObjectResult: {
        success: true
    },
    updateDataObjectResult: {
        success: true
    },
    deleteDataObjectResult: {
        success: true
    },
    getDataObjectPermissionResult: {
        success: true
    },
    dataObjectPermission: null,
    getDataObjectByUUIDResult: {
        success: true,
    },
    dataObjectByUUIDResult: null,
    getDataObjectSharedLinkResult: {
        success: true,
    },
    dataObjectSharedLinkResult: null,
    getUnmaskedDataByUUIDResult: { 
        success: true 
    },
    unmaskedData: null,
};

export default (state = initialState, action) => {
    let dataObjectSno = 0;
    let dataObjectsByDataSetIdResult = {};
    let dataObjectIndex = -1;
    switch (action.type) {
        case CREATE_DATA_OBJECTS_UPLOAD_JOB_SUCCESS:
            return { ...state, createDataObjectsUploadJobResult: { success: true } };
        case CREATE_DATA_OBJECTS_UPLOAD_JOB_FAILURE:
            return { ...state, createDataObjectsUploadJobResult: { success: false, ...action.payload.error } };
        case GET_DATA_OBJECT_SUCCESS:
            dataObjectsByDataSetIdResult = state.dataObjectsByDataSetIdResult;
            if (action.payload.dataObject && dataObjectsByDataSetIdResult && dataObjectsByDataSetIdResult.Items && dataObjectsByDataSetIdResult.Items.length > 0) {
                let dataObjectIndex = dataObjectsByDataSetIdResult.Items.findIndex(item => item.dataObjectId === action.payload.dataObject.dataObjectId);
                if (dataObjectIndex > -1) {
                    dataObjectsByDataSetIdResult = { ...dataObjectsByDataSetIdResult };
                    dataObjectsByDataSetIdResult.Items = [...dataObjectsByDataSetIdResult.Items];
                    dataObjectsByDataSetIdResult.Items[dataObjectIndex] = action.payload.dataObject;
                }
            }
            return { ...state, getDataObjectResult: { success: true }, dataObject: action.payload.dataObject, dataObjectsByDataSetIdResult };

        case GET_DATA_OBJECT_FAILURE:
            return { ...state, getDataObjectResult: { success: false, ...action.payload.error }, dataObject: null };
        case GET_DATA_OBJECTS_BY_DATASET_ID_SUCCESS:
            dataObjectSno = 0;
            for (let item of action.payload.dataObjectsResult.Items) {
                item.sNo = dataObjectSno + 1;
                dataObjectSno = item.sNo;
            }
            return { ...state, getDataObjectsByDataSetIdResult: { success: true }, dataObjectsByDataSetIdResult: action.payload.dataObjectsResult };
        case GET_DATA_OBJECTS_BY_DATASET_ID_FAILURE:
            return { ...state, getDataObjectsByDataSetIdResult: { success: false, ...action.payload.error }, dataObjectsByDataSetIdResult: { Items: [] } };
        case GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_SUCCESS:
            dataObjectSno = state.dataObjectsByDataSetIdResult.Items.length;
            for (let item of action.payload.dataObjectsResult.Items) {
                item.sNo = dataObjectSno + 1;
                dataObjectSno = item.sNo;
            }
            return {
                ...state,
                getDataObjectsByDataSetIdNextPageResult: {
                    success: true
                },
                dataObjectsByDataSetIdResult: {
                    ...action.payload.dataObjectsResult,
                    Items: state.dataObjectsByDataSetIdResult.Items.concat(action.payload.dataObjectsResult.Items)
                }
            };
        case GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_FAILURE:
            return {
                ...state,
                getDataObjectsByDataSetIdNextPageResult: {
                    success: false,
                    ...action.payload.error
                }
            };
        case CREATE_DATA_OBJECT_SUCCESS:
            dataObjectsByDataSetIdResult = { ...state.dataObjectsByDataSetIdResult };
            dataObjectsByDataSetIdResult.Items = [...dataObjectsByDataSetIdResult.Items];
            dataObjectsByDataSetIdResult.Items.splice(0, 0, action.payload.dataObject);
            return { ...state, createDataObjectResult: { success: true }, dataObjectsByDataSetIdResult };
        case CREATE_DATA_OBJECT_FAILURE:
            return {
                ...state,
                createDataObjectResult: {
                    success: false,
                    ...action.payload.error
                }
            };
        case UPDATE_DATA_OBJECT_SUCCESS:
            dataObjectsByDataSetIdResult = { ...state.dataObjectsByDataSetIdResult };
            dataObjectsByDataSetIdResult.Items = [...dataObjectsByDataSetIdResult.Items];
            dataObjectIndex = dataObjectsByDataSetIdResult.Items.findIndex(dataObject => dataObject.dataObjectId === action.payload.dataObjectId);
            if (dataObjectIndex > -1) {
                dataObjectsByDataSetIdResult.Items[dataObjectIndex] = { ...dataObjectsByDataSetIdResult.Items[dataObjectIndex], ...action.payload.dataObject };
            }
            return { ...state, updateDataObjectResult: { success: true }, dataObjectsByDataSetIdResult };
        case UPDATE_DATA_OBJECT_FAILURE:
            return {
                ...state,
                updateDataObjectResult: {
                    success: false,
                    ...action.payload.error
                }
            };
        case DELETE_DATA_OBJECT_SUCCESS:
            dataObjectsByDataSetIdResult = { ...state.dataObjectsByDataSetIdResult };
            dataObjectsByDataSetIdResult.Items = [...dataObjectsByDataSetIdResult.Items];
            dataObjectIndex = dataObjectsByDataSetIdResult.Items.findIndex(dataObject => dataObject.dataObjectId === action.payload.dataObjectId);
            if (dataObjectIndex > -1) {
                dataObjectsByDataSetIdResult.Items.splice(dataObjectIndex, 1);
            }
            return { ...state, deleteDataObjectResult: { success: true }, dataObjectsByDataSetIdResult };
        case DELETE_DATA_OBJECT_FAILURE:
            return { ...state, deleteDataObjectResult: { success: false, ...action.payload.error } };
        case UPDATE_DATA_OBJECT_VALIDATION_ERRORS_REQUEST:
            dataObjectsByDataSetIdResult = { ...state.dataObjectsByDataSetIdResult };
            dataObjectsByDataSetIdResult.Items = [...dataObjectsByDataSetIdResult.Items];
            dataObjectIndex = dataObjectsByDataSetIdResult.Items.findIndex(dataObject => dataObject.dataObjectId === action.params.dataObject.dataObjectId);
            if (dataObjectIndex > -1) {
                dataObjectsByDataSetIdResult.Items[dataObjectIndex] = { ...dataObjectsByDataSetIdResult.Items[dataObjectIndex], errors: action.params.validationErrors };
            }
            return { ...state, dataObjectsByDataSetIdResult };
        case GET_DATA_OBJECT_PERMISSIONS_SUCCESS:
            return { ...state, getDataObjectPermissionResult: { success: true }, dataObjectPermission: action.payload.permission };
        case GET_DATA_OBJECT_PERMISSIONS_FAILURE:
            return { ...state, getDataObjectPermissionResult: { success: false, ...action.payload.error }, dataObjectPermission: null };
        case GET_DATA_OBJECT_BY_UUID_SUCCESS:
            return { ...state, getDataObjectByUUIDResult: { success: true }, dataObjectByUUIDResult: action.payload.result };
        case GET_DATA_OBJECT_BY_UUID_FAILURE:
            return { ...state, getDataObjectByUUIDResult: { success: false, ...action.payload.error }, dataObjectByUUIDResult: null };
        case GET_DATA_OBJECT_SHARED_LINK_SUCCESS:
            return { ...state, getDataObjectSharedLinkResult: { success: true }, dataObjectSharedLinkResult: action.payload.result };
        case GET_DATA_OBJECT_SHARED_LINK_FAILURE:
            return { ...state, getDataObjectSharedLinkResult: { success: false, ...action.payload.error }, dataObjectSharedLinkResult: null };
        case GET_UNMASKED_DATA_BY_UUID_SUCCESS:
            return {
                ...state,
                getUnmaskedDataByUUIDResult: { success: true },
                unmaskedData: action.payload.result,
            };
        case GET_UNMASKED_DATA_BY_UUID_FAILURE:
            return {
                ...state,
                getUnmaskedDataByUUIDResult: { success: false, ...action.payload.error },
                unmaskedData: null,
            };
        default:
            return state;
    }
};
