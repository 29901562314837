import { put, call, select, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import { Endpoints } from "services/api";

const getTenant = (state) => state.userLogin.tenant;
const getUser = (state) => state.userLogin.user;

function* fetchUserPIIPermission(api, tenantId, userId) {
    const result = yield call(
        [api, api.get],
        `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/pii`,
        { tenantid: tenantId }
    );
    return result.data;
}

export function* getUserPIIPermissionRequest(api) {
    try {
        const tenant = yield select(getTenant);
        const user = yield select(getUser);
        const permissionResponse = yield call(fetchUserPIIPermission, api, tenant.tenantId, user.userId);

        let permission = { canView: false };
        if (permissionResponse?.Items?.length > 0) {
            const actions = permissionResponse.Items[0].actions || [];
            if (actions.includes("view")) {
                permission.canView = true;
            }
        }

        yield put({ type: "GET_USER_PII_PERMISSION_SUCCESS", payload: permission });
    } catch (error) {
        yield put({ type: "GET_USER_PII_PERMISSION_FAILURE", payload: error.message });
    }
}


export function* watchGetUserPIIPermissionRequest(api) {
    yield takeLatest(actions.GET_USER_PII_PERMISSION_REQUEST, getUserPIIPermissionRequest, api);
}

export default function* ({ api }) {
    yield watchGetUserPIIPermissionRequest(api);
}
