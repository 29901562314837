import {
    CheckCircleOutlined,
    CloseCircleFilled,
    CloseCircleOutlined,
    FileExclamationFilled,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useMemo } from "react";
import DataPreviewTable, { IDataPreviewDataType } from "./DataPreviewTable";
import "./style.css";

function DataPreview({
    onClose,
    columns,
    data,
    title = "Data Preview (100 Records)",
    dataSchemaColumns = [],
    className = "",
    closeLabel,
    closeTooltip,
    transposed = false,
}: IDataPreviewProps) {
    const headers = React.useMemo(() => {
        const columnHeaders: IDataPreviewDataType = {};
        columns.map(
            (column) =>
                (columnHeaders[column] = (
                    <>
                        {column}
                        {dataSchemaColumns &&
                            (dataSchemaColumns.findIndex(
                                (item) =>
                                    item.toLowerCase() === column.toLowerCase(),
                            ) > -1 ? (
                                <CheckCircleOutlined className="ml-1 text-green-600" />
                            ) : (
                                <CloseCircleOutlined className="ml-1 text-red-600" />
                            ))}
                    </>
                )),
        );

        return columnHeaders;
    }, [columns, dataSchemaColumns]);

    const buttonClassName = useMemo(
        () =>
            closeLabel
                ? "flex items-center gap-1 rounded-md bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-600"
                : "text-xl text-red-500 hover:text-red-600",
        [closeLabel],
    );

    return (
        <div
            className={["flex size-full flex-1 flex-col", className].join(" ")}
        >
            <div className="flex flex-row items-center justify-between py-2">
                <h3 className="text-sm font-bold">{title}</h3>
                {onClose && (
                    <Tooltip placement="topRight" title={closeTooltip}>
                        <button
                            onClick={onClose}
                            aria-label="Close"
                            className={buttonClassName}
                        >
                            {closeLabel ? (
                                <CloseCircleOutlined />
                            ) : (
                                <CloseCircleFilled />
                            )}
                            {closeLabel && (
                                <p className="uppercase">{closeLabel}</p>
                            )}
                        </button>
                    </Tooltip>
                )}
            </div>
            {data.length > 0 ? (
                <div className="scroll-webkit w-full flex-1 overflow-auto border border-slate-100">
                    <DataPreviewTable
                        headers={headers}
                        rows={data}
                        transposed={transposed}
                    />
                </div>
            ) : (
                <div className="flex w-full flex-1 flex-col items-center justify-center overflow-auto border border-dashed border-slate-300">
                    <FileExclamationFilled className="mb-3 text-3xl text-slate-500" />
                    <p className="text-sm">No data found</p>
                </div>
            )}
        </div>
    );
}

export default DataPreview;
