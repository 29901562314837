import React, { useState, useEffect } from 'react';
import { Form, Select } from "antd";
import { SchemaFieldTitle } from 'components';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';
const { Option } = Select;

const PicklistInput = ({ value, onChange, items, fetchingData, isRequired, disabled }) => {
    const [picklistValue, setPicklistValue] = useState(value);
    const [options, setOptions] = useState(items);
    const [loading, setLoading] = useState(fetchingData);

    useEffect(() => {
        setOptions(items);
        setLoading(fetchingData);
    }, [items, fetchingData]);

    const onPicklistValueChange = (value, option) => {
        setPicklistValue(value);
        onChange(value);
    }

    return <Select
        defaultValue={value}
        value={picklistValue}
        onChange={onPicklistValueChange}
        loading={loading}
        allowClear={!isRequired}
        disabled={disabled}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }>
        {
            options && options.map((option, index) => <Option key={index} value={option.code}>{`${option.code}${option.description && ` (${option.description})`}`}</Option>)
        }
    </Select>
}

function FormPicklistField({ fieldErrors, fieldId, name, description, isRequired, items, fetchingData, disabled = false, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
        }
        tooltip={description}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim() && fetchingData === false) {
                        for (let item of items) {
                            if (item.code === value.trim()) {
                                return Promise.resolve();
                            }
                        }
                        return Promise.reject(`Invalid value. Value should be a valid picklist value`);
                    }
                    return Promise.resolve();
                }
            }
        ]}
        {...fieldErrors}>
        <PicklistInput fetchingData={fetchingData} items={items} isRequired={isRequired} disabled={disabled} />
    </Form.Item>
}

export default FormPicklistField