import {
    DeleteRowOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    Tooltip,
    Typography,
} from "antd";
import { FormulaErrorId, Parser, SUPPORTED_FORMULAS } from "hot-formula-parser";
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import validIdentifier from "valid-identifier";
import FormulaVariableValue from "./FormulaVariableValue";

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;
const { Text } = Typography;

type FormulaParserProps = {
    expression: string;
    variables?: IFormulaVariable[];
    inputColumns: string[];
    inputData: IFormulaParserDataInput[];
};

const numMapping = ["Ze", "On", "Tw", "Th", "Fr", "Fv", "Sx", "Sn", "Et", "Nn"];

const FormulaParser = forwardRef<IEvaluateExpressionHandle, FormulaParserProps>(
    ({ expression, variables, inputColumns, inputData }, ref) => {
        const [currentDataRow, setCurrentDataRow] = useState<number | null>(1);
        const [evaluationResult, setEvaluationResult] = useState<{
            result: string | number | boolean | null;
            error: FormulaErrorId | string | null;
        }>({
            result: null,
            error: null,
        });
        const [evaluateExpressionEnabled, setEvaluateExpressionEnabled] =
            useState(true);
        const [formulaExpression, setFormulaExpression] = useState(expression);
        const [form] = Form.useForm();
        const inputDelayTimer = useRef<NodeJS.Timeout>();
        const [initialValues, setInitialValues] = React.useState<
            IFormulaVariable[]
        >(variables ?? []);

        useImperativeHandle(ref, () => ({
            evaluateExpression() {
                return evaluate();
            },
        }));

        const onEvaluateExpressionClick = () => {
            evaluate();
        };

        const supportedFormulaOptions = React.useMemo(() => {
            const options: { label: string; value: string }[] = [];
            [...SUPPORTED_FORMULAS]
                .sort()
                .filter((a, i) => {
                    // Remove duplicates
                    if (i === 0) {
                        return true;
                    }

                    return a !== SUPPORTED_FORMULAS[i - 1];
                })
                .map((f) =>
                    options.push({
                        value: f,
                        label: f,
                    }),
                );

            return options;
        }, []);

        const evaluate = (): IEvaluateExpressionHandleResponse => {
            if (!evaluateExpressionEnabled) {
                return {
                    error: "ERROR",
                };
            }

            const formulaVariables = form.getFieldsValue(true);
            const parser = new Parser();
            let dataObject =
                inputData && inputData.length > 0 && currentDataRow
                    ? inputData[currentDataRow - 1]
                    : null;
            if (
                formulaVariables.variables &&
                formulaVariables.variables.length > 0
            ) {
                for (let variable of formulaVariables.variables) {
                    let variableValue = variable.value;
                    if (variable.valueType === "DATA_COLUMN") {
                        if (dataObject) {
                            variableValue =
                                inputData &&
                                inputData.length > 0 &&
                                currentDataRow
                                    ? inputData[currentDataRow - 1][
                                          variableValue
                                      ]
                                    : "";
                        }
                    }
                    parser.setVariable(variable.name, variableValue);
                }
            }
            if (dataObject) {
                for (let key in dataObject) {
                    if (validIdentifier(key) && !/.*\d$/.test(key)) {
                        // Must validate key
                        parser.setVariable(key, dataObject[key]);
                    }
                }
            }

            const parserResult = parser.parse(formulaExpression);
            const currentEvaluationResult = { ...evaluationResult };
            currentEvaluationResult.result = parserResult.result;
            if (parserResult.error) {
                switch (parserResult.error) {
                    case "#ERROR!":
                        currentEvaluationResult.error =
                            "Something went wrong with the expression execution.";
                        break;
                    case "#DIV/0!":
                        currentEvaluationResult.error =
                            "A divide by zero error occurred.";
                        break;
                    case "#NAME?":
                        currentEvaluationResult.error =
                            "Function or variable not found.";
                        break;
                    case "#N/A":
                        currentEvaluationResult.error =
                            "Value could not be available to function.";
                        break;
                    case "#NUM!":
                        currentEvaluationResult.error =
                            "Invalid numeric value provided to function.";
                        break;
                    case "#VALUE!":
                        currentEvaluationResult.error =
                            "Invalid argument type provided to function.";
                        break;
                }
            } else {
                currentEvaluationResult.error = "";
            }

            setEvaluationResult(currentEvaluationResult);
            return {
                ...currentEvaluationResult,
                variables: formulaVariables.variables,
                formulaExpression,
            };
        };

        const onVariablesValueChanged = async () => {
            setEvaluateExpressionEnabled(false);
            try {
                await form.validateFields({ validateOnly: true });
                setEvaluateExpressionEnabled(true);
            } catch (error: unknown) {
                if (
                    error &&
                    typeof error === "object" &&
                    Object.hasOwn(error, "errorFields")
                ) {
                    for (const [key, value] of Object.entries(error)) {
                        if (key === "errorFields" && Array.isArray(value)) {
                            setEvaluateExpressionEnabled(value.length === 0);
                        }
                    }
                }
            }
        };

        const onFormulaExpressionChanged = (
            e: React.ChangeEvent<HTMLTextAreaElement>,
        ) => {
            setFormulaExpression(e.target.value);
        };

        const onInputDataRowChange = (value: number | null) => {
            setCurrentDataRow(value);
        };

        const getDataRecords = (record: Record<string, string> | null) => {
            const attributeList = [];
            if (record) {
                for (let key in record) {
                    attributeList.push({
                        name: key,
                        value: record[key],
                        warning:
                            validIdentifier(key) === false || /.*\d$/.test(key)
                                ? "Attribute is not a valid formula expression variable. Please use variable with data column mapping."
                                : "",
                    });
                }
            }
            return attributeList;
        };

        // Check the input data for invalid Attribute names,
        // Then set the initial values for Variables form.
        React.useEffect(() => {
            const newVariables: IFormulaVariable[] = variables ?? [];

            inputData.forEach((col) => {
                for (let key in col) {
                    if (!validIdentifier(key) || /.*\d$/.test(key)) {
                        // Attribute name is not valid, i.e. has a space somewhere or ends with a number
                        // Create a variable without space and special characters
                        const trimmedName = key
                            .replace(/[^A-Z0-9]+/gi, "")
                            .replace(/[0-9]/g, (m) => numMapping[Number(m)]);
                        if (!newVariables.some((v) => v.name === trimmedName)) {
                            // Make sure it's unique in the list
                            const variable: IFormulaVariable = {
                                valueType: "DATA_COLUMN",
                                value: key,
                                name: trimmedName,
                            };
                            newVariables.push(variable);
                        }
                    }
                }
            });

            setInitialValues(newVariables);
        }, [inputData, variables]);

        // Reset the form when initialValues have changed.
        React.useEffect(() => {
            form.resetFields();
        }, [initialValues, form]);

        return (
            <div className="flex size-full flex-row gap-3">
                <div className="flex flex-1 flex-col bg-gray-50 p-3">
                    <Title level={5}>Variables</Title>
                    <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        initialValues={{
                            variables: initialValues,
                        }}
                        onValuesChange={() => {
                            // Set a timer so that it doesn't check every character
                            if (inputDelayTimer.current) {
                                clearTimeout(inputDelayTimer.current);
                            }
                            inputDelayTimer.current = setTimeout(() => {
                                onVariablesValueChanged();
                                clearTimeout(inputDelayTimer.current);
                            }, 1000);
                        }}
                    >
                        <Form.List name="variables">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <div
                                                className="flex flex-row gap-2"
                                                key={key}
                                            >
                                                <div className="flex-1">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "name"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Name is required",
                                                            },
                                                            {
                                                                pattern:
                                                                    /^[a-zA-Z]+$/,
                                                                message:
                                                                    "Only alphabets allowed",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Name" />
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            name,
                                                            "valueType",
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Please select type of value",
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Value Type">
                                                            <Option value="CONSTANT">
                                                                = Constant
                                                            </Option>
                                                            <Option value="DATA_COLUMN">
                                                                = Data column
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(
                                                            prevValues,
                                                            currentValues,
                                                        ) => {
                                                            if (
                                                                prevValues
                                                                    .variables[
                                                                    name
                                                                ] &&
                                                                currentValues
                                                                    .variables[
                                                                    name
                                                                ]
                                                            ) {
                                                                if (
                                                                    prevValues
                                                                        .variables[
                                                                        name
                                                                    ]
                                                                        .valueType !==
                                                                    currentValues
                                                                        .variables[
                                                                        name
                                                                    ].valueType
                                                                ) {
                                                                    return true;
                                                                }
                                                                if (
                                                                    prevValues
                                                                        .variables[
                                                                        name
                                                                    ].value !==
                                                                    currentValues
                                                                        .variables[
                                                                        name
                                                                    ].value
                                                                ) {
                                                                    return true;
                                                                }
                                                            }
                                                            return false;
                                                        }}
                                                    >
                                                        {({
                                                            getFieldValue,
                                                        }) => {
                                                            return (
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "value",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required:
                                                                                true,
                                                                            message:
                                                                                "Value is required",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <FormulaVariableValue
                                                                        dataColumns={
                                                                            inputColumns
                                                                        }
                                                                        valueType={getFieldValue(
                                                                            [
                                                                                "variables",
                                                                                name,
                                                                                "valueType",
                                                                            ],
                                                                        )}
                                                                    ></FormulaVariableValue>
                                                                </Form.Item>
                                                            );
                                                        }}
                                                    </Form.Item>
                                                </div>
                                                <div className="pt-1">
                                                    <DeleteRowOutlined
                                                        className="text-red-600 hover:text-red-800"
                                                        onClick={() =>
                                                            remove(name)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    )}
                                    <Form.Item>
                                        <Button
                                            onClick={() =>
                                                add({
                                                    valueType: "DATA_COLUMN",
                                                })
                                            }
                                            block
                                            style={{
                                                width: "100%",
                                            }}
                                            size="small"
                                            type="primary"
                                        >
                                            <PlusOutlined /> Add variable
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form>
                </div>
                <div className="flex h-full flex-1 flex-col gap-3">
                    <div className="flex flex-row gap-3">
                        <div>
                            <InputNumber
                                style={{ width: "100%" }}
                                addonBefore="Data row"
                                min={1}
                                max={inputData ? inputData.length : 1}
                                value={currentDataRow}
                                onChange={onInputDataRowChange}
                            />
                        </div>
                        <div className="flex-1">
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                showSearch
                                placeholder="Formula Reference"
                                options={supportedFormulaOptions}
                                optionFilterProp="label"
                            />
                        </div>
                    </div>
                    <div className="flex-1 overflow-auto">
                        <table className="table w-full">
                            <thead className="table-header-group">
                                <tr className="table-row bg-slate-100">
                                    <th className="sticky text-wrap p-2 text-left">
                                        Attribute Name
                                    </th>
                                    <th className="sticky text-wrap p-2 text-left">
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {getDataRecords(
                                    inputData &&
                                        inputData.length > 0 &&
                                        currentDataRow
                                        ? inputData[currentDataRow - 1]
                                        : null,
                                ).map((r) => (
                                    <tr className="table-row" key={uuidv4()}>
                                        <td className="table-cell text-wrap p-2">
                                            {r.name}
                                            {r.warning && (
                                                <Tooltip title={r.warning}>
                                                    <ExclamationCircleOutlined className="ml-2 text-red-600" />
                                                </Tooltip>
                                            )}
                                        </td>
                                        <td className="table-cell text-wrap p-2">
                                            {r.value}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <TextArea
                            placeholder="Enter expression"
                            value={formulaExpression}
                            onChange={onFormulaExpressionChanged}
                        />
                        {evaluationResult.error && (
                            <Text type="danger">{evaluationResult.error}</Text>
                        )}
                    </div>
                    <div>
                        <Input
                            readOnly={true}
                            addonBefore="Result"
                            value={evaluationResult.result?.toString()}
                            addonAfter={
                                <Button
                                    size="small"
                                    type="link"
                                    disabled={!evaluateExpressionEnabled}
                                    onClick={onEvaluateExpressionClick}
                                >
                                    Evaluate
                                </Button>
                            }
                        />
                    </div>
                </div>
            </div>
        );
    },
);

export default FormulaParser;
