import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import FormulaSuggestionInput from "../FormulaSuggestionInput";

type FormulaPresenterProps = {
    builder?: IFormulaBuilder;
    sourceColumns?: string[];
    onSelect: (selected: IFormulaFunction, parentId?: string) => void;
};

export default function FormulaPresenter({
    builder,
    sourceColumns = [],
    onSelect,
}: FormulaPresenterProps) {
    const [hovered, setHovered] = React.useState<boolean>(false);
    const [showInput, setShowInput] = React.useState<boolean>(false);

    const bgClassName = React.useMemo(() => {
        let _bg = "bg-gray-100 hover:bg-gray-300";
        if (builder && builder.formulaFunction) {
            switch (builder.formulaFunction.type) {
                case "formula":
                    _bg =
                        "bg-transparent hover:bg-violet-300 px-1 whitespace-nowrap rounded-l-sm";
                    break;
                case "variable":
                    _bg = "bg-cyan-100 rounded-sm px-1 whitespace-nowrap";
                    break;
                case "string":
                    _bg = "bg-yellow-100 rounded-sm px-1 whitespace-nowrap";
                    break;
                default:
                    break;
            }
        }
        return _bg;
    }, [builder]);

    const handleSelect = React.useCallback(
        (selected: IFormulaFunction, parentId?: string) => {
            onSelect(selected, parentId);
            setShowInput(false);
        },
        [onSelect],
    );

    if (!builder || !builder.formulaFunction) {
        return (
            <FormulaSuggestionInput
                sourceColumns={sourceColumns}
                onSelect={handleSelect}
            />
        );
    }

    return (
        <>
            <p
                className={bgClassName}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {builder.formulaFunction.label}
            </p>
            {builder.formulaFunction.type === "formula" && (
                <span
                    className={[
                        hovered ? "bg-violet-300" : "bg-transparent",
                        "rounded-l-sm px-0",
                    ].join(" ")}
                >
                    (
                </span>
            )}
            {builder?.children &&
                builder.children.map((child) => (
                    <React.Fragment key={child.formulaFunction.id}>
                        <FormulaPresenter
                            builder={child}
                            sourceColumns={sourceColumns}
                            onSelect={handleSelect}
                        />
                        <span
                            className={[
                                hovered ? "bg-violet-300" : "bg-transparent",
                                "px-0",
                            ].join(" ")}
                        >
                            ,
                        </span>
                    </React.Fragment>
                ))}
            {builder.formulaFunction.type === "formula" && (
                <div style={{ marginRight: showInput ? "3px" : "0" }}>
                    <button
                        className="mx-2 size-4 rounded-sm bg-sky-400 text-xs text-white hover:bg-sky-500"
                        onClick={() => setShowInput((prev) => !prev)}
                    >
                        {showInput ? <CloseOutlined /> : <PlusOutlined />}
                    </button>
                </div>
            )}
            {builder.formulaFunction.type === "formula" && showInput && (
                <FormulaSuggestionInput
                    sourceColumns={sourceColumns}
                    onSelect={(s) =>
                        handleSelect(s, builder.formulaFunction.id)
                    }
                />
            )}
            {builder.formulaFunction.type === "formula" && (
                <span
                    className={[
                        hovered ? "bg-violet-300" : "bg-transparent",
                        "rounded-r-sm px-0",
                    ].join(" ")}
                >
                    )
                </span>
            )}
        </>
    );
}
