import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { InboxOutlined, FileTwoTone } from '@ant-design/icons';
import LABELS from 'constants/labels';
import emailAddresses from 'email-addresses';
import CypressTestIds from "../../../cypress/CypressTestIds";

class ManageUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDefault: {
                email: '',
                firstName: '',
                lastName: ''
            },
            readOnlyView: this.props.action === "view"
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    componentDidMount() {
        if (this.props.user) {
            this.formRef.current.setFieldsValue({ ...this.props.user });
        }
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        let user = { ...formData };
        if (this.props.action === "create") {
            user.active = true;
            this.props.onSave(user);
        }
        else {
            user.version = this.props.user.version;
            this.props.onSave(this.props.user.userId, user);
        }
    }

    resetPassword = () => {
        this.props.onResetPassword(this.props.user.userId);
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    render() {
        return (
            <>
                <Form
                    labelCol={{ sm: { span: 5 }, xs: { span: 24 } }}
                    layout="horizontal"
                    labelAlign="left"
                    ref={this.formRef}
                    name="basic"
                    onFinish={this.onFormSubmit}
                    initialValues={this.state.userDefault}
                    onValuesChange={this.props.onValuesChanged}>
                    <Form.Item
                        data-testid={CypressTestIds.USERS_EMAIL_INPUT}
                        className="wrap-label"
                        {...this.getFormErrors("email")}
                        label={LABELS.CORPORATE_EMAIL_ADDRESS}
                        name={["email"]}
                        rules={this.props.action === "create" ? [
                            {
                                required: true,
                                message: `${LABELS.CORPORATE_EMAIL_ADDRESS} is required`
                            },
                            {
                                type: 'email'
                            },
                            ({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const address = emailAddresses.parseOneAddress(value);
                                    if (address && address.domain) {
                                        if (this.props.tenantDomain !== address.domain.toLowerCase()) {
                                            return Promise.reject(new Error(`Email domain should be ${this.props.tenantDomain}`));
                                        }
                                    }
                                    return Promise.resolve();
                                }
                            })] : []}
                        tooltip={{
                            title: 'The email you provide must be of the same domain as of tenant.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input placeholder={LABELS.CORPORATE_EMAIL_ADDRESS_PLACE_HOLDER} readOnly={this.props.action !== "create"} />
                    </Form.Item>
                    <Form.Item
                        data-testid={CypressTestIds.USERS_FIRST_NAME_INPUT}
                        className="wrap-label"
                        {...this.getFormErrors("firstName")}
                        label={LABELS.FIRST_NAME}
                        name={["firstName"]}
                        rules={[{ required: true, message: `${LABELS.FIRST_NAME} is required` }]}
                        tooltip={{
                            title: 'Names should be proper and consistent with how you are known within your organization and are required for contractual arrangements.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input placeholder={LABELS.FIRST_NAME_PLACE_HOLDER} readOnly={this.isReadOnlyView()} />
                    </Form.Item>
                    <Form.Item
                        data-testid={CypressTestIds.USERS_LAST_NAME_INPUT}
                        className="wrap-label"
                        {...this.getFormErrors("lastName")}
                        label={LABELS.LAST_NAME}
                        name={["lastName"]}
                        rules={[{ required: true, message: `${LABELS.LAST_NAME} is required` }]}
                        tooltip={{
                            title: 'Names should be proper and consistent with how you are known within your organization and are required for contractual arrangements.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input placeholder={LABELS.LAST_NAME_PLACE_HOLDER} readOnly={this.isReadOnlyView()} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className="footer-right-column">
                            <Space>
                                <Button  data-testid={CypressTestIds.USERS_CANCEL_BUTTON} onClick={this.props.onCancel}>Cancel</Button>
                                {this.props.action === "edit" ? <Button onClick={this.resetPassword} disabled={this.isReadOnlyView() || !this.isSaveAllowed()}>Reset Password</Button> : <></>}
                                <Button data-testid={CypressTestIds.USERS_SAVE_BUTTON} type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()}>Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </>);
    }
}

export default ManageUser