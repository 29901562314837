import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Table, Card, Space, Typography, Modal } from 'antd';
import { PlusOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { DataObjectsPreviewList, FullHeightContainerLayout, DataSetBatchUploadHistoryModal } from 'components';
import { DataObjectRequestVerification } from 'containers';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import _ from 'lodash';
import moment from 'moment';
import { unflatten } from 'flat';
import flatten from 'flat';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Column } = Table;
const { Text } = Typography;

class ManageDataSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            readOnlyView: this.props.action === "view"
        };
        this.defaultDataSet = {
            schemaName: "",
            schemaDescription: "",
            dataSetName: "",
            dataSetDescription: ""
        }
        this.emptyObject = {};
        this.formRef = React.createRef();
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.populateDataSet();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    populateDataSet = () => {
        let dataSet = { ...this.defaultDataSet };
        if (this.props.schemaModel) {
            dataSet.schemaName = this.props.schemaModel.name;
            dataSet.schemaDescription = this.props.schemaModel.description;

        }
        if (this.props.dataSet) {
            dataSet.dataSetName = this.props.dataSet.dataSetName;
            dataSet.dataSetDescription = this.props.dataSet.dataSetDescription;
        }

        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue(dataSet);
        this.defaultDataSet = this.formRef.current.getFieldsValue();
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    onFormSubmit = (dataSet) => {
        if (!this.isSaveAllowed()) {
            return;
        }
        if (this.props.action === "edit") {
            let updatedDataSet = { ...dataSet };
            this.props.onUpdateDataSet(this.props.dataSet, updatedDataSet, this.state.afterSaveCallback);
        }
        else if (this.props.action === "create") {
            let newDataSet = { ...dataSet };
            this.props.onCreateDataSet(newDataSet, this.state.afterSaveCallback);
        }
        this.setState({
            afterSaveCallback: null
        });
    }

    onBulkUploadData = (source) => {
        let dataSet = this.formRef.current.getFieldsValue();
        if (this.props.action === "create") {
            this.setState({
                afterSaveCallback: () => this.props.onBulkUploadData(dataSet.dataSetName, source),
                showSaveConfirmationModal: true
            });
        }
        else if (this.props.action === "edit") {
            if (_.isEqual(dataSet, this.defaultDataSet) === false) {
                this.setState({
                    afterSaveCallback: () => this.props.onBulkUploadData(dataSet.dataSetName, source),
                    showSaveConfirmationModal: true
                });
            }
            else {
                this.props.onBulkUploadData(dataSet.dataSetName, source);
            }
        }
        else {
            this.props.onBulkUploadData(dataSet.dataSetName, source);
        }
    }

    onSaveConfirmOk = () => {
        this.setState({
            showSaveConfirmationModal: false
        }, () => {
            this.formRef.current && this.formRef.current.submit()
        });
    };

    onSaveConfirmCancel = () => {
        this.setState({
            showSaveConfirmationModal: false,
            afterSaveCallback: null
        });
    };

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onDataObjectPageSelect = (pagination) => {
        if (this.props.onGetNextDataObjects && this.props.dataObjectsResult.pageKey && (pagination.current * pagination.pageSize) >= this.props.dataObjects.length) {
            this.props.onGetNextDataObjects(this.props.dataSet.dataSetId, this.props.dataObjectsResult.pageKey);
        }
    }

    showBatchUploadJobsModal = () => {
        this.setState({
            batchUploadModalVisible: true
        });
        this.props.onGetBatchUploadJobs(this.props.dataSet.dataSetId);
    }

    closeBatchUploadJobsModal = (jobUndoInitiated) => {
        this.setState({
            batchUploadModalVisible: false
        });
        if (jobUndoInitiated) {
            this.props.refreshDataObjects();
        }
    }

    render() {
        return (
            <>
                {
                    this.state.batchUploadModalVisible ?
                        <DataSetBatchUploadHistoryModal
                            visible={this.state.batchUploadModalVisible}
                            loadingData={this.props.fetchingBatchUploadJobs}
                            isBusy={this.props.undoJobInProgress}
                            jobList={this.props.jobList}
                            onCancel={this.closeBatchUploadJobsModal}
                            onOk={this.closeBatchUploadJobsModal}
                            onUndoJob={this.props.undoJob}>
                        </DataSetBatchUploadHistoryModal>
                        :
                        <></>
                }
                <Modal
                    title="Save Dataset"
                    centered={true}
                    visible={this.state.showSaveConfirmationModal}
                    onOk={this.onSaveConfirmOk}
                    onCancel={this.onSaveConfirmCancel}>
                    <p>There are unsaved changes. Do you want to save dataset?</p>
                </Modal>
                <FullHeightContainerLayout
                    showHeader={true}
                    showFooter={true}
                    header={
                        <Form
                            ref={this.formRef}
                            name="basic"
                            layout='vertical'
                            onFinish={this.onFormSubmit}
                            initialValues={this.defaultDataSet}
                            onValuesChange={this.props.onValuesChanged}>
                            <Row>
                                <Col span={20}>
                                    <Row>
                                        <Col span={12} style={{ textAlign: 'left' }}>
                                            <Form.Item
                                                shouldUpdate={true}>
                                                {() => {
                                                    return this.formRef && this.formRef.current && <Space direction="vertical">
                                                        <Text>Schema Name</Text>
                                                        <Text strong>{this.formRef.current.getFieldValue(["schemaName"])}</Text>
                                                    </Space>
                                                }}
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} offset={1} style={{ textAlign: 'left' }}>
                                            <Form.Item
                                                shouldUpdate={true}>
                                                {() => {
                                                    return this.formRef && this.formRef.current && <Space direction="vertical">
                                                        <Text>Schema Description</Text>
                                                        <Text strong>{this.formRef.current.getFieldValue(["schemaDescription"])}</Text>
                                                    </Space>
                                                }}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12} style={{ textAlign: 'left' }}>
                                            <Form.Item
                                                {...this.getFormErrors("dataSetName")}
                                                label="Dataset Name"
                                                name={["dataSetName"]}
                                                validateFirst={true}
                                                rules={nameRules}>
                                                <Input readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.DATASET_MANAGE_DATASET_NAME_INPUT}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10} offset={1} style={{ textAlign: 'left' }}>
                                            <Form.Item
                                                {...this.getFormErrors("dataSetDescription")}
                                                label="Dataset Description"
                                                name={["dataSetDescription"]}
                                                rules={descriptionRules}>
                                                <Input readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.DATASET_MANAGE_DATASET_DESCRIPTION_INPUT}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <Button
                                            style={{ width: "100%" }}
                                            onClick={() => this.onBulkUploadData("systemconnection")}
                                            data-testid={CypressTestIds.DATASET_MANAGE_DATASET_CONNECT_TO_A_SYSTEM_BUTTON}>
                                            Connect to a System
                                        </Button>
                                        {
                                            this.props.dataObjects && this.props.dataObjects.length > 0
                                                ?
                                                <DataObjectRequestVerification
                                                    disabled={(
                                                        !this.props.bulkVerification ||
                                                        !this.props.schemaModel ||
                                                        !this.props.schemaModel.fields ||
                                                        this.props.schemaModel.fields.length === 0 ||
                                                        this.props.loadingDataObjects ||
                                                        !this.props.dataObjects ||
                                                        this.props.dataObjects.length === 0
                                                    )}
                                                    key={this.props.dataObjects[0].dataObjectId}
                                                    dataObject={this.props.dataObjects[0]}
                                                    schemaFields={(this.props.schemaModel && this.props.schemaModel.fields) || []}
                                                    bulkVerification={true}>
                                                </DataObjectRequestVerification>
                                                :
                                                <></>
                                        }

                                        <Button
                                            style={{ width: "100%" }}
                                            type="primary"
                                            onClick={() => this.onBulkUploadData("file")}
                                            data-testid={CypressTestIds.DATASET_MANAGE_DATASET_LOAD_DATA_FILE_INPUT}>
                                            Load data from a file
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>}
                    footer={
                        <Row className="table-footer-row">
                            <Col span={24} className="footer-right-column">
                                <Space>
                                    <Button onClick={this.props.onCancel}>
                                        Cancel
                                    </Button>
                                    {this.props.action === "edit" ?
                                        <Button
                                            disabled={this.isReadOnlyView() || !this.isSaveAllowed()}
                                            type="primary"
                                            onClick={this.props.onCreateNewDataObject}
                                            data-testid={CypressTestIds.DATASET_MANAGE_DATASET_CANCEL_BUTTON}>
                                            <PlusOutlined /> Add Data
                                        </Button>
                                        :
                                        <></>
                                    }
                                    <Button
                                        disabled={this.isReadOnlyView() || !this.isSaveAllowed()}
                                        onClick={() => this.formRef.current && this.formRef.current.submit()}
                                        htmlType="submit"
                                        type="primary"
                                        data-testid={CypressTestIds.DATASET_MANAGE_DATASET_SAVE_BUTTON}>Save
                                    </Button>
                                    <Button type="primary" disabled={true}
                                    data-testid={CypressTestIds.DATASET_MANAGE_DATASET_EXPORT_BUTTON}>Export</Button>
                                </Space>
                            </Col>
                        </Row>
                    }
                    content={
                        <FullHeightContainerLayout
                            showHeader={true}
                            header={<Card size="small" title={<Icon
                                color='blue'
                                name='history'
                                className="action-cursor"
                                onClick={this.showBatchUploadJobsModal}
                                disabled={this.props.action === "create"} />}
                                headStyle={{ textAlign: "right" }}
                                bodyStyle={{ display: "none" }}
                            ></Card>}
                            content={<DataObjectsPreviewList
                                showHeader={false}
                                schemaFields={(this.props.schemaModel && this.props.schemaModel.fields) || []}
                                dataObjectList={this.props.loadingDataObjects ? [] : this.props.dataObjects}
                                loading={this.props.loadingDataObjects || this.props.loadingNextPageDataObjects}
                                onPageSelect={this.onDataObjectPageSelect}
                                onDataObjectSelected={this.props.onDataObjectSelected}
                                onShowDataObjectHistory={(dataObjectId) => this.props.onShowDataObjectHistory(this.props.dataSet.dataSetName, dataObjectId)}
                                onDeleteDataObject={this.props.onDeleteDataObject}>
                            </DataObjectsPreviewList>}>
                        </FullHeightContainerLayout>
                    }>
                </FullHeightContainerLayout>
            </>
        );
    }
}

export default ManageDataSet