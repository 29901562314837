import React, { useEffect, useState } from "react";
import { Space, Table, Typography, Tooltip, Pagination, Row, Col } from "antd";
import { DislikeTwoTone, WarningTwoTone, LikeTwoTone, SafetyCertificateTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { FormDisplayField, SchemaFieldTitle, FullHeightContainerLayout, VerticleEllipsisMenu, DataObjectLink } from 'components';
import { DataObjectShareOption } from 'containers';
import moment from "moment";
import { maskValue } from "common/Utility";

const { Title, Text } = Typography;
const { Column } = Table;

const DataObjectsPreviewList = ({
    showHeader = true,
    recordCount,
    schemaFields,
    dataObjectList,
    loading,
    onPageSelect,
    onDataObjectSelected,
    onShowDataObjectHistory,
    onDeleteDataObject }) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedDataObjectList, setPaginatedDataObjectList] = useState(dataObjectList && dataObjectList.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setPaginatedDataObjectList(dataObjectList && dataObjectList.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [dataObjectList]);

    const onClickRow = (record, rowIndex) => {
        return {
            onClick: () => {
                if (selectedRowKeys.length === 0 || selectedRowKeys[0] !== record.dataObjectId) {
                    setSelectedRowKeys([record.dataObjectId]);
                    onDataObjectSelected && onDataObjectSelected(dataObjectList.find(dataObject => dataObject.dataObjectId === record.dataObjectId));
                }
            },
            onFocus: () => {
                if (selectedRowKeys.length === 0 || selectedRowKeys[0] !== record.dataObjectId) {
                    setSelectedRowKeys([record.dataObjectId]);
                    onDataObjectSelected && onDataObjectSelected(dataObjectList.find(dataObject => dataObject.dataObjectId === record.dataObjectId));
                }
            },
        };
    }

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedDataObjectList = dataObjectList && dataObjectList.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedDataObjectList(currentPaginatedDataObjectList);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
        if (currentPaginatedDataObjectList.length <= pageSize) {
            onPageSelect && onPageSelect({ current: page, pageSize });
        }
    }

    let scroll = {
        x: 150 * ((schemaFields && schemaFields.length) || 1),
        y: `28.25vh`
    }

    return (
        <FullHeightContainerLayout
            showHeader={showHeader}
            header={
                <Space direction="horizontal">
                    <Text type="secondary">Number of records: </Text>
                    <Text type="success">{recordCount || 0}</Text>
                </Space>
            }
            content={
                <Table
                    dataSource={paginatedDataObjectList}
                    size="small"
                    rowKey="dataObjectId"
                    scroll={scroll}
                    loading={loading ? { size: "large", tip: "Loading DataObjects..." } : false}
                    className={['container-height-100', "schema-list-table"].join(" ")}
                    pagination={false}
                    onRow={onClickRow}
                    rowSelection={{
                        selectedRowKeys,
                        type: 'radio'
                    }}
                    rowClassName={(dataObject, index) => {
                        if (dataObject && dataObject.errors && dataObject.errors.length > 0) {
                            return "error-row";
                        }
                        return "default-row";
                    }}>
                    {
                        [
                            <Column
                                width="8rem"
                                key="status"
                                shouldCellUpdate={(record, prevRecord) => {
                                    return record !== prevRecord;
                                }}
                                render={(value, dataObject) => <Space size={"small"}>
                                    {
                                        !dataObject._verificationId
                                            ?
                                            (
                                                <>
                                                    <Tooltip title={"Verification not initiated"}>
                                                        <SafetyCertificateTwoTone twoToneColor={"#aeb0ac"} />
                                                    </Tooltip>
                                                    <Tooltip title={"Consent not requested"}>
                                                        <LikeTwoTone twoToneColor={"#aeb0ac"} />
                                                    </Tooltip>
                                                </>
                                            )
                                            :
                                            (
                                                dataObject._verificatioExpiry && moment().utc().isAfter(moment(dataObject._verificatioExpiry).utc())
                                                    ?
                                                    <>
                                                        <Tooltip title={"Verification link expired"}>
                                                            <SafetyCertificateTwoTone twoToneColor={"red"} />
                                                        </Tooltip>
                                                        <Tooltip title={"Consent not requested"}>
                                                            <LikeTwoTone twoToneColor={"#aeb0ac"} />
                                                        </Tooltip>
                                                    </>
                                                    :
                                                    (
                                                        !dataObject._verified
                                                            ?
                                                            <>
                                                                <Tooltip title={"Verification link sent"}>
                                                                    <SafetyCertificateTwoTone />
                                                                </Tooltip>
                                                                <Tooltip title={"Consent not requested"}>
                                                                    <LikeTwoTone twoToneColor={"#aeb0ac"} />
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <>
                                                                <Tooltip title={"Verified"}>
                                                                    <SafetyCertificateTwoTone twoToneColor={"#78d11f"} />
                                                                </Tooltip>
                                                                {
                                                                    dataObject._consent === false
                                                                        ?
                                                                        <Tooltip title={"Consent not granted or revoked"}>
                                                                            <DislikeTwoTone twoToneColor={"red"} />
                                                                        </Tooltip>
                                                                        :
                                                                        (
                                                                            dataObject._consent === true
                                                                                ?
                                                                                <Tooltip title={"Consent granted"}>
                                                                                    <LikeTwoTone twoToneColor={"#78d11f"} />
                                                                                </Tooltip>
                                                                                :
                                                                                <Tooltip title={"Consent not requested"}>
                                                                                    <LikeTwoTone twoToneColor={"#aeb0ac"} />
                                                                                </Tooltip>
                                                                        )
                                                                }
                                                            </>
                                                    )
                                            )
                                    }

                                    {onShowDataObjectHistory
                                        ?
                                        <Icon
                                            color='blue'
                                            name='history'
                                            className="action-cursor"
                                            onClick={() => onShowDataObjectHistory(dataObject.dataObjectId)} />
                                        :
                                        <></>
                                    }

                                    {dataObject.errors && dataObject.errors.length > 0 ? <Tooltip placement="topLeft" title={`Record contains ${dataObject.errors.length} error(s)`}><WarningTwoTone twoToneColor="#FF0000" /></Tooltip> : <></>}
                                </Space>}
                            />,
                            <Column
                                width="5%"
                                title="No."
                                key="index"
                                shouldCellUpdate={(record, prevRecord) => {
                                    return record !== prevRecord;
                                }}
                                render={(value, item, index) => item.sNo || index + 1}
                            />,
                            <Column
                                width="14rem"
                                title={"Record Id"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                shouldCellUpdate={(record, prevRecord) => {
                                    return record !== prevRecord;
                                }}
                                render={(value, dataObject, index) => {
                                    return (<Tooltip placement="topLeft" title={dataObject.dataObjectId}>
                                        <DataObjectShareOption
                                            key={dataObject.dataObjectId}
                                            dataObjectId={dataObject.dataObjectId}
                                            dataSetId={dataObject.dataSetId}
                                            schemaId={dataObject.schemaId}
                                            businessAreaId={dataObject.businessAreaId}>
                                            <DataObjectLink dataObjectId={dataObject.dataObjectId}></DataObjectLink>
                                        </DataObjectShareOption>
                                    </Tooltip>)
                                }}
                            />,
                            ...(schemaFields && schemaFields.map(field => {
                                return <Column
                                    key={field.fieldId}
                                    dataIndex={field.fieldId}
                                    shouldCellUpdate={(record, prevRecord) => {
                                        return record !== prevRecord;
                                    }}
                                    title={<SchemaFieldTitle name={field.name} tags={field.classifiers}></SchemaFieldTitle>}
                                    render={(value, dataObject, index) => {
                                        // let fieldError = null;
                                        // if (dataObject.errors && dataObject.errors.length > 0) {
                                        //     for (let error of dataObject.errors) {
                                        //         if (error.name === field.fieldId) {
                                        //             fieldError = error.errors;
                                        //             break;
                                        //         }
                                        //     }
                                        // }
                                        let displayValue = field.isPII ? maskValue(value) : value;
                                        let fieldError = dataObject.errors?.find(error => error.name === field.fieldId)?.errors;
                                        return field.isPII ? (
                                            <Tooltip placement="topLeft" title="This value is masked to protect sensitive information.">
                                                <div>
                                                    <FormDisplayField fieldError={fieldError} value={displayValue} {...field} />
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <FormDisplayField fieldError={fieldError} value={displayValue} {...field} />
                                        );
                                    }}
                                />
                            }) || []),
                            <Column
                                width={"4rem"}
                                key={"actions"}
                                shouldCellUpdate={(record, prevRecord) => {
                                    return record !== prevRecord;
                                }}
                                render={(value, dataObject) => <Space>
                                    <Icon
                                        color='blue'
                                        name='trash alternate'
                                        className="action-cursor"
                                        onClick={() => onDeleteDataObject(dataObject.dataObjectId)} />
                                </Space>}
                            />

                        ]
                    }
                </Table>
            }
            showFooter={true}
            footer={
                <Row justify="end" style={{ padding: "0.5rem 0rem" }}>
                    <Col span={24} style={{ textAlign: "end" }}>
                        <Pagination
                            size="small"
                            hideOnSinglePage={true}
                            total={(dataObjectList && dataObjectList.length) || 0}
                            defaultPageSize={pagination.defaultPageSize}
                            pageSize={pagination.pageSize}
                            responsive={true}
                            showSizeChanger={false}
                            onChange={onPageClick} />
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    );
}

export default DataObjectsPreviewList;


