import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import FormulaParser from "../FormulaParser";

const { Option } = Select;

type ExpressionInputType = {
    isVisible: boolean;
    targetField: IDataMappingField | null;
    variables?: IFormulaVariable[];
    expression?: string;
};

type DataMapperProps = {
    destinationFields: IDataMappingField[];
    sourceFields: string[];
    sourceData: IFormulaParserDataInput[];
    onMappingChanged: (mappedField: IDataMappingField) => void;
};

const DataMapper = ({
    destinationFields,
    sourceFields,
    sourceData,
    onMappingChanged,
}: DataMapperProps) => {
    const [expressionInput, setExpressionInput] = useState<ExpressionInputType>(
        { isVisible: false, targetField: null },
    );
    const formulaParserRef = useRef<IEvaluateExpressionHandle>(null);

    const onMappingTypeChange = React.useCallback(
        (field: IDataMappingField, value: IDataMappingType) => {
            if (field.mappingType === value) {
                // Nothing has changed
                return;
            }

            const changedField: IDataMappingField = {
                ...field,
                mappingType: value,
                value: "",
                sourceColumns: [],
                variables: [],
                builder: undefined,
            };

            onMappingChanged(changedField);
        },
        [onMappingChanged],
    );

    const onDataColumnChange = React.useCallback(
        (field: IDataMappingField, value: string) => {
            if (field.value === value) {
                // Nothing has changed
                return;
            }

            const changedField: IDataMappingField = {
                ...field,
                value: value ?? "",
                sourceColumns: value ? [value] : [],
            };

            onMappingChanged(changedField);
        },
        [onMappingChanged],
    );

    const onExpressionModelOpenClick = React.useCallback(
        (field: IDataMappingField) => {
            const newExpressionInput: ExpressionInputType = {
                targetField: field,
                isVisible: true,
                variables: field.variables ?? [],
                expression: field.value ?? "",
            };
            setExpressionInput(newExpressionInput);
        },
        [],
    );

    const onExpressionModelOkClick = React.useCallback(() => {
        const evaluationResult = formulaParserRef.current?.evaluateExpression();
        if (
            evaluationResult &&
            !evaluationResult.error &&
            expressionInput.targetField
        ) {
            const changedField: IDataMappingField = {
                ...expressionInput.targetField,
                value: evaluationResult.formulaExpression ?? "",
                variables: evaluationResult.variables ?? [],
            };

            onMappingChanged(changedField);
            setExpressionInput({
                isVisible: false,
                targetField: null,
                variables: [],
                expression: "",
            });
        }
    }, [expressionInput.targetField, onMappingChanged]);

    const onExpressionModelCancelClick = () => {
        const newExpressionInput: ExpressionInputType = {
            targetField: null,
            isVisible: false,
            variables: [],
            expression: "",
        };
        setExpressionInput(newExpressionInput);
    };

    const sourceFieldOptions = React.useMemo(() => {
        const options: { label: string; value: string }[] = [];
        const sortedFields = [...sourceFields].sort();
        sortedFields
            .filter((a, i) => {
                // Remove duplicates
                if (i === 0) {
                    return true;
                }
                return a !== sortedFields[i - 1];
            })
            .map((f) =>
                options.push({
                    value: f,
                    label: f,
                }),
            );

        return options;
    }, [sourceFields]);

    return (
        <div className="size-full flex-1">
            <Modal
                title="Formula Expression"
                width="80vw"
                centered
                destroyOnClose
                open={expressionInput.isVisible}
                onOk={() => onExpressionModelOkClick()}
                onCancel={() => onExpressionModelCancelClick()}
                styles={{
                    body: { height: "75vh", width: "100%" },
                }}
            >
                <FormulaParser
                    ref={formulaParserRef}
                    inputColumns={sourceFields}
                    inputData={sourceData}
                    variables={expressionInput.variables}
                    expression={expressionInput.expression ?? ""}
                ></FormulaParser>
            </Modal>

            <table className="table w-full text-left text-sm">
                <thead className="table-header-group">
                    <tr className="table-row border-b border-gray-100 bg-gray-50">
                        <th className="table-cell border-r border-gray-200/50 p-2">
                            Name
                        </th>
                        <th className="table-cell w-40 border-r border-gray-200/50 p-2">
                            Data Type
                        </th>
                        <th className="table-cell w-40 border-r border-gray-200/50 p-2">
                            Mapping Type
                        </th>
                        <th className="table-cell p-2">Mapped Value</th>
                    </tr>
                </thead>
                <tbody>
                    {destinationFields.map((field, index) => (
                        <tr
                            key={`${field.name}-${index}`}
                            className="table-row border-b border-gray-100"
                        >
                            <td className="table-cell p-2">
                                <span className="flex flex-row justify-between">
                                    <Tooltip
                                        placement="topLeft"
                                        title={field.name}
                                    >
                                        {field.name}
                                    </Tooltip>
                                    {!field.value && (
                                        <Tooltip
                                            placement="top"
                                            title={`${field.name} is not mapped`}
                                            className="text-red-500"
                                        >
                                            <ExclamationCircleOutlined />
                                        </Tooltip>
                                    )}
                                </span>
                            </td>
                            <td className="table-cell p-2">
                                <Tooltip
                                    placement="topLeft"
                                    title={field.dataType}
                                >
                                    {field.dataType}
                                </Tooltip>
                            </td>
                            <td className="table-cell p-2">
                                <Select<IDataMappingType>
                                    style={{ width: "100%" }}
                                    defaultValue={
                                        field.mappingType ?? "DATA_COLUMN"
                                    }
                                    onChange={(value) =>
                                        onMappingTypeChange(field, value)
                                    }
                                    value={field.mappingType}
                                >
                                    <Option value="DATA_COLUMN">
                                        Data column
                                    </Option>
                                    <Option value="FORMULA">Formula</Option>
                                </Select>
                            </td>
                            <td className="table-cell p-2">
                                {field.mappingType === "DATA_COLUMN" ? (
                                    <Select
                                        style={{ width: "100%" }}
                                        showSearch
                                        allowClear={true}
                                        placeholder="Search to Select"
                                        options={sourceFieldOptions}
                                        optionFilterProp="label"
                                        onChange={(value) =>
                                            onDataColumnChange(field, value)
                                        }
                                        defaultValue={field.value}
                                    />
                                ) : (
                                    <Input
                                        readOnly={true}
                                        addonBefore="="
                                        addonAfter={
                                            <Button
                                                size="small"
                                                type="link"
                                                onClick={() =>
                                                    onExpressionModelOpenClick(
                                                        field,
                                                    )
                                                }
                                            >
                                                fx
                                            </Button>
                                        }
                                        placeholder="SUM(amount,100)"
                                        value={field.value}
                                        onClick={() =>
                                            onExpressionModelOpenClick(field)
                                        }
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataMapper;
