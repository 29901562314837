import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Typography, Row, Col, Button, Modal, Alert, Checkbox } from 'antd';
import LABELS from 'constants/labels';
import actions from 'store/actions';
import { ModalLoadingOverlay, ConfirmationLinkSuccessModal } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import _ from 'lodash';
import flatten from 'flat';
import CypressTestIds from '../cypress/CypressTestIds';

const { Link } = Typography;

class UserSignup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                termsAndConditions: false
            },
            busy: false,
            busyMessage: ""
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onSendEmailVerificationLinkResultChanged(prevProps);
        if (this.props.signupUserResult && this.props.signupUserResult !== prevProps.signupUserResult) {
            if (this.props.signupUserResult.success) {
                this.setState({
                    busy: false,
                    showSignupSuccessModal: true
                });
            } else {
                if (this.props.signupUserResult.fields) {
                    let formErrors = this.props.signupUserResult.fields;
                    if (formErrors["email"]) {
                        formErrors["email"] = <>
                            <>
                                {formErrors["email"]} And If you haven't verified email yet then &nbsp;
                            </>
                            <Link underline onClick={this.onResendVerificationLink}>
                                send email verification link again.
                            </Link>
                        </>
                    }
                    this.setState({
                        formErrors
                    });
                }
                else {
                    showError("User signup failed.");
                }

                this.setState({
                    busy: false
                });
            }
        }
    }

    onSendEmailVerificationLinkResultChanged = (prevProps) => {
        if (this.props.sendEmailVerificationLinkResult && this.props.sendEmailVerificationLinkResult !== prevProps.sendEmailVerificationLinkResult) {
            if (this.props.sendEmailVerificationLinkResult.success) {
                this.setState({
                    showEmailSentSuccess: true,
                    formErrors: {}
                });
            }
            else {
                showError("Could not able to send verification link at this moment. Please try after some time.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    getFormErrors = fieldName => {
        if (this.state.formErrors && this.state.formErrors[fieldName]) {
            return {
                help: this.state.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onResendVerificationLink = () => {
        if (this.state.email) {
            this.setState({
                busy: true,
                busyMessage: "Sending verification link...",
            });
            this.props.sendEmailVerificationLink(this.state.email);
        }
    }

    onConfirmationLinkSuccessOkClick = () => {
        this.setState({ showEmailSentSuccess: false });
        this.props.onGoBackToLogin();
    }

    onFormSubmit = (formData) => {
        this.setState({
            busy: true,
            busyMessage: "Signing-up user...",
            email: formData.email
        });
        this.props.signUpNewUser(formData);
    }

    onFormValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.formErrors) === false) {
            let formErrors = { ...this.state.formErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete formErrors[key];
            }
            this.setState({
                formErrors
            })
        }
    }

    goBackToLogin = () => {
        this.props.onGoBackToLogin();
        this.setState({
            showSignupSuccessModal: false
        });
    }

    render() {
        return (
            <>
                <ModalLoadingOverlay busy={this.state.busy} message={this.state.busyMessage}></ModalLoadingOverlay>
                <ConfirmationLinkSuccessModal open={this.state.showEmailSentSuccess} okClick={this.onConfirmationLinkSuccessOkClick}></ConfirmationLinkSuccessModal>
                {this.state.showSignupSuccessModal ?
                    <Modal
                        centered
                        closable={false}
                        footer={null}
                        forceRender={true}
                        keyboard={false}
                        maskClosable={false}
                        visible={this.state.showSignupSuccessModal}>
                        <>
                            <Row className="full-height-container center-align-items">
                                <Col span={24}>
                                    <Alert
                                        message="User created successfully"
                                        description="Please check your mail box for email verification."
                                        type="success"
                                        showIcon
                                    />
                                </Col>
                            </Row>
                            <Row className="middle-row full-height-container center-align-items">
                                <Col span={24}>
                                    <Button block type="primary" onClick={this.goBackToLogin} data-testid={CypressTestIds.USER_SIGNUP_GOBACKTOLOGIN_BUTTON}>Go back to login</Button>
                                </Col>
                            </Row>
                        </>
                    </Modal> :
                    <></>}
                <Form layout="vertical"
                    labelAlign="left"
                    ref={this.formRef}
                    name="basic"
                    initialValues={this.state.form}
                    onFinish={this.onFormSubmit}
                    onValuesChange={this.onFormValuesChanged}>
                    <Form.Item
                        className="wrap-label"
                        {...this.getFormErrors("email")}
                        label={LABELS.CORPORATE_EMAIL_ADDRESS}
                        name={["email"]}
                        rules={[{ required: true, message: `${LABELS.CORPORATE_EMAIL_ADDRESS} is required` }, { type: 'email' }]}
                        tooltip={{
                            title: 'The email you provide must be a business or corporate email address not a yahoo, gmail, Hotmail or other free email service. Other users from your domain will share the tenancy with you under the domain.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input placeholder={LABELS.CORPORATE_EMAIL_ADDRESS_PLACE_HOLDER} data-testid={CypressTestIds.USER_SIGNUP_CORPORATE_EMAIL_INPUT}/>
                    </Form.Item>
                    <Form.Item
                        className="wrap-label"
                        {...this.getFormErrors("password")}
                        label={LABELS.PASSWORD}
                        name={["password"]}
                        rules={[{ required: true, message: 'Password is required' }]}
                        tooltip={{
                            title: 'Password must be at least 8 characters in length and should contain mixed case, numerals, and special characters.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input.Password placeholder='*******' data-testid={CypressTestIds.USER_SIGNUP_PASSWORD_INPUT}/>
                    </Form.Item>
                    <Form.Item
                        className="wrap-label"
                        {...this.getFormErrors("firstName")}
                        label={LABELS.FIRST_NAME}
                        name={["firstName"]}
                        rules={[{ required: true, message: `${LABELS.FIRST_NAME} is required.` }]}
                        tooltip={{
                            title: 'Names should be proper and consistent with how you are known within your organization and are required for contractual arrangements.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input placeholder={LABELS.FIRST_NAME_PLACE_HOLDER} data-testid={CypressTestIds.USER_SIGNUP_FIRST_NAME_INPUT}/>
                    </Form.Item>
                    <Form.Item
                        className="wrap-label"
                        {...this.getFormErrors("lastName")}
                        label={LABELS.LAST_NAME}
                        name={["lastName"]}
                        rules={[{ required: true, message: `${LABELS.LAST_NAME} is required.` }]}
                        tooltip={{
                            title: 'Names should be proper and consistent with how you are known within your organization and are required for contractual arrangements.',
                            color: "orange"
                        }}
                        hasFeedback>
                        <Input placeholder={LABELS.LAST_NAME_PLACE_HOLDER} data-testid={CypressTestIds.USER_SIGNUP_LAST_NAME_INPUT}/>
                    </Form.Item>
                    <Form.Item
                        className="wrap-label"
                        {...this.getFormErrors("termsAndConditions")}
                        name={["termsAndConditions"]}
                        valuePropName="checked"
                        initialValue={false}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Please agree to the Terms and Conditions'));
                                },
                            })
                        ]}>
                        <Checkbox data-testid={CypressTestIds.USER_SIGNUP_AGREE_TERMS_CHECKBOX}><label className="checkbox-item-required" title={LABELS.AGREE_TO_TERMS_AND_CONDITIONS_OF_USE}>{LABELS.AGREE_TO_TERMS_AND_CONDITIONS_OF_USE}</label></Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button block type="primary" htmlType="submit" data-testid={CypressTestIds.USER_SIGNUP_SIGNUP_BUTTON}>Sign up</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        signupUserResult: state.userSignup.signupUserResult,
        sendEmailVerificationLinkResult: state.userLogin.sendEmailVerificationLinkResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUpNewUser: (user) => dispatch(actions.signUpNewUserRequest(user)),
        sendEmailVerificationLink: (email) => dispatch(actions.sendEmailVerificationLinkRequest(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSignup);