// Recursively loop through children to find ID and insert function
export default function insertBuilderFunction(
    fb: IFormulaBuilder,
    parentId: string,
    newFunction: IFormulaFunction,
) {
    if (fb.formulaFunction.id === parentId) {
        if (fb.children) {
            fb.children.push({
                formulaFunction: newFunction,
            });
        } else {
            fb.children = [
                {
                    formulaFunction: newFunction,
                },
            ];
        }
    } else if (fb.children) {
        fb.children.forEach((child) =>
            insertBuilderFunction(child, parentId, newFunction),
        );
    }
}
