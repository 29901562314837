import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tabs, Empty } from 'antd';
import actions from 'store/actions';
import {
    DataSetList,
    ManageDataSet,
    NotFoundError,
    LoadingOverlay,
    ProhibitedArea,
    EmptyDataRow,
    BasicFormBuilder,
    OkCancelConfirmationBox,
    SuccessResult,
    DataSetExport,
    DeduplicatedRecordsTable,
    CopyInputModal
} from "components";
import { DataSetAudit, DataObjectConsents, DataObjectVerifications } from 'containers';
import { showError, showSuccess, showWarning, showInfo } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Segment } from 'semantic-ui-react';
import LocationContainer from './LocationMain';
import _ from 'lodash';
import flatten from 'flat';
import { maskValue } from 'common/Utility';

const { TabPane } = Tabs;

class DataSets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingSchemaDataSets: false,
            fetchingDataSetPermission: false,
            fetchingDataSet: false,
            busy: false,
            busyMessage: "Please wait...",
            manageDataSetErrors: {},
            manageDataSetContainerSpan: 24,
            showExportJobCreatedResult: false,
            showExportJobToSystemConnectionCreatedResult: false,
            sidePanelActiveTab: "DATASET_RECORD"
        }
    }

    componentWillMount() {
        this.props.getDataObjectPermission();
        if (this.props.action) {
            this.showBreadCrumbNavigationItems();
            if (this.props.action === "history") {
                this.setState({
                    showHistory: false
                });
            }
            this.setState({
                fetchingDataSetPermission: true
            });
            this.props.getUserDataSetPermission();

            if (this.props.dataSetId) {
                this.setState({
                    fetchingDataSet: true,
                });
                this.props.getDataSetById(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataSetId);
            }
        }
        else {
            if (this.props.selectedSchemaModel) {
                this.setState({
                    fetchingSchemaDataSets: true,
                    fetchingDataSetPermission: true
                })
                this.props.getDataSetBySchemaId(this.props.selectedSchemaModel.businessAreaId, this.props.selectedSchemaModel.schemaId);
                this.props.getUserDataSetPermission();
                this.props.getUserExportJobPermission();
            }
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserDataSetPermissionResultChanged(prevProps);
        this.onGetSchemaDataSetResultChanged(prevProps);
        this.onCreateDataSetResultChanged(prevProps);
        this.onUpdateDataSetResultChanged(prevProps);
        this.onDeleteDataSetResultChanged(prevProps);
        this.onGetDataSetByIdResultChanged(prevProps);
        this.onGetDataObjectsByDataSetIdResultChanged(prevProps);
        this.onGetDataObjectsByDataSetIdNextPageResultChanged(prevProps);
        this.onGetJobsResultChanged(prevProps);
        this.onUndoJobResultChanged(prevProps);
        this.onIndexDataSetResultChanged(prevProps);
        this.onExportDataSetToFileResultChanged(prevProps);
        this.onExportDataSetToSystemConnectionResultChanged(prevProps);
        this.onCreateDataObjectResultChanged(prevProps);
        this.onUpdateDataObjectResultChanged(prevProps);
        this.onDeleteDataObjectResultChanged(prevProps);
        this.onGetDataObjectResultChanged(prevProps);
        this.onGetDeDuplicatedRecordsRequestResultChanged(prevProps);
        this.onGetDataObjectPermissionResultChanged(prevProps);
    }

    onGetDataObjectPermissionResultChanged = (prevProps) => {
        if (this.props.getDataObjectPermissionResult && this.props.getDataObjectPermissionResult !== prevProps.getDataObjectPermissionResult) {
            if (!this.props.getDataObjectPermissionResult.success || !this.props.dataObjectPermission || !this.props.dataObjectPermission.canEdit) {
                this.setState({
                    permissionToEditDataObjectDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }
   

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase()),
                    text: "Schemas"
                },
                {
                    route: "",
                    text: this.props.schema
                },
                {
                    route: `${AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase())}${this.props.schemaModel ? `#${this.props.schemaModel.name}` : ""}`,
                    text: "Datasets"
                },
            ];
            if (this.props.action) {
                let dataSetName = "";
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New Dataset"
                        });
                        break;
                    case "view":
                    case "edit":
                        breadCrumbItems.push(...[{
                            route: "",
                            text: this.getDataSetName()
                        }]);
                        break;
                    case "history":
                        dataSetName = this.getDataSetName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: dataSetName
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                    case "export":
                        dataSetName = this.getDataSetName();
                        breadCrumbItems.push(...[{
                            route: "",
                            text: dataSetName
                        },
                        {
                            route: "",
                            text: "Export"
                        }]);
                        break;
                }
            }

            this.props.setBreadcrumbNavigationItems(breadCrumbItems);
        }
    }

    getDataSetName = () => {
        let dataSetName = "";
        if (this.props.dataSet && this.props.dataSet.dataSetId === this.props.dataSetId) {
            dataSetName = this.props.dataSet.dataSetName;
        }

        if (!dataSetName && this.props.schemaDataSetResult && this.props.schemaDataSetResult.Items && this.props.schemaDataSetResult.Items.length > 0) {
            let dataSet = this.props.schemaDataSetResult.Items.find(dataSet => dataSet.dataSetId === this.props.dataSetId);
            if (dataSet) {
                dataSetName = dataSet.dataSetName;
            }
        }
        if (!dataSetName && this.props.recentDataSetListResult && this.props.recentDataSetListResult.Items && this.props.recentDataSetListResult.Items.length > 0) {
            let dataSet = this.props.recentDataSetListResult.Items.find(dataSet => dataSet.dataSetId === this.props.dataSetId);
            if (dataSet) {
                dataSetName = dataSet.dataSetName;
            }
        }
        if (!dataSetName) {
            dataSetName = this.props.dataSetId;
        }
        return dataSetName;
    }

    onGetUserDataSetPermissionResultChanged = (prevProps) => {
        if (this.props.getUserDataSetPermissionResult && this.props.getUserDataSetPermissionResult !== prevProps.getUserDataSetPermissionResult) {
            if (!this.props.getUserDataSetPermissionResult.success || !this.props.dataSetPermission) {
                this.setState({
                    permissionDenied: true
                });
            }
            else {
                this.setDataSetPermissions(this.props.dataSetPermission, this.props.userBusinessArea);
            }
            this.setState({
                fetchingDataSetPermission: false
            });
        }
    }

    onGetSchemaDataSetResultChanged = (prevProps) => {
        if (this.props.getSchemaDataSetResult && this.props.getSchemaDataSetResult !== prevProps.getSchemaDataSetResult) {
            if (!this.props.getSchemaDataSetResult.success) {
                if (this.props.getSchemaDataSetResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema datasets.");
                }
            }
            this.setState({
                fetchingSchemaDataSets: false
            });
        }
    }

    onCreateDataSetResultChanged = (prevProps) => {
        if (this.props.createDataSetResult && this.props.createDataSetResult !== prevProps.createDataSetResult) {
            if (!this.props.createDataSetResult.success) {
                if (this.props.createDataSetResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to create dataset. Permission Denied.");
                }
                else {
                    this.setState({
                        busy: false,
                        manageDataSetErrors: this.props.createDataSetResult.fields
                    });
                    showError("Could not able to create dataset.");
                }
            }
            else {
                this.setState({
                    manageDataSetErrors: null
                });
                if (this.state.afterSaveCallback) {
                    this.state.afterSaveCallback();
                }
                else {
                    this.setState({
                        busy: true,
                        busyMessage: "Dataset created. Please wait..."
                    });
                    showSuccess("Dataset created successfully.", () => {
                        this.goToSchemas();
                    });
                }
            }
            this.setState({
                afterSaveCallback: null
            });
        }
    }

    onUpdateDataSetResultChanged = (prevProps) => {
        if (this.props.updateDataSetResult && this.props.updateDataSetResult !== prevProps.updateDataSetResult) {
            if (!this.props.updateDataSetResult.success) {
                if (this.props.updateDataSetResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update dataset. Permission Denied.");
                }
                else {
                    this.setState({
                        busy: false,
                        manageDataSetErrors: this.props.updateDataSetResult.fields
                    });
                    showError("Could not able to update dataset.");
                }
            }
            else {
                this.setState({
                    manageDataSetErrors: null
                });
                if (this.state.afterSaveCallback) {
                    this.state.afterSaveCallback();
                }
                else {
                    this.setState({
                        busy: true,
                        busyMessage: "Dataset updated. Please wait..."
                    });
                    showSuccess("Dataset updated successfully.", () => {
                        this.goToSchemas();
                    });
                }
            }
            this.setState({
                afterSaveCallback: null
            });
        }
    }

    onDeleteDataSetResultChanged = (prevProps) => {
        if (this.props.deleteDataSetResult && this.props.deleteDataSetResult !== prevProps.deleteDataSetResult) {
            if (!this.props.deleteDataSetResult.success) {
                if (this.props.deleteDataSetResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied!")
                }
                else {
                    showError("Could not able to delete dataset.");
                }
            }
            else {
                showSuccess("Dataset deleted successfully.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onGetDataSetByIdResultChanged = (prevProps) => {
        if (this.props.getDataSetByIdResult && this.props.getDataSetByIdResult !== prevProps.getDataSetByIdResult) {
            if (!this.props.getDataSetByIdResult.success) {
                if (this.props.getDataSetByIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset.");
                }
            }
            else {
                if (!this.props.schemaDataSetResult || !this.props.schemaDataSetResult.Items || this.props.schemaDataSetResult.Items.length === 0) {
                    this.showBreadCrumbNavigationItems();
                }
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
                else {
                    if (this.props.dataSet) {
                        this.getDataSetObjects();
                    }
                }
            }
            this.setState({
                fetchingDataSet: false
            });
        }
    }

    onGetDataObjectsByDataSetIdResultChanged = (prevProps) => {
        if (this.props.getDataObjectsByDataSetIdResult && this.props.getDataObjectsByDataSetIdResult !== prevProps.getDataObjectsByDataSetIdResult) {
            if (!this.props.getDataObjectsByDataSetIdResult.success) {
                if (this.props.getDataObjectsByDataSetIdResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        dataObjectsPermissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get data objects.");
                }
            }
            if (this.props.action.toLowerCase() !== "export") {
                this.props.setMainColumnSpan(24);
            }
            this.setState({
                fetchingDataObjects: false,
                showDataObjectFormContainer: true,
                manageDataSetContainerSpan: 18
            });
        }
    }

    onGetDataObjectsByDataSetIdNextPageResultChanged = (prevProps) => {
        if (this.props.getDataObjectsByDataSetIdNextPageResult && this.props.getDataObjectsByDataSetIdNextPageResult !== prevProps.getDataObjectsByDataSetIdNextPageResult) {
            if (!this.props.getDataObjectsByDataSetIdNextPageResult.success) {
                if (this.props.getDataObjectsByDataSetIdNextPageResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        dataObjectsPermissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get data objects.");
                }
            }
            this.setState({
                fetchingNextPageDataObjects: false
            });
        }
    }

    onGetJobsResultChanged = (prevProps) => {
        if (this.props.getJobsResult && this.props.getJobsResult !== prevProps.getJobsResult) {
            if (!this.props.getJobsResult.success) {
                showError("Could not able to get batch upload jobs.");
            }
            this.setState({
                fetchingDataSetJobs: false
            });
        }
    }

    onUndoJobResultChanged = (prevProps) => {
        if (this.props.undoJobResult && this.props.undoJobResult !== prevProps.undoJobResult) {
            if (!this.props.undoJobResult.success) {
                showError("Could not able to undo job.");
            }
            this.setState({
                undoJobInProgress: false
            });
        }
    }

    onIndexDataSetResultChanged = (prevProps) => {
        if (this.props.indexDataSetResult && this.props.indexDataSetResult !== prevProps.indexDataSetResult) {
            if (!this.props.indexDataSetResult.success) {
                showError("Could not able to create dataset records index job.");
            }
            else {
                showSuccess("Index dataset records job created successfully.");
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onExportDataSetToFileResultChanged = (prevProps) => {
        if (this.props.exportDataSetToFileResult && this.props.exportDataSetToFileResult !== prevProps.exportDataSetToFileResult) {
            if (!this.props.exportDataSetToFileResult.success) {
                showError("Could not able to create export dataset job.");
            }
            else {
                this.setState({
                    showExportJobCreatedResult: true
                });
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onExportDataSetToSystemConnectionResultChanged = (prevProps) => {
        if (this.props.exportDataSetToSystemConnectionResult && this.props.exportDataSetToSystemConnectionResult !== prevProps.exportDataSetToSystemConnectionResult) {
            if (!this.props.exportDataSetToSystemConnectionResult.success) {
                showError("Could not able to create export dataset job.");
            }
            else {
                this.setState({
                    showExportJobToSystemConnectionCreatedResult: true
                });
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onCreateDataObjectResultChanged = (prevProps) => {
        if (this.props.createDataObjectResult && this.props.createDataObjectResult !== prevProps.createDataObjectResult) {
            if (!this.props.createDataObjectResult.success) {
                if (this.props.createDataObjectResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied!");
                }
                else {
                    showError("Could not able to create dataset record.");
                }
            }
            else {
                if (this.state.selectedDataObject) {
                    let selectedDataObject = this.props.dataObjectsByDataSetIdResult.Items[0];
                    this.setState({
                        selectedDataObject
                    });
                    if (selectedDataObject.errors && selectedDataObject.errors.length > 0) {
                        showWarning("Record created successfully with warnings.")
                    }
                    else {
                        showSuccess("Record created successfully.")
                    }
                }
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }
    

    onUpdateDataObjectResultChanged = (prevProps) => {
        if (this.props.updateDataObjectResult && this.props.updateDataObjectResult !== prevProps.updateDataObjectResult) {
            if (!this.props.updateDataObjectResult.success) {
                if (this.props.updateDataObjectResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied!");
                }
                else {
                    showError("Could not able to update dataset record.");
                }
            }
            else {
                if (this.state.selectedDataObject) {
                    let selectedDataObject = this.props.dataObjectsByDataSetIdResult.Items.find(dataObject => dataObject.dataObjectId === this.state.selectedDataObject.dataObjectId);
                    if (selectedDataObject) {
                        this.setState({
                            selectedDataObject
                        });
                        if (selectedDataObject.errors && selectedDataObject.errors.length > 0) {
                            showWarning("Record saved successfully with warnings.")
                        }
                        else {
                            showSuccess("Record saved successfully.")
                        }
                    }
                    else {
                        showSuccess("Record saved successfully.")
                    }
                }
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onDeleteDataObjectResultChanged = (prevProps) => {
        if (this.props.deleteDataObjectResult && this.props.deleteDataObjectResult !== prevProps.deleteDataObjectResult) {
            if (!this.props.deleteDataObjectResult.success) {
                showError("Could not able to delete dataset record.");
            }
            else {
                if (this.state.selectedDataObject) {
                    this.setState({
                        selectedDataObject: null
                    });
                    showSuccess("Record deleted successfully.")
                }
            }
            this.setState({
                busy: false,
                busyMessage: ""
            });
        }
    }

    onGetDataObjectResultChanged = (prevProps) => {
        if (this.props.getDataObjectResult && this.props.getDataObjectResult !== prevProps.getDataObjectResult) {
            if (this.props.getDataObjectResult.success) {
                if (this.state.selectedDataObject && this.props.dataObject &&
                    this.state.selectedDataObject.dataObjectId === this.props.dataObject.dataObjectId) {
                    this.setState({
                        selectedDataObject: this.props.dataObject
                    });
                }
            }
        }
    }

    onGetDeDuplicatedRecordsRequestResultChanged = (prevProps) => {
        if (this.props.getDeDuplicatedRecordsRequestResult && this.props.getDeDuplicatedRecordsRequestResult !== prevProps.getDeDuplicatedRecordsRequestResult) {
            if (!this.props.getDeDuplicatedRecordsRequestResult.success) {
                showError("Could not able to get deduplicated records.");
            }
            this.setState({
                fetchingDeDuplicatedRecords: false
            });
        }
    }

    getDataSetObjects = () => {
        this.setState({
            fetchingDataObjects: true
        });
        this.props.getDataObjectsByDataSetId(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataSet.dataSetId);
    }

    loadingComponentData = () => {
        return this.state.fetchingDataSet || this.state.fetchingDataSetPermission || this.state.fetchingSchemaDataSets;
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.loadingComponentData();
        return isBusy;
    }

    setDataSetPermissions = (permission, userBusinessArea) => {
        let dataSetPermission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        if (permission && userBusinessArea && userBusinessArea.user) {
            dataSetPermission = {
                canAdd: permission.canAdd && userBusinessArea.user.canAdd,
                canView: permission.canView && userBusinessArea.user.canView,
                canEdit: permission.canEdit && userBusinessArea.user.canEdit,
                canDelete: permission.canDelete && userBusinessArea.user.canDelete,
            };
        }
        this.setState({
            dataSetPermission
        });
    }

    showCreateDataSet = () => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.selectedSchemaModel.name)
            .replace(":dataSetAction", "create"));
    }

    showEditDataSet = (dataSetId) => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.selectedSchemaModel.name)
            .replace(":dataSetId", dataSetId)
            .replace(":dataSetAction", "edit"));
    }

    showViewDataSet = (dataSetId) => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.selectedSchemaModel.name)
            .replace(":dataSetId", dataSetId)
            .replace(":dataSetAction", "view"));
    }

    showDataSetHistory = (dataSetId) => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.selectedSchemaModel.name)
            .replace(":dataSetId", dataSetId)
            .replace(":dataSetAction", "history"));
    }

    showDataObjectBulkUpload = (dataSetName, source) => {
        this.props.history.push(`${AppPaths.TENANT_DATASET_DATAOBJECT_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.schema)
            .replace(":dataset", dataSetName)
            .replace(":dataObjectAction", "bulkupload")}#${source}`,
            {
                dataSetAction: this.props.action
            });
    }

    onIndexDataSet = (dataSet) => {
        this.setState({
            dataSetToIndex: dataSet,
            showIndexDataSetConfirmation: true,
            indexDataSetConfirmMessage: `Are you sure, you want to index '${dataSet.dataSetName}' Dataset records? Indexing would take time depending on the size of the dataset.`
        });
    }

    onIndexDataSetConfirmed = () => {
        this.props.indexDataSet(this.state.dataSetToIndex);
        this.setState({
            dataSetToIndex: null,
            showIndexDataSetConfirmation: false,
            busy: true,
            busyMessage: "Creating index dataset records job...",
        })
    }

    onIndexDataSetCancel = () => {
        this.setState({
            dataSetToIndex: null,
            showIndexDataSetConfirmation: false
        })
    }

    onExportDataSetToFile = (dataSet) => {
        this.setState({
            busy: true,
            busyMessage: "Creating export dataset job...",
        })
        this.props.exportDataSetToFile(dataSet);
    }

    onExportDataSetToSystemConnectionClick = (dataSet) => {
        this.props.history.push(AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.selectedSchemaModel.name)
            .replace(":dataSetId", dataSet.dataSetId)
            .replace(":dataSetAction", "export"));
    }

    onExportDataSetToSystemConnection = (connection) => {
        this.setState({
            busy: true,
            busyMessage: "Creating export dataset job...",
        })
        this.props.exportDataSetToSystemConnection(this.props.dataSet, connection);
    }

    onExportDataSetToSystemConnectionCancel = () => {
        this.props.history.push(`${AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())}${this.props.schemaModel ? `#${this.props.schemaModel.name}` : ""}`);
    }

    closeExportJobCreatedResult = () => {
        this.setState({
            showExportJobCreatedResult: false
        });
    }

    closeExportJobToSystemConnectionCreatedResult = () => {
        this.props.history.push(`${AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())}${this.props.schemaModel ? `#${this.props.schemaModel.name}` : ""}`);
    }

    showDataObjectHistory = (dataSetName, dataObjectId) => {
        this.props.history.push(AppPaths.TENANT_DATASET_DATAOBJECT_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schema", this.props.schema)
            .replace(":dataset", dataSetName)
            .replace(":dataObjectId", dataObjectId)
            .replace(":dataObjectAction", "history"));
    }

    goToSchemas = () => {
        this.props.history.push(`${AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())}${this.props.schemaModel ? `#${this.props.schemaModel.name}` : ""}`);
    }

    createDataSet = (dataSet, afterSaveCallback) => {
        this.setState({
            busy: true,
            busyMessage: "Creating dataset...",
            afterSaveCallback
        });
        this.props.createDataSet(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, dataSet);
    }

    updateDataSet = (dataSet, updatedDataSet, afterSaveCallback) => {
        this.setState({
            busy: true,
            busyMessage: "Updating dataset...",
            afterSaveCallback
        });
        this.props.updateDataSet(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, dataSet, updatedDataSet);
    }

    showDeleteDataSetConfirmation = (dataSet) => {
        this.setState({
            deletedDataSet: dataSet,
            showDataSetDeleteConfirmation: true,
            deleteDataSetConfirmMessage: `Are you sure, you want to delete '${dataSet.dataSetName}' Dataset?`
        })
    }

    onDeleteDataSetConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting dataset..."
        });
        this.props.deleteDataSet(this.props.selectedSchemaModel.businessAreaId, this.props.selectedSchemaModel.schemaId, this.state.deletedDataSet.dataSetId);
        this.onDeleteDataSetCancel();
    }

    onDeleteDataSetCancel = () => {
        this.setState({
            deletedDataSet: null,
            showDataSetDeleteConfirmation: false,
            deleteDataSetConfirmMessage: ""
        })
    }

    onDataSetValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageDataSetErrors) === false) {
            let manageDataSetErrors = { ...this.state.manageDataSetErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageDataSetErrors[key];
            }
            this.setState({
                manageDataSetErrors
            })
        }
    }

    getEmptyText = () => {
        if (!this.props.selectedSchemaModel) {
            return <EmptyDataRow description="Please select a schema."></EmptyDataRow>
        }
        if (this.props.selectedSchemaModel) {
            if (this.state.fetchingSchemaDataSets) {
                return <EmptyDataRow></EmptyDataRow>;
            }
            return <EmptyDataRow description={`There are no data sets for schema - ${this.props.selectedSchemaModel.name}`}></EmptyDataRow>
        }
        return "";
    }

    getPageContainerClasses = () => {
        let classes = ["page-container"];
        if (this.props.displaySize === "half") {
            classes.push("half-container");
        }
        return classes.join(" ");
    }

    getNotFoundError = () => {
        if (!this.props.dataSet) {
            if (this.props.getDataSetByIdResult.success) {
                return {
                    title: "Dataset not found.",
                    description: "The dataset you are looking for does not exist."
                }
            }
            return {
                title: "Dataset could not be fetched.",
                description: "An error occurred while fetching dataset. Please contact support."
            }
        }
        return null;
    }

    getNextDataObjects = (dataSetId, pageKey) => {
        this.setState({
            fetchingNextPageDataObjects: true
        });
        this.props.getDataObjectsByDataSetIdNextPage(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, dataSetId, pageKey);
    }

    getDataSetJobs = (dataSetId) => {
        this.props.getDataSetJobs(dataSetId);
        this.setState({
            fetchingDataSetJobs: true
        })
    }

    onUndoJob = (jobId, version) => {
        this.setState({
            undoJobInProgress: true
        });
        this.props.undoJob(jobId, version);
    }

    onDataObjectSelected = (dataObject) => {
        this.props.getDeDuplicatedRecords(dataObject.dataObjectId);
        setTimeout(() => {
            this.setState({
                loadingDataObjectForm: false,
                selectedDataObject: dataObject,
            })
        }, 500);
        this.setState({
            loadingDataObjectForm: true,
            fetchingDeDuplicatedRecords: true
        });
    }

    onCreateNewDataObject = () => {
        this.setState({
            selectedDataObject: {}
        });
    }

    createDataObject = (dataObject) => {
        this.setState({
            busy: true,
            busyMessage: "Creating dataset record..."
        });
        this.props.createDataObject(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataSet.dataSetId, dataObject);
    }

    updateDataObject = (dataObjectId, dataObject) => {
        this.setState({
            busy: true,
            busyMessage: "Updating dataset record..."
        });
        this.props.updateDataObject(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataSet.dataSetId, dataObjectId, dataObject);
    }

    showDeleteDataObjectConfirmation = (dataObjectId) => {
        this.setState({
            deletedDataObjectId: dataObjectId,
            showDataObjectDeleteConfirmation: true,
            deleteDataObjectConfirmMessage: `Are you sure, you want to delete dataset record?`
        })
    }

    onDeleteDataObjectConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting dataset record..."
        });
        this.props.deleteDataObject(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataSet.dataSetId, this.state.deletedDataObjectId);
        this.onDeleteDataObjectCancel();
    }

    onDeleteDataObjectCancel = () => {
        this.setState({
            deletedDataObjectId: null,
            showDataObjectDeleteConfirmation: false,
            deleteDataObjectConfirmMessage: ""
        })
    }

    closeDataObjectForm = () => {
        this.setState({
            selectedDataObject: null
        });
    }

    onValidationErrors = (dataObject, validationErrors) => {
        this.props.updateDataObjectValidationErrors(dataObject, validationErrors);
    }

    goToJobs = () => {
        this.props.history.push(AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.tenant.toLowerCase()).replace(":monitorItem", "jobs"));
    }

    onSidePanelActiveTabChanged = (activeKey) => {
        this.setState({
            sidePanelActiveTab: activeKey
        });
    }
    renderLocationContainer = () => {
        // console.log("chala")
        
        const findFieldbyName = (name) => {
            return this.props.schemaModel.fields.find(field => name.includes(field.name.toLowerCase()));
        }
    
        const latitudeField = findFieldbyName(['latitude', 'lat']);
        const longitudeField = findFieldbyName(['longitude', 'long', 'lng']);
        const directAddressField = findFieldbyName(['address', 'location', 'addr', 'full_address']);
        const geocodeField = findFieldbyName(['geocode', 'geocoordinates', 'coordinates', 'coords']);
    
        const addressFields = {
            building: findFieldbyName(['building', 'bldg', 'unit', 'suite']),
            street: findFieldbyName(['street', 'road', 'st', 'rd', 'address_line_1']),
            locality: findFieldbyName(['locality', 'area', 'neighborhood']),
            city: findFieldbyName(['city', 'town', 'municipality']),
            state: findFieldbyName(['state', 'province', 'region']),
            country: findFieldbyName(['country', 'nation']),
            zip: findFieldbyName(['zip', 'zipcode', 'postal_code', 'postcode'])
        };
    
        const hasSupplementaryFields = Object.values(addressFields).some(Boolean);
    
        let addressField = '';
    
        if (directAddressField && hasSupplementaryFields) {
            addressField = [
                this.state.selectedDataObject[directAddressField.fieldId],
                ...Object.values(addressFields)
                    .filter(Boolean)
                    .map(field => this.state.selectedDataObject[field.fieldId])
            ].filter(Boolean).join(', ');
        } else if (directAddressField) {
            addressField = this.state.selectedDataObject[directAddressField.fieldId];
        } else if (hasSupplementaryFields) {
            addressField = Object.values(addressFields)
                .filter(Boolean)
                .map(field => this.state.selectedDataObject[field.fieldId])
                .join(', ');
        }
    
        if (addressField) {
            return <LocationContainer selectedDataObject={addressField} />;
        }
    
        // Fallback to latitude and longitude if addressField is not present
        if (latitudeField && longitudeField) {
            return (
                <LocationContainer
                    markers={[{ position: { latitude: this.state.selectedDataObject[latitudeField.fieldId].toString(), longitude: this.state.selectedDataObject[longitudeField.fieldId] } }]}
                />
            );
        }
        if(geocodeField){
            // console.log("databoject",this.props.schemaModel.fields)
            let geocodeValue=this.state.selectedDataObject[geocodeField.fieldId];
            const [latitude,longitude] =geocodeValue.split(',').map(coord=>parseFloat(coord.trim()));
            return (
                <LocationContainer markers={[{ position: { latitude, longitude } }]} />
            )
        }
    
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No location field found. Cannot show location details." />
        );
    };

    maskSelectedDataObject() {
        const { selectedDataObject } = this.state;
        const { schemaModel } = this.props;
    
        if (!selectedDataObject || !schemaModel || !schemaModel.fields) return {};
    
        const maskedDataObject = { ...selectedDataObject };
    
        schemaModel.fields.forEach((field) => {
            if (field.isPII && field.fieldId && selectedDataObject.hasOwnProperty(field.fieldId)) {
                maskedDataObject[field.fieldId] = maskValue(selectedDataObject[field.fieldId]);
            }
        });
    
        return maskedDataObject;
    }
    
    
    // renderLocationContainer = () => {
    //     // Helper function to safely get nested properties
    //     const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);
    
    //     // Function to extract location data from a field
    //     const extractLocationData = (field) => {
    //         console.log("value",field.dataType)
    //         const value = this.state.selectedDataObject[field.fieldId];
    //         if (value === undefined || value === null) return null;
    
    //         let location = {
    //             fullAddress: null,
    //             street: null,
    //             city: null,
    //             state: null,
    //             zip: null,
    //             country: null,
    //             latitude: null,
    //             longitude: null,
    //             context: field.name // Store the field name as context
    //         };
    
    //         switch (field.dataType) {
    //             case 'latlng':
    //                 const [lat, lng] = value.split(',').map(coord => parseFloat(coord.trim()));
    //                 location.latitude = lat;
    //                 location.longitude = lng;
    //                 break;
    //             case 'geolatitude':
    //                 location.latitude = parseFloat(value);
    //                 break;
    //             case 'geolongitude':
    //                 location.longitude = parseFloat(value);
    //                 break;
    //             case 'string':
    //                 if (/full.?address|address/i.test(field.name)) {
    //                     location.fullAddress = value;
    //                 } else if (/street|road|address.?line.?1/i.test(field.name)) {
    //                     location.street = value;
    //                 } else if (/city|town|municipality/i.test(field.name)) {
    //                     location.city = value;
    //                 } else if (/state|province|region/i.test(field.name)) {
    //                     location.state = value;
    //                 } else if (/zip|postal.?code/i.test(field.name)) {
    //                     location.zip = value;
    //                 } else if (/country|nation/i.test(field.name)) {
    //                     location.country = value;
    //                 }
    //             case 'integer':
    //                 if (/zip|postal.?code/i.test(field.name)) {
    //                     location.zip = value;
    //                 }
    //             case 'float':
    //                 if (/latitude|lat/i.test(field.name)) {
    //                     location.latitude = value;
    //                 } else if (/longitude|long|lng/i.test(field.name)) {
    //                     location.longitude = value;
    //                 }
    //                 break;
    //         }
    //         console.log ("location",location)
    
    //         return location;
    //     };
    
    //     // Function to group related fields
    //     const groupRelatedFields = (fields) => {
    //         const groups = {};
    //         fields.forEach(field => {
    //             const baseName = field.name.replace(/(latitude|longitude|address|location|coordinates|street|city|state|zip|country|geocode)$/i, '').trim();
    //             if (!groups[baseName]) groups[baseName] = [];
    //             groups[baseName].push(field);
    //         });
    //         console.log ("groups",groups)
    //         return Object.values(groups);
    //     };
    
    //     // Identify location-related fields
    //     const locationFields = this.props.schemaModel.fields.filter(field => 
    //         /address|location|coordinates|latitude|longitude|street|city|state|zip|country|geocode|geocoordinates/i.test(field.name) ||
    //         ['latlng', 'geolatitude', 'geolongitude'].includes(field.dataType)
    //     );
    
    //     // Group related fields
    //     const fieldGroups = groupRelatedFields(locationFields);
    
    //     // Extract location data from each group
    //     const locations = fieldGroups.map(group => {
    //         let location = { context: group[0].name };
    //         group.forEach(field => {
    //             const data = extractLocationData(field);
    //             if (data) {
    //                 location = { ...location, ...data };
    //             }
    //         });
    //         return location;
    //     }).map(loc => {
    //         // Combine address components if full address is not provided
    //         if (!loc.fullAddress) {
    //             loc.fullAddress = [loc.street, loc.city, loc.state, loc.zip, loc.country]
    //                 .filter(Boolean)
    //                 .join(', ');
    //             console.log("Combined address", loc.fullAddress)
    //         }
    //         return loc;
    //     }).filter(loc => loc.fullAddress || (loc.latitude && loc.longitude));
    //     console.log("just before unique",locations)
    
    //     // Deduplicate locations
    //     const uniqueLocations = locations.reduce((acc, loc) => {
    //         const key = loc.latitude && loc.longitude ? `${loc.latitude},${loc.longitude}` : loc.fullAddress;
    //         if (!acc[key]) {
    //             acc[key] = loc;
    //         } else {
    //             // Merge contexts if duplicate coordinates or addresses
    //             acc[key].context = `${acc[key].context}, ${loc.context}`;
    //         }
    //         return acc;
    //     }, {});
    //     console.log("value hai!!!",Object.values(uniqueLocations))
    
    //     if (Object.keys(uniqueLocations).length > 0) {
    //         return (
    //             <LocationContainer
    //                 locations={Object.values(uniqueLocations)}
    //             />
    //         );
    //     }
    
    //     return (
    //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No location fields found. Cannot show location details." />
    //     );
    // };
    //WORKING!!!!!!!!!!! stuff here
    // renderLocationContainer = () => {
    //     // Helper function to safely get nested properties
    //     const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

    //     // Function to extract location data from a field
    //     const extractLocationData = (field) => {
    //         const value = this.state.selectedDataObject[field.fieldId];
    //         if (value === undefined || value === null) return null;
    
    //         return {
    //             type: field.name.toLowerCase().replace(/^(home_|work_|other_)/, '').replace(/\d+$/, ''),
    //             value: value
    //         };
    //     };
    
    //     // Function to determine the context of a field
    //     const getFieldContext = (fieldName) => {
    //         const lowerName = fieldName.toLowerCase();
    //         if (lowerName.startsWith('home_')) return 'Home';
    //         if (lowerName.startsWith('work_')) return 'Work';
    //         const match = lowerName.match(/^(.*?)(address|location|geocode|latitude|longitude|street|city|state|zip|zipcode|country)/);
    //         return match ? match[1].trim() || 'Other' : 'Other';
    //     };
    
    //     // Function to group fields by their context and numerical suffix
    //     const groupFields = (fields) => {
    //         const groups = {};
    //         fields.forEach(field => {
    //             const context = getFieldContext(field.name);
    //             const match = field.name.match(/(\d+)$/);
    //             const suffix = match ? match[1] : '';
    //             const key = `${context}_${suffix}`;
    //             if (!groups[key]) groups[key] = [];
    //             groups[key].push(field);
    //         });
    //         return Object.values(groups);
    //     };
    
    //     // Identify location-related fields
    //     const locationFields = this.props.schemaModel.fields.filter(field => 
    //         /address|location|geocode|latitude|longitude|street|city|state|zip|country|zipcode/i.test(field.name) ||
    //         ['latlng', 'geolatitude', 'geolongitude'].includes(field.dataType)
    //     );
    
    //     // Group related fields
    //     const fieldGroups = groupFields(locationFields);
    
    //     // Combine fields in each group into a single location object
    //     const locations = fieldGroups.map(group => {
    //         let location = {
    //             context: getFieldContext(group[0].name),
    //             address: '',
    //             latitude: null,
    //             longitude: null
    //         };
    
    //         const addressParts = [];
    
    //         group.forEach(field => {
    //             const data = extractLocationData(field);
    //             if (data) {
    //                 switch (data.type) {
    //                     case 'address':
    //                     case 'street':
    //                         addressParts.unshift(data.value); // Put street address at the beginning
    //                         break;
    //                     case 'city':
    //                     case 'state':
    //                     case 'zip':
    //                     case 'country':
    //                         addressParts.push(data.value);
    //                         break;
    //                     case 'geocode':
    //                         [location.latitude, location.longitude] = data.value.split(',').map(coord => parseFloat(coord.trim()));
    //                         break;
    //                     case 'latitude':
    //                         location.latitude = parseFloat(data.value);
    //                         break;
    //                     case 'longitude':
    //                         location.longitude = parseFloat(data.value);
    //                         break;
    //                 }
    //             }
    //         });
    
    //         location.address = addressParts.filter(Boolean).join(', ');
    //         return location;
    //     }).filter(loc => loc.address || (loc.latitude && loc.longitude));
    //     console.log("Location",locations)
    
    //     if (locations.length > 0) {
    //         return (
    //             <LocationContainer
    //                 locations={locations}
    //             />
    //         );
    //     }
    
    
    //     return (
    //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No location fields found. Cannot show location details." />
    //     );
    // };

    



    getDataSetComponent = () => {
        if (!this.props.action) {
            return <>
                <SuccessResult
                    show={this.state.showExportJobCreatedResult}
                    title="Export dataset to a file job created!"
                    subTitle="A job is created. File will be available in the job for download once job is completed."
                    okText="Go to jobs"
                    cancelText="Close"
                    onOkClick={this.goToJobs}
                    onCancelClick={this.closeExportJobCreatedResult}>
                </SuccessResult>
                <SuccessResult
                    show={this.state.showExportJobToSystemConnectionCreatedResult}
                    title="Export dataset to a system connection job created!"
                    subTitle="A job is created. Dataset records would be exported to selected destination once job is completed."
                    okText="Go to jobs"
                    cancelText="Close"
                    onOkClick={this.goToJobs}
                    onCancelClick={this.closeExportJobToSystemConnectionCreatedResult}>
                </SuccessResult>
                <OkCancelConfirmationBox
                    show={this.state.showDataSetDeleteConfirmation}
                    message={this.state.deleteDataSetConfirmMessage}
                    onCancel={this.onDeleteDataSetCancel}
                    onOk={this.onDeleteDataSetConfirmed}>
                </OkCancelConfirmationBox>
                <OkCancelConfirmationBox
                    show={this.state.showIndexDataSetConfirmation}
                    message={this.state.indexDataSetConfirmMessage}
                    onCancel={this.onIndexDataSetCancel}
                    onOk={this.onIndexDataSetConfirmed}>
                </OkCancelConfirmationBox>
                <DataSetList
                    permission={this.props.selectedSchemaModel ? this.state.dataSetPermission : {}}
                    allowCreateNew={this.props.selectedSchemaModel && !this.state.fetchingSchemaDataSets ? true : false}
                    dataSetList={this.state.fetchingSchemaDataSets || !this.props.selectedSchemaModel ? [] : this.props.schemaDataSetResult.Items}
                    parentSchema={this.props.selectedSchemaModel && this.props.selectedSchemaModel.name}
                    emptyText={this.getEmptyText()}
                    onCreateNewDataSet={this.showCreateDataSet}
                    onEditDataSet={this.showEditDataSet}
                    onDeleteDataSet={this.showDeleteDataSetConfirmation}
                    onViewDataSet={this.showViewDataSet}
                    onViewDataSetHistory={this.showDataSetHistory}
                    onIndexDataSet={this.onIndexDataSet}
                    onExportToFileClick={this.onExportDataSetToFile}
                    onExportToSystemConnectionClick={this.onExportDataSetToSystemConnectionClick}
                    exportPermission={this.props.exportPermission}>
                </DataSetList>
            </>
        }
        let notFoundError = this.getNotFoundError();

        switch (this.props.action.toLowerCase()) {
            case "create":
                return <ManageDataSet
                    permission={this.state.dataSetPermission}
                    schemaModel={this.props.schemaModel}
                    formErrors={this.state.manageDataSetErrors}
                    onValuesChanged={this.onDataSetValuesChanged}
                    action={this.props.action}
                    onBulkUploadData={this.showDataObjectBulkUpload}
                    onCreateDataSet={this.createDataSet}
                    onCancel={this.goToSchemas}>
                </ManageDataSet>
            case "view":
            case "edit":
                return !notFoundError ?
                    <Row gutter={[8, 8]} wrap={false} className="layout-row">
                        <Col span={this.state.manageDataSetContainerSpan}>
                            <OkCancelConfirmationBox
                                show={this.state.showDataObjectDeleteConfirmation}
                                message={this.state.deleteDataObjectConfirmMessage}
                                onCancel={this.onDeleteDataObjectCancel}
                                onOk={this.onDeleteDataObjectConfirmed}>
                            </OkCancelConfirmationBox>
                            <ManageDataSet
                                permission={this.state.dataSetPermission}
                                schemaModel={this.props.schemaModel}
                                dataSet={this.props.dataSet}
                                formErrors={this.state.manageDataSetErrors}
                                onValuesChanged={this.onDataSetValuesChanged}
                                action={this.props.action}
                                onBulkUploadData={this.showDataObjectBulkUpload}
                                onUpdateDataSet={this.updateDataSet}
                                loadingDataObjects={this.state.fetchingDataObjects}
                                loadingNextPageDataObjects={this.state.fetchingNextPageDataObjects}
                                dataObjectsResult={this.props.dataObjectsByDataSetIdResult}
                                dataObjects={this.props.dataObjectsByDataSetIdResult.Items}
                                onGetNextDataObjects={this.getNextDataObjects}
                                onCancel={this.goToSchemas}
                                onGetBatchUploadJobs={this.getDataSetJobs}
                                fetchingBatchUploadJobs={this.state.fetchingDataSetJobs}
                                jobList={this.props.jobsResult.Items}
                                undoJob={this.onUndoJob}
                                undoJobInProgress={this.state.undoJobInProgress}
                                refreshDataObjects={this.getDataSetObjects}
                                onDataObjectSelected={this.onDataObjectSelected}
                                onCreateNewDataObject={this.onCreateNewDataObject}
                                onShowDataObjectHistory={this.showDataObjectHistory}
                                onDeleteDataObject={this.showDeleteDataObjectConfirmation}
                                bulkVerification={true}>
                            </ManageDataSet>
                        </Col>
                        {this.state.showDataObjectFormContainer ?
                            <Col span={6}>
                                <Segment className="field-properties-container">
                                    <Tabs type="editable-card" style={{ height: "100%" }} activeKey={this.state.sidePanelActiveTab} onChange={this.onSidePanelActiveTabChanged} hideAdd={true}>
                                        <TabPane tab="Dataset Record" key="DATASET_RECORD" closable={false}>
                                            {
                                                (this.state.loadingDataObjectForm
                                                    ?
                                                    <LoadingOverlay busy={true} message="Please wait..."></LoadingOverlay>
                                                    :
                                                    <BasicFormBuilder
                                                        key={this.state.selectedDataObject ? this.state.selectedDataObject.dataObjectId : "new_dataobject"}
                                                        showHeader={false}
                                                        // formDataObject={this.maskSelectedDataObject()}
                                                        formDataObject={this.state.selectedDataObject}
                                                        schemaFields={(this.props.schemaModel && this.props.schemaModel.fields) || []}
                                                        onCreate={this.createDataObject}
                                                        onUpdate={this.updateDataObject}
                                                        onCancel={this.closeDataObjectForm}
                                                        onValidationErrors={this.onValidationErrors}
                                                        requestVerification={true}
                                                        showRevealButton={true}
                                                        maskPIIFields={true}
                                                        readOnly={
                                                            !this.props.dataObjectPermission?.canEdit ||
                                                            this.props.action === "view"
                                                        }>
                                                    </BasicFormBuilder>
                                                )
                                            }
                                        </TabPane>
                                        <TabPane tab="Deduplication History" key="DEDUPLICATION_HISTORY" closable={false}>
                                            {
                                                (this.state.fetchingDeDuplicatedRecords
                                                    ?
                                                    <LoadingOverlay busy={true} message="Getting records..."></LoadingOverlay>
                                                    :
                                                    <DeduplicatedRecordsTable items={this.state.selectedDataObject && this.state.selectedDataObject.dataObjectId ? this.props.deDuplicatedRecordsResult.Items : []}></DeduplicatedRecordsTable>
                                                )
                                            }
                                        </TabPane>
                                        <TabPane tab="Verification" key="DATA_OBJECT_VERIFICATION" closable={false}>
                                            {
                                                this.state.sidePanelActiveTab === "DATA_OBJECT_VERIFICATION"
                                                    ?
                                                    this.state.selectedDataObject && <DataObjectVerifications
                                                        key={this.state.selectedDataObject.dataObjectId}
                                                        businessAreaId={this.state.selectedDataObject.businessAreaId}
                                                        schemaId={this.state.selectedDataObject.schemaId}
                                                        dataObjectId={this.state.selectedDataObject.dataObjectId}>
                                                    </DataObjectVerifications>
                                                    :
                                                    <></>
                                            }
                                        </TabPane>
                                        <TabPane tab="Consent" key="DATA_OBJECT_CONSENT" closable={false}>
                                            {
                                                this.state.sidePanelActiveTab === "DATA_OBJECT_CONSENT"
                                                    ?

                                                    this.state.selectedDataObject && <DataObjectConsents key={this.state.selectedDataObject.dataObjectId} dataObjectId={this.state.selectedDataObject.dataObjectId}></DataObjectConsents>
                                                    :
                                                    <></>
                                            }
                                        </TabPane>
                                        <TabPane tab="Location" key="LOCATION_ACTIVE" closable={false}>
                                            {
                                            this.state.sidePanelActiveTab === 'LOCATION_ACTIVE' 
                                            &&
                                            this.state.selectedDataObject 
                                            ? 
                                            (
                                             this.renderLocationContainer() ): 
                                                (
                                                <>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Choose a row to view location details."/>
                                                </>
                                            )}
                                        </TabPane>
                                    </Tabs>
                                </Segment>
                            </Col>
                            :
                            <></>}
                    </Row>
                    :
                    <NotFoundError
                        title={notFoundError.title}
                        description={notFoundError.description}>
                    </NotFoundError>
            case "history":
                return !notFoundError ?
                    (this.state.showHistory ? <DataSetAudit dataSet={this.props.dataSet}></DataSetAudit> : <></>)
                    :
                    <NotFoundError
                        title={notFoundError.title}
                        description={notFoundError.description}>
                    </NotFoundError>
            case "export":
                return !notFoundError ?
                    <>
                        <SuccessResult
                            show={this.state.showExportJobToSystemConnectionCreatedResult}
                            title="Export dataset to a system connection job created!"
                            subTitle="A job is created. Dataset records would be exported to selected destination once job is completed."
                            okText="Go to jobs"
                            cancelText="Close"
                            onOkClick={this.goToJobs}
                            onCancelClick={this.closeExportJobToSystemConnectionCreatedResult}>
                        </SuccessResult>
                        <DataSetExport
                            businessAreaId={this.props.schemaModel.businessAreaId}
                            onExport={this.onExportDataSetToSystemConnection}
                            onCancel={this.onExportDataSetToSystemConnectionCancel}></DataSetExport>
                    </>
                    :
                    <NotFoundError
                        title={notFoundError.title}
                        description={notFoundError.description}>
                    </NotFoundError>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        return <div className={this.getPageContainerClasses()}>
            <LoadingOverlay
                busy={this.isBusy()}
                spinner
                message={this.state.busyMessage || "Please wait..."}>
            </LoadingOverlay>
            {!this.loadingComponentData()
                ?
                <>
                    {this.getDataSetComponent()}
                </>
                :
                <></>}
        </div>;

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getUserDataSetPermissionResult: state.dataSets.getUserDataSetPermissionResult,
        dataSetPermission: state.dataSets.permission,
        getSchemaDataSetResult: state.dataSets.getSchemaDataSetResult,
        recentDataSetListResult: state.dataSets.recentDataSetListResult,
        schemaDataSetResult: state.dataSets.schemaDataSetResult,
        createDataSetResult: state.dataSets.createDataSetResult,
        schemaModel: state.schemaModels.schemaModel,
        schemaPermission: state.schemaModels.permission,
        getDataSetByIdResult: state.dataSets.getDataSetByIdResult,
        dataSet: state.dataSets.dataSet,
        updateDataSetResult: state.dataSets.updateDataSetResult,
        deleteDataSetResult: state.dataSets.deleteDataSetResult,
        getDataObjectsByDataSetIdResult: state.dataObjects.getDataObjectsByDataSetIdResult,
        dataObjectsByDataSetIdResult: state.dataObjects.dataObjectsByDataSetIdResult,
        getDataObjectsByDataSetIdNextPageResult: state.dataObjects.getDataObjectsByDataSetIdNextPageResult,
        getJobsResult: state.dataSets.getJobsResult,
        jobsResult: state.dataSets.jobsResult,
        undoJobResult: state.dataSets.undoJobResult,
        createDataObjectResult: state.dataObjects.createDataObjectResult,
        updateDataObjectResult: state.dataObjects.updateDataObjectResult,
        deleteDataObjectResult: state.dataObjects.deleteDataObjectResult,
        getDataObjectResult: state.dataObjects.getDataObjectResult,
        dataObject: state.dataObjects.dataObject,
        indexDataSetResult: state.dataSets.indexDataSetResult,
        exportDataSetToFileResult: state.dataSets.exportDataSetToFileResult,
        exportDataSetToSystemConnectionResult: state.dataSets.exportDataSetToSystemConnectionResult,
        exportPermission: state.dataSets.exportPermission,
        getDeDuplicatedRecordsRequestResult: state.dataObjectDuplicateSearch.getDeDuplicatedRecordsRequestResult,
        deDuplicatedRecordsResult: state.dataObjectDuplicateSearch.deDuplicatedRecordsResult,
        getDataObjectPermissionResult: state.dataObjects.getDataObjectPermissionResult,
        dataObjectPermission: state.dataObjects.dataObjectPermission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDataSetPermission: () => dispatch(actions.getUserDataSetPermissionRequest()),
        getDataSetBySchemaId: (businessAreaId, schemaId) => dispatch(actions.getDataSetBySchemaIdRequest(businessAreaId, schemaId)),
        createDataSet: (businessAreaId, schemaId, dataSet) => dispatch(actions.createDataSetRequest(businessAreaId, schemaId, dataSet)),
        getDataSetById: (businessAreaId, schemaId, dataSetId) => dispatch(actions.getDataSetByIdRequest(businessAreaId, schemaId, dataSetId)),
        updateDataSet: (businessAreaId, schemaId, dataSet, updatedDataSet) => dispatch(actions.updateDataSetRequest(businessAreaId, schemaId, dataSet, updatedDataSet)),
        deleteDataSet: (businessAreaId, schemaId, dataSetId) => dispatch(actions.deleteDataSetRequest(businessAreaId, schemaId, dataSetId)),
        getDataObjectsByDataSetId: (businessAreaId, schemaId, dataSetId) => dispatch(actions.getDataObjectsByDataSetIdRequest(businessAreaId, schemaId, dataSetId)),
        getDataObjectsByDataSetIdNextPage: (businessAreaId, schemaId, dataSetId, pageKey) => dispatch(actions.getDataObjectsByDataSetIdNextPageRequest(businessAreaId, schemaId, dataSetId, pageKey)),
        getDataSetJobs: (dataSetId) => dispatch(actions.getDataSetJobsRequest(dataSetId)),
        undoJob: (jobId, version) => dispatch(actions.undoJobRequest(jobId, version)),
        createDataObject: (businessAreaId, schemaId, dataSetId, dataObject) => dispatch(actions.createDataObjectRequest(businessAreaId, schemaId, dataSetId, dataObject)),
        updateDataObject: (businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) => dispatch(actions.updateDataObjectRequest(businessAreaId, schemaId, dataSetId, dataObjectId, dataObject)),
        updateDataObjectValidationErrors: (dataObject, validationErrors) => dispatch(actions.updateDataObjectValidationErrorsRequest(dataObject, validationErrors)),
        deleteDataObject: (businessAreaId, schemaId, dataSetId, dataObjectId) => dispatch(actions.deleteDataObjectRequest(businessAreaId, schemaId, dataSetId, dataObjectId)),
        indexDataSet: (dataSet) => dispatch(actions.indexDataSetRequest(dataSet)),
        exportDataSetToFile: (dataSet) => dispatch(actions.exportDataSetToFileRequest(dataSet)),
        exportDataSetToSystemConnection: (dataSet, connection) => dispatch(actions.exportDataSetToSystemConnectionRequest(dataSet, connection)),
        getUserExportJobPermission: () => dispatch(actions.getUserExportJobPermissionRequest()),
        getDeDuplicatedRecords: (survivedRecordId) => dispatch(actions.getDeDuplicatedRecordsRequest(survivedRecordId)),
        getDataObjectPermission: () => dispatch(actions.getDataObjectPermissionRequest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSets));