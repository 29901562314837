import { Input, Select } from "antd";
import React from "react";

const { Option } = Select;

type FormulaVariableValueProps = {
    value?: string;
    onChange?: (v: string | undefined) => void;
    valueType: string;
    dataColumns: string[];
};

export default function FormulaVariableValue({
    value,
    onChange,
    valueType,
    dataColumns,
}: FormulaVariableValueProps) {
    const onConstantValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value);
    };

    const onDataColumnChange = (changed: string) => {
        onChange?.(changed);
    };

    return valueType === "CONSTANT" ? (
        <Input
            placeholder="Enter value"
            value={value}
            onChange={(e) => onConstantValueChange(e)}
        ></Input>
    ) : (
        <Select
            placeholder="Select column"
            value={value}
            onChange={onDataColumnChange}
            popupMatchSelectWidth={false}
        >
            {dataColumns.map((column, index) => (
                <Option key={index} value={column}>
                    {column}
                </Option>
            ))}
        </Select>
    );
}
