import React, { Component } from 'react';
import LABELS from 'constants/labels';
import { Form, Input, Button } from 'antd';

class PasswordOnlyLogin extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    getFormErrors = (fieldName) => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            };
        }
        return {};
    };

    onFormSubmit = (formData) => {
        this.props.onUserLogin(this.props.prefilledEmail, formData.password);
    };

    render() {
        return (
            <Form layout="vertical"
                labelAlign="left"
                ref={this.formRef}
                name="passwordOnly"
                onFinish={this.onFormSubmit}>
                <Form.Item
                    {...this.getFormErrors("password")}
                    label={LABELS.PASSWORD}
                    name="password"
                    rules={[{ required: true, message: 'Password is required' }]}
                    hasFeedback>
                    <Input.Password
                        placeholder="*******"
                        disabled={this.props.inProgress}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={this.props.inProgress}>
                        {this.props.progressMessage || "Reveal Data"}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default PasswordOnlyLogin;
