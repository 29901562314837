import React from 'react';
import fieldDataTypes from 'common/data/fieldDataTypes';
import { Form, Input, Checkbox, Select, InputNumber, Tooltip, Empty } from "antd";
import CypressTestIds from '../../../cypress/CypressTestIds';

export default ({ selectedFieldRow, dataType, readOnly, getFormErrors }) => {
    return <Form.Item
        label="Min Length"
        name={["fields", selectedFieldRow.name, "min"]}
        initialValue={fieldDataTypes[dataType].min}
        rules={
            [
                ({ getFieldValue, ...param }) => ({
                    validator(_, value) {
                        if (value <= getFieldValue(["fields", selectedFieldRow.name, "max"])) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Min Length should be less than or equal to Max Length.'));
                    },
                }),
            ]
        }
        {...getFormErrors(`fields.${selectedFieldRow.name}.min`)}>
    <InputNumber
        data-testid={CypressTestIds.SCHEMA_MIN_LENGTH_PROPERTY_INPUT}
        type="number"
        readOnly={readOnly}
        min={fieldDataTypes[dataType].min}
        max={fieldDataTypes[dataType].max}></InputNumber>
</Form.Item>
}