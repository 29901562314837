import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';

const UploadProgressBar = ({ fileName, progress, onCancel }) => (
  <div className="bg-gray-100 rounded-md p-2 mt-2">
    <div className="flex justify-between items-center mb-1">
      <span className="text-sm truncate max-w-[70%]">{fileName}</span>
      <div className="flex items-center space-x-2">
        <span className="text-sm text-gray-600">{Math.round(progress)}%</span>
        <button
          onClick={onCancel}
          className="text-gray-500 hover:text-red-500 transition-colors p-1"
          aria-label="Cancel upload"
        >
          <CloseCircleFilled className="text-lg" />
        </button>
      </div>
    </div>
    <div className="w-full bg-gray-200 rounded-full h-2">
      <div
        className="bg-blue-500 h-2 rounded-full transition-all duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
  </div>
);

export default UploadProgressBar;