import React, { Component } from "react";
import { connect } from "react-redux";
import actions from 'store/actions';
import { Login } from "components";
import { Modal } from "antd";
import { showError, showSuccess } from 'common/ToastNotifications';
import PasswordOnlyLogin from "../PasswordOnlyLogin";

class DataObjectUnobfuscationLoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginProgress: false,
            loginProgressMessage: "",
            userLoginErrors: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.userLoginResult !== prevProps.userLoginResult) {
            if (this.props.userLoginResult.success) {
                this.props.onLoginSuccess();
                showSuccess("Login successful!");
            } else {
                if (this.props.userLoginResult.code === "UserNotConfirmedException") {
                    this.setState({
                        userLoginErrors: {
                            email: (
                                <>
                                    Email is not verified. Please check your email for the verification link.{" "}
                                </>
                            )
                        }
                    });
                } else if (this.props.userLoginResult.code === "NotAuthorizedException") {
                    this.setState({
                        userLoginErrors: {
                            email: "Email may be incorrect",
                            password: "Password may be incorrect"
                        }
                    });
                } else {
                    showError("Login failed. Please try again.");
                }
                showError("Login failed. Please try again.");
            }
            this.setState({
                loginProgress: false,
                loginProgressMessage: ""
            });
        }
    }

    userLogin = (email, password) => {
        this.setState({
            loginProgress: true,
            loginProgressMessage: "Please wait...",
            userLoginErrors: {},
        });
        this.props.userLogin(email, password);
    };

    render() {
        return (
            <Modal
                visible={this.props.visible}
                title="Key in your password to reveal the masked data, this event will be logged for auditing purposes"
                onCancel={this.props.onCancel}
                footer={null}
                centered
                destroyOnClose={true}
            >
                    <PasswordOnlyLogin
                        prefilledEmail={this.props.user ? this.props.user.email : ""}
                        inProgress={this.state.loginProgress}
                        progressMessage={this.state.loginProgressMessage}
                        onUserLogin={this.userLogin}
                        formErrors={this.state.userLoginErrors}
                    />
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userLoginResult: state.userLogin.userLoginResult,
    userLoginStatus: state.userLogin.userLoginStatus,
    user: state.userLogin.user
});

const mapDispatchToProps = (dispatch) => ({
    userLogin: (email, password) => dispatch(actions.userLoginRequest(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataObjectUnobfuscationLoginModal);
