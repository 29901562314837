const initialState: IDataMapperFlowState = {
    data: null,
    row: 1,
};

export default function DataMapperFlowReducer(
    state: IDataMapperFlowState = initialState,
    action: IDataMapperFlowAction | IDataMapperFlowClearAction,
): IDataMapperFlowState {
    switch (action.type) {
        case "IDATA_MAPPER_FLOW":
            return {
                ...state,
                ...action.payload,
            };
        case "IDATA_MAPPER_FLOW_CLEAR":
            return {
                data: null,
                row: 1,
            };
        default:
            return state;
    }
}
