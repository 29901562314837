import mergeClassNames from "common/mergeClassNames";
import React, { PropsWithChildren } from "react";

type DockableContainerProps = PropsWithChildren<{
    panel?: React.ReactNode;
    showPanel?: boolean;
    panelPosition?: "bottom" | "right" | "left";
    className?: string;
}>;

export default function DockableContainer({
    children,
    panel,
    panelPosition = "bottom",
    showPanel = false,
    className = "",
}: DockableContainerProps) {
    const panelPositionClassName = React.useMemo((): string => {
        if (panelPosition === "bottom") {
            return "flex-col";
        } else if (panelPosition === "right") {
            return "flex-row";
        } else {
            return "flex-row-reverse";
        }
    }, [panelPosition]);

    return (
        <div
            data-testid="dockable-container"
            className={mergeClassNames(
                "flex flex-1 flex-col gap-4 overflow-auto",
                panelPositionClassName,
                className,
            )}
        >
            <main className="flex h-full w-full flex-1 flex-col gap-4 overflow-auto">
                {children}
            </main>
            {panel && showPanel && (
                <div
                    data-testid="dockable-container-panel"
                    className={mergeClassNames(
                        "flex flex-col overflow-hidden",
                        panelPosition === "bottom"
                            ? "max-h-[30vh] min-h-40"
                            : "h-full min-w-40 max-w-[30vw]",
                    )}
                >
                    {panel}
                </div>
            )}
        </div>
    );
}
