import React from 'react';
import { Tooltip, Button } from 'antd';
import { CalendarTwoTone, UserOutlined, StarFilled } from '@ant-design/icons';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';

const colorSchemes = [
    { bg: 'bg-pink-100', text: 'text-pink-800', border: 'border-pink-500' },
    { bg: 'bg-teal-100', text: 'text-teal-800', border: 'border-teal-500' },
    { bg: 'bg-green-100', text: 'text-green-800', border: 'border-green-500' },
    { bg: 'bg-yellow-100', text: 'text-yellow-800', border: 'border-yellow-500' },
];

const TagPreview = ({ tagName, businessAreaName, tagDescription }) => {
    const { bg, text, border } = colorSchemes[0];

    return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out border border-gray-200 flex flex-col relative overflow-hidden group">
        <div className="absolute -right-8 top-4 bg-blue-500 text-white px-8 py-1 rotate-45 text-xs font-semibold shadow-sm">
        Preview
        </div>
        <div className="flex items-center justify-between bg-blue-100 p-3 rounded-t-lg">
            <div className="flex flex-row items-center">
                <h3 className="text-lg font-bold text-blue-600">
                    {tagName || 'Tag Name'}
                </h3>
            <span className={`text-xs font-medium px-2 py-1 mt-1 mx-2 rounded-full ${bg} ${text} border ${border}`}>
                {businessAreaName || 'business area'}
            </span>
        </div>
        <div className="flex space-x-1 mr-12">
            <Tooltip title="Preview Star">
                <StarFilled 
                    className="text-gray-400 hover:text-yellow-500 cursor-pointer text-xl"
            />
            </Tooltip>
        </div>
        </div>
        <div className="p-4 flex-grow overflow-hidden hover:bg-slate-100">
        <Tooltip title={tagDescription} placement="bottomLeft" overlayClassName="max-w-md">
            <div className="text-gray-800 mb-4 line-clamp-3 hover:text-clip text-lg font-light">
                {tagDescription || 'Tag description will appear here'}
            </div>
        </Tooltip>
        <div className="flex flex-col items-start rounded-md text-base text-gray-500">
            <div className="flex items-center gap-2 text-base font-medium">
                <CalendarTwoTone className="text-blue-500 text-2xl" />
                <span>{moment().toDate().toLocaleString()}</span>
            </div>
            <div className="flex items-center gap-2 text-base font-semibold">
                <UserOutlined className="text-green-500 text-2xl" />
                <span>user@example.com</span>
            </div>
        </div>
        </div>
        <div className="border-t border-gray-200 p-4 flex justify-between items-center">
            <div className="flex space-x-2">
                <Button icon={<Icon name='pencil' color='blue' />} disabled />
                <Button icon={<Icon name='eye' color='blue' />} disabled />
                <Button icon={<Icon name='history' color='blue' />} disabled />
                <Button icon={<Icon name='trash alternate' color='blue' />} disabled />
            </div>
        </div>
    </div>
    );
};

export default TagPreview;

