
import { put, call, takeEvery, select, takeLatest, delay } from 'redux-saga/effects';
import { Storage } from "aws-amplify";
import * as actions from './actions';
import { Auth } from "aws-amplify";
import { Endpoints } from 'services/api';
import moment from 'moment';
import { keyGenerator } from 'common/Utility';
import { getArrayBufferFromFileObject, sanitizeFileName } from 'common/fileHandler';
import errorHandler from 'common/errorHandler';
import _ from 'lodash';
import * as homeSaga from 'store/home/sagas';
import mime from 'mime-types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import parsePath from 'parse-filepath';

const getUser = (state) => state.userLogin.user;

function* getDataTags(api) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datatags`);
    return result.data;
}

function* createDataTag(api, dataTag) {
    let result = yield call([api, api.post], `${Endpoints.datasets}/api/v1/datatags`, dataTag);
    return result.data;
}

function* updateDataTag(api, tagId, dataTag) {
    let result = yield call([api, api.put], `${Endpoints.datasets}/api/v1/datatags/${tagId}`, dataTag);
    return result.data;
}

function* reorderDataTags(api,dataTags){
    let result=yield call([api,api.put],`${Endpoints.datasets}/api/v1/datatags/reorder`,dataTags);
    return result.data;
}

function* getDataTagById(api, tagId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/datatags/${tagId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* getDataTagsByBusinessAreaId(api, businessAreaId) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/businessareas/${businessAreaId}/datatags`);
    return result.data;
}

function* getUserBusinessAreaDataTags(api) {
    let result = yield call([api, api.get], `${Endpoints.datasets}/api/v1/users/businessareas/datatags`);
    return result.data;
}

function* deleteDataTag(api, tagId) {
    yield call([api, api.delete], `${Endpoints.datasets}/api/v1/datatags/${tagId}`);
}

export function* getUserDataTagPermission(api, userId) {
    let result = yield call([api, api.get], `${Endpoints.permissions}/api/v1/users/${userId}/permissions/resources/datatags`);
    return result.data;
}

export function* getDataTagsRequest(api) {
    try {
        let dataTagsResult = yield call(getDataTags, api);
        yield put(actions.getDataTagsSuccess(dataTagsResult));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data tag");
        yield put(actions.getDataTagsFailure(errorObject));
    }
}

export function* createDataTagRequest(api, { dataTag }) {
    try {
        let newDataTag = yield call(createDataTag, api, dataTag);
        yield put(actions.createDataTagSuccess(newDataTag));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "data tag");
        yield put(actions.createDataTagFailure(errorObject));
    }
}

export function* getDataTagByIdRequest(api, { tagId }) {
    try {
        let dataTag = yield call(getDataTagById, api, tagId);
        yield put(actions.getDataTagByIdSuccess(dataTag));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data tag");
        yield put(actions.getDataTagByIdFailure(errorObject));
    }
}

export function* getDataTagsByBusinessAreaIdRequest(api, { businessAreaId }) {
    try {
        let result = yield call(getDataTagsByBusinessAreaId, api, businessAreaId);
        yield put(actions.getDataTagsByBusinessAreaIdSuccess(result, businessAreaId));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", " business area data tags");
        yield put(actions.getDataTagsByBusinessAreaIdFailure(errorObject, businessAreaId));
    }
}

export function* getUserBusinessAreaDataTagsRequest(api) {
    try {
        let result = yield call(getUserBusinessAreaDataTags, api);
        yield put(actions.getUserBusinessAreaDataTagsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user business area data tags");
        yield put(actions.getUserBusinessAreaDataTagsFailure(errorObject));
    }
}

export function* updateDataTagRequest(api, { dataTag, updatedDataTag }) {
    try {
        if (_.isEqual(dataTag, { ...dataTag, ...updatedDataTag }) === false) {
            updatedDataTag.version = dataTag.version;
            yield call(updateDataTag, api, dataTag.tagId, updatedDataTag);
        }
        yield put(actions.updateDataTagSuccess());
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "data tag");
        yield put(actions.updateDataTagFailure(errorObject));
    }
}

export function* reorderDataTagsRequest(api,{params}){
    try{
        yield delay(4000);
        const state=yield select();
        const currentQueue=state.dataTags.dndQueue;
        if(currentQueue.length>0){
            const uniqueTags = currentQueue.filter((tag, index, self) => 
                index === self.findIndex((t) => (
                    t.tagId === tag.tagId && t.oldSortOrder === tag.oldSortOrder && t.sortOrder === tag.sortOrder
                ))
            );
            yield put(actions.markTagsProcessing(uniqueTags));
            yield call(reorderDataTags,api,uniqueTags);
            yield put(actions.reorderDataTagsSuccess());

            const newState = yield select();
            if (newState.dataTags.dndQueue.length > 0) {
                // console.log("Remaining items in dndQueue:", newState.dataTags.dndQueue);
                yield put(actions.reorderDataTagsRequest(newState.dataTags.dndQueue));
            }
        }

    }
    catch (error) {
        let errorObject = errorHandler(error, "Reorder", "data tags");
        yield put(actions.reorderDataTagsFailure(errorObject));
    }

}

export function* deleteDataTagRequest(api, { tagId }) {
    try {
        yield call(deleteDataTag, api, tagId);
        yield put(actions.deleteDataTagSuccess(tagId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "data tag");
        yield put(actions.deleteDataTagFailure(errorObject));
    }
}

export function* getUserDataTagPermissionRequest(api) {
    try {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        let user = yield select(getUser);
        let permissionResponse = yield call(getUserDataTagPermission, api, user.userId);
        if (permissionResponse && permissionResponse.Items && permissionResponse.Items.length > 0) {
            let permissionActions = permissionResponse.Items.map(item => item.actions);
            permissionActions = _.union(...permissionActions);
            if (permissionActions.length > 0) {
                if (permissionActions.includes("add")) {
                    permission.canAdd = true;
                }
                if (permissionActions.includes("view")) {
                    permission.canView = true;
                }
                if (permissionActions.includes("edit")) {
                    permission.canEdit = true;
                }
                if (permissionActions.includes("delete")) {
                    permission.canDelete = true;
                }
            }
        }

        yield put(actions.getUserDataTagPermissionSuccess(permission));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "user data tag permissions");
        yield put(actions.getUserDataTagPermissionFailure(errorObject));
    }
}

export function* watchGetDataTagsRequest(api, params) {
    yield call(getDataTagsRequest, api);
}

export function* watchCreateDataTagRequest(api, { params }) {
    yield call(createDataTagRequest, api, params);
}

export function* watchGetDataTagByIdRequest(api, { params }) {
    yield call(getDataTagByIdRequest, api, params);
}

export function* watchGetDataTagsByBusinessAreaIdRequest(api, { params }) {
    yield call(getDataTagsByBusinessAreaIdRequest, api, params);
}

export function* watchGetUserBusinessAreaDataTagsRequest(api, { params }) {
    yield call(getUserBusinessAreaDataTagsRequest, api);
}

export function* watchUpdateDataTagRequest(api, { params }) {
    yield call(updateDataTagRequest, api, params);
}

export function* watchDeleteDataTagRequest(api, { params }) {
    yield call(deleteDataTagRequest, api, params);
}

export function* watchGetUserDataTagPermissionRequest(api, { params }) {
    yield call(getUserDataTagPermissionRequest, api);
}
export function* watchReorderDataTags(api,{params}) {
    yield call(reorderDataTagsRequest, api,params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_TAGS_REQUEST, watchGetDataTagsRequest, api);
    yield takeLatest(actions.CREATE_DATA_TAG_REQUEST, watchCreateDataTagRequest, api);
    yield takeLatest(actions.GET_DATA_TAG_BY_ID_REQUEST, watchGetDataTagByIdRequest, api);
    yield takeLatest(actions.GET_DATA_TAGS_BY_BUSINESS_AREA_ID_REQUEST, watchGetDataTagsByBusinessAreaIdRequest, api);
    yield takeLatest(actions.GET_USER_BUSINESS_AREA_DATA_TAGS_REQUEST, watchGetUserBusinessAreaDataTagsRequest, api);
    yield takeLatest(actions.UPDATE_DATA_TAG_REQUEST, watchUpdateDataTagRequest, api);
    yield takeLatest(actions.REORDER_DATA_TAGS_REQUEST, watchReorderDataTags, api);
    yield takeEvery(actions.DELETE_DATA_TAG_REQUEST, watchDeleteDataTagRequest, api);
    yield takeEvery(actions.GET_USER_DATA_TAG_PERMISSION_REQUEST, watchGetUserDataTagPermissionRequest, api);
}