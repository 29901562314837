import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Row, Col, Button, Space, InputNumber, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { FullHeightContainerLayout } from "components";
import CypressTestIds from "../../../cypress/CypressTestIds";

const ManageKinesisStreamCDCDestination = ({ destinationItem, getFormErrors, readOnly }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({ ...destinationItem });
    }, [destinationItem]);
    return (
        <Form
            form={form}
            name="manageKinesisStreamCDCDestinationForm"
            style={{ height: "100%" }}>
            <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }} wrap={false}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                    <FullHeightContainerLayout
                        showHeader={false}
                        showFooter={false}
                        content={<Row>
                            <Col span={24}>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            {...getFormErrors("region")}
                                            name="region"
                                            label="Region"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Region is required.',
                                                },
                                            ]}>
                                            <Input readOnly={readOnly} data-testid={CypressTestIds.CDC_MANAGE_KINESIS_REGION_INPUT} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            {...getFormErrors("kinesisStreamArn")}
                                            name="kinesisStreamArn"
                                            label="Kinesis Stream Arn"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Kinesis Stream Arn is required.',
                                                },
                                            ]}>
                                            <Input readOnly={readOnly} data-testid={CypressTestIds.CDC_MANAGE_KINESIS_STREAM_ARN_INPUT} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            {...getFormErrors("roleArn")}
                                            name="roleArn"
                                            label="Role Arn"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Role Arn is required.',
                                                },
                                            ]}>
                                            <Input readOnly={readOnly} data-testid={CypressTestIds.CDC_MANAGE_KINESIS_ROLE_ARN_INPUT} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}>
                    </FullHeightContainerLayout>
                </Col>
            </Row>
        </Form>
    );
};

export default ManageKinesisStreamCDCDestination;