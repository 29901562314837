import React, { useState, useEffect } from 'react';
import { Form, DatePicker } from "antd";
import moment from 'moment';
import { SchemaFieldTitle } from 'components';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';

const getValidYearValue = (value) => {
    let intValue = parseInt(value);
    if (isNaN(intValue)) {
        return null;
    }

    let yearValue = value && moment().year(value);
    if (yearValue && yearValue.isValid() === false) {
        yearValue = null;
    }
    return yearValue;
}

const DateYearInput = ({ value, onChange, disabled }) => {
    const [dateYearValue, setDateYearValue] = useState(getValidYearValue(value));

    useEffect(() => {
        setDateYearValue(getValidYearValue(value));
    }, [value]);

    const onDateYearValueChange = (date, dateString) => {
        setDateYearValue(date);
        onChange(dateString && parseInt(dateString));
    }

    return <DatePicker picker="year" value={dateYearValue} onChange={onDateYearValueChange} disabled={disabled} />
}

function FormDateYearField({ fieldErrors, fieldId, name, description, max, min, isRequired, disabled, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
        }
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim()) {
                        let intValue = parseInt(value);
                        let yearValue = moment().year(value);
                        if (yearValue.isValid() === false || isNaN(intValue)) {
                            return Promise.reject(`Value should be a valid year value`);
                        }

                        if (min && yearValue.isBefore(moment().year(min))) {
                            return Promise.reject(`Value should be greater than or equal to ${min}`);
                        }

                        if (max && yearValue.isAfter(moment().year(max))) {
                            return Promise.reject(`Value should be less than or equal to ${max}`);
                        }
                    }

                    return Promise.resolve();
                },
            },
        ]}
        {...fieldErrors}>
        <DateYearInput disabled={disabled}/>
    </Form.Item>
}

export default FormDateYearField