import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Tabs } from 'antd';
import actions from 'store/actions';
import {
    NotFoundError,
    LoadingOverlay,
    ProhibitedArea,
    BasicFormBuilder
} from "components";
import { showError, showSuccess, showWarning, showInfo } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";

class DataObjectUUID extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingPermission: true,
            fetchingRecord: true,
            busy: false,
            busyMessage: "Please wait..."
        }
    }

    componentWillMount() {
        this.props.getDataObjectPermission();
        this.props.getDataObjectByUUID(this.props.dataObjectId);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetDataObjectPermissionResultChanged(prevProps);
        this.onGetDataObjectByUUIDResultChanged(prevProps);
        this.onUpdateDataObjectResultChanged(prevProps);
    }

    onGetDataObjectPermissionResultChanged = (prevProps) => {
        if (this.props.getDataObjectPermissionResult && this.props.getDataObjectPermissionResult !== prevProps.getDataObjectPermissionResult) {
            if (!this.props.getDataObjectPermissionResult.success || !this.props.dataObjectPermission || !this.props.dataObjectPermission.canView) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingPermission: false
            });
        }
    }

    onGetDataObjectByUUIDResultChanged = (prevProps) => {
        if (this.props.getDataObjectByUUIDResult && this.props.getDataObjectByUUIDResult !== prevProps.getDataObjectByUUIDResult) {
            if (!this.props.getDataObjectByUUIDResult.success) {
                if (this.props.getDataObjectByUUIDResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset record.");
                }
            }
            this.setState({
                fetchingRecord: false
            });
        }
    }

    onUpdateDataObjectResultChanged = (prevProps) => {
        if (this.props.updateDataObjectResult && this.props.updateDataObjectResult !== prevProps.updateDataObjectResult) {
            if (!this.props.updateDataObjectResult.success) {
                if (this.props.updateDataObjectResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update dataset record. Permission Denied.");
                }
                else {
                    this.setState({
                        busy: false
                    });
                    showError("Could not able to update dataset record.");
                }
            }
            else {
                this.setState({
                    busy: false,
                    fetchingRecord: true
                });
                this.props.getDataObjectByUUID(this.props.dataObjectId);
            }
        }
    }

    updateDataObject = (dataObjectId, dataObject) => {
        this.setState({
            busy: true,
            busyMessage: "Updating dataset record..."
        });
        this.props.updateDataObject(
            this.props.dataObjectByUUIDResult.dataObject.businessAreaId,
            this.props.dataObjectByUUIDResult.dataObject.schemaId,
            this.props.dataObjectByUUIDResult.dataObject.dataSetId,
            dataObjectId,
            dataObject);
    }

    goToHome = () => {
        this.props.history.push(AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.toLowerCase()));
    }

    isBusy = () => {
        let isBusy = this.state.fetchingPermission || this.state.fetchingRecord || this.state.busy;
        return isBusy;
    }

    getNotFoundError = () => {
        if (this.props.getDataObjectByUUIDResult.success && !this.props.dataObjectByUUIDResult) {
            return {
                title: "Dataset record not found.",
                description: "The dataset record you are looking for does not exist."
            }
        }
        return null;
    }

    render() {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        if (this.isBusy()) {
            return <LoadingOverlay
                busy={this.isBusy()}
                spinner
                message={this.state.busyMessage || "Please wait..."}>
            </LoadingOverlay>;
        }
        let notFoundError = this.getNotFoundError();
        return !notFoundError ? <BasicFormBuilder
            key={`${this.props.dataObjectId}_${this.props.dataObjectByUUIDResult.dataObject.version}`}
            showHeader={false}
            formDataObject={this.props.dataObjectByUUIDResult.dataObject}
            schemaFields={(this.props.dataObjectByUUIDResult.schemaModel && this.props.dataObjectByUUIDResult.schemaModel.fields) || []}
            onCreate={() => { }}
            onUpdate={this.updateDataObject}
            onCancel={this.goToHome}
            readOnly={!this.props.dataObjectPermission.canEdit}
            fromhyperlink={true}>
            showRevealButton={true}
            >
        </BasicFormBuilder>
            :
            <NotFoundError
                title={notFoundError.title}
                description={notFoundError.description}>
            </NotFoundError>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataObjectPermissionResult: state.dataObjects.getDataObjectPermissionResult,
        dataObjectPermission: state.dataObjects.dataObjectPermission,
        getDataObjectByUUIDResult: state.dataObjects.getDataObjectByUUIDResult,
        dataObjectByUUIDResult: state.dataObjects.dataObjectByUUIDResult,
        updateDataObjectResult: state.dataObjects.updateDataObjectResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataObjectPermission: () => dispatch(actions.getDataObjectPermissionRequest()),
        getDataObjectByUUID: (dataObjectId) => dispatch(actions.getDataObjectByUUIDRequest(dataObjectId)),
        updateDataObject: (businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) => dispatch(actions.updateDataObjectRequest(businessAreaId, schemaId, dataSetId, dataObjectId, dataObject)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectUUID));