import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, InputNumber, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import { FullHeightContainerLayout, CopyInput } from "components";
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import CypressTestIds from "../../../cypress/CypressTestIds";

class ManageAppClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appClientDefault: {
                clientName: '',
                accessTokenValidity: 1
            },
            readOnlyView: this.props.action === "view"
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    componentDidMount() {
        if (this.props.appClient) {
            this.formRef.current.setFieldsValue({ ...this.props.appClient });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.appClient && prevProps.appClient !== this.props.appClient) {
            this.formRef.current.setFieldsValue({ ...this.props.appClient });
        }
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        let appClient = { ...formData };
        if (this.props.action === "create") {
            appClient.active = true;
            this.props.onSave(appClient);
        }
        else {
            let diffObject = diff(this.props.appClient, appClient);
            let updatedObject = {};
            for (let key in diffObject) {
                if (diffObject[key] !== undefined) {
                    updatedObject[key] = appClient[key];
                }
            }
            if (_.isEmpty(updatedObject) === false) {
                updatedObject.version = this.props.appClient.version;
            }

            this.props.onSave(this.props.appClient.clientId, updatedObject);
        }
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    onRegenerateAppClientSecret = (event) => {
        this.props.onRegenerateAppClientSecret && this.props.onRegenerateAppClientSecret(this.props.appClient);
    }

    render() {
        return (
            <Form
                layout="horizontal"
                labelAlign="left"
                ref={this.formRef}
                name="basic"
                onFinish={this.onFormSubmit}
                initialValues={this.state.appClientDefault}
                onValuesChange={this.props.onValuesChanged}
                style={{ height: "100%" }}>
                <FullHeightContainerLayout
                    showFooter={true}
                    content={
                        <>
                            <Form.Item
                                className="wrap-label"
                                {...this.getFormErrors("clientName")}
                                label="App Name"
                                name={["clientName"]}
                                rules={[{ required: true, message: `Name is required` }]}
                                hasFeedback>
                                <Input placeholder="App Client Name" readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.APP_CLIENT_MANAGE_NAME_INPUT} />
                            </Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        className="wrap-label"
                                        {...this.getFormErrors("accessTokenValidity")}
                                        label="Access Token Validity"
                                        name={["accessTokenValidity"]}
                                        rules={[{ required: true, message: `Access Token Validity is required` }]}
                                        hasFeedback>
                                        <InputNumber
                                            defaultValue={1}
                                            min={1}
                                            max={24}
                                            formatter={value => `${value} hours`}
                                            parser={value => value && value.replace(' hours', '')}
                                            style={{ width: "100%" }}
                                            readOnly={this.isReadOnlyView()}
                                            data-testid={CypressTestIds.APP_CLIENT_MANAGE_ACCESS_TOKEN_VALIDITY_INPUT}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                this.props.action !== "create"
                                    ?
                                    <>
                                        <Form.Item
                                            className="wrap-label"
                                            label="Client Id"
                                            name={["clientId"]}
                                            data-testid={CypressTestIds.APP_CLIENT_MANAGE_CLIENT_ID_INPUT}>
                                            <CopyInput readOnly={true} data-testid={CypressTestIds.APP_CLIENT_MANAGE_CLIENT_ID_COPY_INPUT}></CopyInput>
                                        </Form.Item>
                                        <Row style={{ flexWrap: "nowrap" }}>
                                            <Col style={{ width: "100%" }}>
                                                <Form.Item
                                                    className="wrap-label"
                                                    label="Secret"
                                                    name={["clientSecret"]}
                                                    >
                                                    <CopyInput readOnly={true} data-testid={CypressTestIds.APP_CLIENT_MANAGE_SECRET_INPUT}></CopyInput>
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ width: "auto" }}>
                                                <Button onClick={this.onRegenerateAppClientSecret} disabled={this.isReadOnlyView() || !this.isSaveAllowed()} data-testid={CypressTestIds.APP_CLIENT_MANAGE_SECRET_REGENERATE_BUTTON}>Regenerate</Button>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <></>
                            }
                        </>
                    }
                    footer={
                        <Row>
                            <Col span={24} className="footer-right-column">
                                <Space>
                                    <Button onClick={this.props.onCancel} data-testid={CypressTestIds.APP_CLIENT_MANAGE_CANCEL_BUTTON} >Cancel</Button>
                                    <Button type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()} data-testid={CypressTestIds.APP_CLIENT_MANAGE_SAVE_BUTTON}>Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    }>
                </FullHeightContainerLayout>
            </Form>
        );
    }
}

export default ManageAppClient