import React from "react";
import { Switch, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Column } = Table;

const ChangeDataCaptureList = ({ items, onAddCDCDestination, onViewCDCDestination, onEditCDCDestination, onDeleteCDCDestination, onActivateDeactivateCDCItem, permission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }

    return <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
        <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <FullHeightContainerLayout
                showHeader={false}
                showFooter={true}
                content={
                    <Table size="small"
                        rowKey="connectionId"
                        dataSource={items}
                        pagination={false}
                        scroll={{ y: "100vh" }}
                        className="container-height-100"
                        data-testid={CypressTestIds.CDC_LIST_TABLE}>
                        <Column
                            width="6em"
                            render={(value, cdcItem, index) => <>
                            <Tooltip title ='Edit CDC Destination'>
                                <Icon
                                    disabled={!permission.canEdit}
                                    color='blue'
                                    name='pencil'
                                    className="action-cursor"
                                    onClick={() => onEditCDCDestination(cdcItem.destinationId)} 
                                    data-testid={CypressTestIds.CDC_LIST_EDIT_BUTTON} /> </Tooltip>
                                    <Tooltip title ='View CDC Destination'>
                                <Icon
                                    disabled={!permission.canView}
                                    color='blue'
                                    name='eye'
                                    className="action-cursor"
                                    onClick={() => onViewCDCDestination(cdcItem.destinationId)}
                                    data-testid={CypressTestIds.CDC_LIST_VIEW_BUTTON} /> </Tooltip>
                            </>}
                        />
                        <Column
                            width="15%"
                            dataIndex="name"
                            title="Name"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, cdcItem, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            width="15%"
                            dataIndex="description"
                            title="Description"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, cdcItem, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                        />
                        <Column
                            dataIndex="destinationType"
                            title="Type"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, cdcItem, index) => {
                                return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                            }}
                        />
                        <Column
                            dataIndex="createdDate"
                            title="Created Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, cdcItem, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            dataIndex="updatedDate"
                            title="Updated Date"
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, cdcItem, index) => <Tooltip placement="topLeft" title={value}>{moment.utc(value).toDate().toLocaleString()}</Tooltip>}
                        />
                        <Column
                            width="8%"
                            dataIndex="active"
                            title={"Active"}
                            ellipsis={{
                                showTitle: false,
                            }}
                            render={(value, cdcItem, index) => {
                                return <Switch checked={value} disabled={!permission.canEdit} onClick={(checked, event) => { }}
                                data-testid={CypressTestIds.CDC_LIST_ACTIVE_SWITCH}></Switch>;
                            }}
                        />
                        <Column
                            width="5%"
                            render={(value, cdcItem, index) => {
                                return (

                                    
                                        <Tooltip title ='Delete CDC Destination'>
                                    <Icon
                                        disabled={!permission.canDelete}
                                        color='blue'
                                        name='trash alternate'
                                        className="action-cursor"
                                        onClick={() => onDeleteCDCDestination(cdcItem)}
                                        data-testid={CypressTestIds.CDC_LIST_DELETE_BUTTON} /> </Tooltip> 
                                );
                            }}
                        />
                    </Table>}
                footer={
                    <Row className="table-footer-row">
                        <Col span={24} className="footer-right-column">
                            <Button type="primary"
                                disabled={!permission.canAdd}
                                onClick={onAddCDCDestination}
                                data-testid={CypressTestIds.CDC_LIST_ADD_BUTTON}>
                                <PlusOutlined /> Add Change Data Capture Destination
                            </Button>
                        </Col>
                    </Row>}
                footerStyle={{ paddingTop: "1rem" }}>

            </FullHeightContainerLayout>
        </Col>
    </Row>
};

export default ChangeDataCaptureList;