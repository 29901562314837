import React from 'react';
import { Modal, Button } from 'antd';
import CypressTestIds from "../../../cypress/CypressTestIds";

const OkCancelConfirmationBox = ({ show, message, cancelButtonText, okButtonText, onCancel, onOk, title = "" }) => {
    let footerButtons = [];
    if (onCancel) {
        footerButtons.push(
            <Button key="back" onClick={onCancel} data-testid={CypressTestIds.CONFIRMATION_BOX_CANCEL_BUTTON}>
                {cancelButtonText || "Cancel"}
            </Button>)
    }
    footerButtons.push(
        <Button key="submit" type="primary" onClick={onOk} data-testid={CypressTestIds.CONFIRMATION_BOX_OK_BUTTON}>
            {okButtonText || "OK"}
        </Button>)

    return (
        <Modal
            title={title}
            centered
            closable={false}
            maskClosable={false}
            open={show}
            footer={footerButtons}>
            <p>{message || "Are you sure?"}</p>
        </Modal >
    )
}

export default OkCancelConfirmationBox