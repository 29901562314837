import { LoadingOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useScreenSize } from "hooks";
import React from "react";

type getOffsetResult = {
    borderX: number;
    borderY: number;
    paddingX: number;
    paddingY: number;
};

function getOffset(el: HTMLElement): getOffsetResult {
    const cs = getComputedStyle(el);

    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    const paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

    const borderX =
        parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
    const borderY =
        parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

    return {
        borderX,
        borderY,
        paddingX,
        paddingY,
    };
}

const LoadingOverlay = ({ busy, message, spinner }: LoadingOverlayProps) => {
    const loadingOverlayContainer = React.useRef<HTMLDivElement>(null);
    const screenSize = useScreenSize(); // To track resize of window

    React.useEffect(() => {
        if (
            loadingOverlayContainer.current &&
            loadingOverlayContainer.current.parentElement
        ) {
            const parentElement = loadingOverlayContainer.current.parentElement;
            const { borderX, borderY, paddingX, paddingY } =
                getOffset(parentElement);

            loadingOverlayContainer.current.style.height = `calc(100% - ${
                paddingY + borderY
            }px)`;
            loadingOverlayContainer.current.style.width = `calc(100% - ${
                paddingX + borderX
            }px)`;
        }
    }, [screenSize, busy, message]);

    return busy ? (
        <div
            className="absolute z-10 box-content flex flex-col items-center justify-center gap-3 overflow-hidden bg-white/90 opacity-100"
            ref={loadingOverlayContainer}
        >
            {spinner && <LoadingOutlined className="text-blue-500" />}
            <Typography>{message}</Typography>
        </div>
    ) : (
        <></>
    );
};

export default LoadingOverlay;
