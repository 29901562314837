import mergeClassNames from "common/mergeClassNames";

export type IDataPreviewDataType = Record<string, React.ReactNode>;

type IDataPreviewTableProps = {
    headers: IDataPreviewDataType;
    rows: IDataPreviewDataType[];
    children?: React.ReactNode;
    footer?: React.ReactNode;
    transposed?: boolean;
};

export default function DataPreviewTable({
    children,
    headers,
    rows,
    footer,
    transposed = false,
}: IDataPreviewTableProps) {
    return (
        <table
            className={mergeClassNames(
                "relative",
                transposed ? "flex min-w-fit flex-row flex-nowrap" : "w-full",
            )}
        >
            <thead
                className={
                    transposed
                        ? "sticky left-0 inline-flex flex-row flex-nowrap self-start"
                        : ""
                }
            >
                <tr className={transposed ? "inline-flex flex-col" : ""}>
                    {Object.keys(headers).map((col) => (
                        <th
                            key={`table-header-${col}`}
                            className={mergeClassNames(
                                "whitespace-nowrap border-b border-white bg-slate-100 px-4 py-3 text-left align-top text-sm",
                                transposed ? "" : "sticky top-0",
                            )}
                        >
                            {headers[col]}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody
                className={transposed ? "inline-flex flex-row flex-nowrap" : ""}
            >
                {rows.map((record, rowIndex) => (
                    <tr
                        className={
                            transposed
                                ? "inline-flex flex-col"
                                : "hover:bg-slate-100"
                        }
                        key={`table-body-row-${rowIndex}`}
                    >
                        {Object.keys(record).map((colIndex) => (
                            <td
                                key={`${rowIndex}-${colIndex}`}
                                className={mergeClassNames(
                                    "whitespace-nowrap border-b border-slate-100 px-4 py-3 text-left align-top text-sm",
                                    transposed
                                        ? "block border-l hover:bg-slate-100"
                                        : "",
                                )}
                            >
                                {record[colIndex]}
                            </td>
                        ))}
                    </tr>
                ))}
                {children}
            </tbody>
            <tfoot>{footer}</tfoot>
        </table>
    );
}
