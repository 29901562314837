import moment from 'moment';

export const ALLOWED_FILE_TYPES = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/plain': ['.txt'],
  
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/tiff': ['.tiff'],
  
  'audio/mpeg': ['.mp3'],
  'video/mp4': ['.mp4'],
  'audio/wav': ['.wav']
};

export const ALLOWED_EXTENSIONS = Object.values(ALLOWED_FILE_TYPES).flat();

export const BLOCKED_EXTENSIONS = [
  '.exe', '.bat', '.cmd', '.sh', '.com', '.bin', '.msi',
  '.js', '.jsx', '.ts', '.tsx', '.py', '.php', '.rb', '.pl',
  '.dll', '.sys', '.vbs', '.wsf', '.reg'
];

export const keyGenerator = (offset) => moment().add(offset, "seconds").valueOf();

export const toLocalFromUtcDate = (utcDate) => {
  if (!utcDate) return utcDate;
  try {
    return moment.utc(utcDate).toDate().toLocaleString();
  } catch {
    return utcDate;
}
}

export const maskValue = (value) => {
    if (value === null || value === undefined) {
        return value;
    }

    const phoneRegex = /(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{1,4}\)?[-.\s]?)?(?:\d{1,4}[-.\s]?){1,3}(?:x\d{1,5})?/;

    const valueStr = value.toString();

    if (phoneRegex.test(valueStr)) {
        return "#".repeat(valueStr.length);
    }

    if (typeof value === "string") {
        return "#".repeat(value.length);
    }


    if (typeof value === 'number') {
        return "#".repeat(value.toString().length);
    }

    if (typeof value === 'boolean') {
        return "#####";
    }

    if (Object.prototype.toString.call(value) === "[object Date]") {
        return "##########"; 
    }

    if (Array.isArray(value)) {
        return value.map((item) => maskValue(item));
    }

    if (typeof value === 'object') {
        return Object.keys(value).reduce((maskedObject, key) => {
            maskedObject[key] = maskValue(value[key]);
            return maskedObject;
        }, {});
    }

    return "#".repeat(value.toString().length);
};

export const sanitizeFileName = (fileName) => {
  const sanitized = fileName.replace(/^.*[\\\/]/, '').replace(/[^a-zA-Z0-9.-]/g, '_');
  
  const parts = sanitized.split('.');
  if (parts.length > 2) {
    const ext = parts.pop();
    return parts.join('_') + '.' + ext;
  }
  
  return sanitized;
};

export const generateUniqueFileName = (fileName) => {
  const sanitized = sanitizeFileName(fileName);
  const timestamp = moment().format('YYYYMMDD-HHmmss');
  const uniqueId = Math.random().toString(36).substring(2, 8);
  const ext = sanitized.split('.').pop();
  const baseName = sanitized.split('.')[0];
  return `${baseName}-${timestamp}-${uniqueId}.${ext}`;
};

export const validateFileType = async (file) => {
  if (!file) {
    throw new Error('No file provided');
  }

  const fileName = file.name.toLowerCase();
  const fileExtension = '.' + fileName.split('.').pop();

  if (fileName.split('.').length > 2) {
    throw new Error('Files with multiple extensions are not allowed');
  }

  const hasBlockedExtension = BLOCKED_EXTENSIONS.some(ext => 
    fileName.includes(ext.toLowerCase())
  );

  if (hasBlockedExtension) {
    throw new Error('This file type is not allowed for security reasons');
  }

  const declaredMimeType = file.type.toLowerCase();
  const expectedExtensions = ALLOWED_FILE_TYPES[declaredMimeType];

  if (!expectedExtensions) {
    throw new Error('Unsupported file type. Please upload only allowed file types.');
  }

  if (!expectedExtensions.includes(fileExtension)) {
    throw new Error(`Invalid file type. Expected ${expectedExtensions.join(' or ')} for ${getFileTypeDescription(declaredMimeType)}`);
  }

  try {
    await validateFileContent(file);
  } catch (error) {
    throw new Error(`File validation failed: ${error.message}`);
  }

  return true;
};

export const validateFileContent = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        const header = Array.from(arr).map(byte => byte.toString(16)).join('');
        
        const isValid = validateFileSignature(header, file.type);
        
        if (!isValid) {
          reject(new Error('File content appears to be invalid or corrupted'));
        }
        
        resolve(true);
      } catch (error) {
        reject(new Error('Failed to validate file content'));
      }
    };

    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsArrayBuffer(file);
  });
};

const validateFileSignature = (hex, mimeType) => {
  const signatures = {
    'application/pdf': ['25504446'],
    'image/jpeg': ['ffd8ff'],
    'image/png': ['89504e47'],
    'image/gif': ['47494638'],
    'video/mp4': ['66747970'],
    'audio/mpeg': ['494433', 'fffb'],
    'audio/wav': ['52494646'],
  };

  if (signatures[mimeType]) {
    return signatures[mimeType].some(sig => hex.startsWith(sig.toLowerCase()));
  }

  return true;
};

export const getFileTypeDescription = (mimeType) => {
  const descriptions = {
    'application/pdf': 'PDF Document',
    'application/msword': 'Word Document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document',
    'text/plain': 'Text Document',
    'application/vnd.ms-excel': 'Excel Spreadsheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet',
    'application/vnd.ms-powerpoint': 'PowerPoint Presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint Presentation',
    'image/jpeg': 'JPEG Image',
    'image/png': 'PNG Image',
    'image/gif': 'GIF Image',
    'image/tiff': 'TIFF Image',
    'audio/mpeg': 'MP3 Audio',
    'video/mp4': 'MP4 Video',
    'audio/wav': 'WAV Audio'
  };
  return descriptions[mimeType] || 'Unknown Type';
};

export const parseValue = (val) => {
  if (!val) return [];
  if (Array.isArray(val)) return val;
  try {
    const parsed = JSON.parse(val);
    return Array.isArray(parsed) ? parsed : [];
  } catch (e) {
    return [];
  }
};

export const formatFileSize = (sizeMB) => sizeMB * 1024 * 1024;

export const getHumanReadableFileSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
};


export const FILE_TYPE_CONFIGS = {
  image: {
    acceptedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
    description: 'Images (JPG, PNG, GIF, WEBP)'
  },
  document: {
    acceptedTypes: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ],
    description: 'Documents (PDF, DOC, DOCX, XLS, XLSX, PPT, PPTX)'
  },
  attachment: {
    acceptedTypes: [
      'image/jpeg', 'image/png', 'image/gif', 'image/webp',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'video/mp4', 'video/webm', 'video/ogg', 'video/quicktime'
    ],
    description: 'All Files (Images, Documents, Videos)'
  }
};

export const calcNewSortOrder=(items,sourceIndex,destinationIndex)=>{
    let newSortOrder;
    if(destinationIndex === 0) {
        newSortOrder = Math.floor(items[destinationIndex].sortOrder / 2);
    }
    else if(destinationIndex === items.length - 1) {
        newSortOrder = items[destinationIndex].sortOrder + 100;
    }
    else{
        newSortOrder =Math.floor((items[destinationIndex-1].sortOrder + items[destinationIndex].sortOrder) / 2);
    }
    return newSortOrder;
}