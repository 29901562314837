export const CREATE_DATA_OBJECTS_UPLOAD_JOB_REQUEST = "CREATE_DATA_OBJECTS_UPLOAD_JOB_REQUEST";
export const CREATE_DATA_OBJECTS_UPLOAD_JOB_SUCCESS = "CREATE_DATA_OBJECTS_UPLOAD_JOB_SUCCESS";
export const CREATE_DATA_OBJECTS_UPLOAD_JOB_FAILURE = "CREATE_DATA_OBJECTS_UPLOAD_JOB_FAILURE";

export const GET_DATA_OBJECTS_BY_DATASET_ID_REQUEST = "GET_DATA_OBJECTS_BY_DATASET_ID_REQUEST";
export const GET_DATA_OBJECTS_BY_DATASET_ID_SUCCESS = "GET_DATA_OBJECTS_BY_DATASET_ID_SUCCESS";
export const GET_DATA_OBJECTS_BY_DATASET_ID_FAILURE = "GET_DATA_OBJECTS_BY_DATASET_ID_FAILURE";

export const GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_REQUEST = "GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_REQUEST"
export const GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_SUCCESS = "GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_SUCCESS"
export const GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_FAILURE = "GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_FAILURE"

export const GET_DATA_OBJECT_REQUEST = "GET_DATA_OBJECT_REQUEST";
export const GET_DATA_OBJECT_SUCCESS = "GET_DATA_OBJECT_SUCCESS";
export const GET_DATA_OBJECT_FAILURE = "GET_DATA_OBJECT_FAILURE";

export const CREATE_DATA_OBJECT_REQUEST = "CREATE_DATA_OBJECT_REQUEST";
export const CREATE_DATA_OBJECT_SUCCESS = "CREATE_DATA_OBJECT_SUCCESS";
export const CREATE_DATA_OBJECT_FAILURE = "CREATE_DATA_OBJECT_FAILURE";

export const UPDATE_DATA_OBJECT_REQUEST = "UPDATE_DATA_OBJECT_REQUEST";
export const UPDATE_DATA_OBJECT_SUCCESS = "UPDATE_DATA_OBJECT_SUCCESS";
export const UPDATE_DATA_OBJECT_FAILURE = "UPDATE_DATA_OBJECT_FAILURE";

export const DELETE_DATA_OBJECT_REQUEST = "DELETE_DATA_OBJECT_REQUEST";
export const DELETE_DATA_OBJECT_SUCCESS = "DELETE_DATA_OBJECT_SUCCESS";
export const DELETE_DATA_OBJECT_FAILURE = "DELETE_DATA_OBJECT_FAILURE";

export const GET_DATA_OBJECT_PERMISSIONS_REQUEST = "GET_DATA_OBJECT_PERMISSIONS_REQUEST";
export const GET_DATA_OBJECT_PERMISSIONS_SUCCESS = "GET_DATA_OBJECT_PERMISSIONS_SUCCESS";
export const GET_DATA_OBJECT_PERMISSIONS_FAILURE = "GET_DATA_OBJECT_PERMISSIONS_FAILURE";

export const GET_DATA_OBJECT_BY_UUID_REQUEST = "GET_DATA_OBJECT_BY_UUID_REQUEST";
export const GET_DATA_OBJECT_BY_UUID_SUCCESS = "GET_DATA_OBJECT_BY_UUID_SUCCESS";
export const GET_DATA_OBJECT_BY_UUID_FAILURE = "GET_DATA_OBJECT_BY_UUID_FAILURE";

export const GET_DATA_OBJECT_SHARED_LINK_REQUEST = "GET_DATA_OBJECT_SHARED_LINK_REQUEST";
export const GET_DATA_OBJECT_SHARED_LINK_SUCCESS = "GET_DATA_OBJECT_SHARED_LINK_SUCCESS";
export const GET_DATA_OBJECT_SHARED_LINK_FAILURE = "GET_DATA_OBJECT_SHARED_LINK_FAILURE";

export const GET_SHARED_DATA_OBJECT_BY_UUID_REQUEST = "GET_SHARED_DATA_OBJECT_BY_UUID_REQUEST";

export const UPDATE_SHARED_DATA_OBJECT_REQUEST = "UPDATE_SHARED_DATA_OBJECT_REQUEST";

export const UPDATE_DATA_OBJECT_VALIDATION_ERRORS_REQUEST = "UPDATE_DATA_OBJECT_VALIDATION_ERRORS_REQUEST";

export const GET_UNMASKED_DATA_BY_UUID_REQUEST = "GET_UNMASKED_DATA_BY_UUID_REQUEST";
export const GET_UNMASKED_DATA_BY_UUID_SUCCESS = "GET_UNMASKED_DATA_BY_UUID_SUCCESS";
export const GET_UNMASKED_DATA_BY_UUID_FAILURE = "GET_UNMASKED_DATA_BY_UUID_FAILURE";


export const createDataObjectsUploadJobRequest = (businessAreaId, schemaId, dataSet, dataUploadOptions, file, fieldMappings) => ({
    type: CREATE_DATA_OBJECTS_UPLOAD_JOB_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSet,
        dataUploadOptions,
        file,
        fieldMappings
    }
})

export const createDataObjectsUploadJobSuccess = () => ({
    type: CREATE_DATA_OBJECTS_UPLOAD_JOB_SUCCESS
})

export const createDataObjectsUploadJobFailure = (error) => ({
    type: CREATE_DATA_OBJECTS_UPLOAD_JOB_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectsByDataSetIdRequest = (businessAreaId, schemaId, dataSetId) => ({
    type: GET_DATA_OBJECTS_BY_DATASET_ID_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId
    }
})

export const getDataObjectsByDataSetIdSuccess = (dataObjectsResult) => ({
    type: GET_DATA_OBJECTS_BY_DATASET_ID_SUCCESS,
    payload: {
        dataObjectsResult
    }
})

export const getDataObjectsByDataSetIdFailure = (error) => ({
    type: GET_DATA_OBJECTS_BY_DATASET_ID_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectsByDataSetIdNextPageRequest = (businessAreaId, schemaId, dataSetId, pageKey) => ({
    type: GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        pageKey
    }
})

export const getDataObjectsByDataSetIdNextPageSuccess = (dataObjectsResult) => ({
    type: GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_SUCCESS,
    payload: {
        dataObjectsResult
    }
})

export const getDataObjectsByDataSetIdNextPageFailure = (error) => ({
    type: GET_DATA_OBJECTS_BY_DATASET_ID_NEXT_PAGE_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectRequest = (businessAreaId, schemaId, dataSetId, dataObjectId) => ({
    type: GET_DATA_OBJECT_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId
    }
})

export const getDataObjectSuccess = (dataObject) => ({
    type: GET_DATA_OBJECT_SUCCESS,
    payload: {
        dataObject
    }
})

export const getDataObjectFailure = (error) => ({
    type: GET_DATA_OBJECT_FAILURE,
    payload: {
        error
    }
})

export const createDataObjectRequest = (businessAreaId, schemaId, dataSetId, dataObject) => ({
    type: CREATE_DATA_OBJECT_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObject
    }
})

export const createDataObjectSuccess = (dataObject) => ({
    type: CREATE_DATA_OBJECT_SUCCESS,
    payload: {
        dataObject
    }
})

export const createDataObjectFailure = (error) => ({
    type: CREATE_DATA_OBJECT_FAILURE,
    payload: {
        error
    }
})

export const updateDataObjectRequest = (businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) => ({
    type: UPDATE_DATA_OBJECT_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId,
        dataObject
    }
})

export const updateDataObjectSuccess = (dataObjectId, dataObject) => ({
    type: UPDATE_DATA_OBJECT_SUCCESS,
    payload: {
        dataObjectId,
        dataObject
    }
})

export const updateDataObjectFailure = (error) => ({
    type: UPDATE_DATA_OBJECT_FAILURE,
    payload: {
        error
    }
})

export const deleteDataObjectRequest = (businessAreaId, schemaId, dataSetId, dataObjectId) => ({
    type: DELETE_DATA_OBJECT_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId
    }
})

export const deleteDataObjectSuccess = (businessAreaId, schemaId, dataSetId, dataObjectId) => ({
    type: DELETE_DATA_OBJECT_SUCCESS,
    payload: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId
    }
})

export const deleteDataObjectFailure = (error) => ({
    type: DELETE_DATA_OBJECT_FAILURE,
    payload: {
        error
    }
})

export const updateDataObjectValidationErrorsRequest = (dataObject, validationErrors) => ({
    type: UPDATE_DATA_OBJECT_VALIDATION_ERRORS_REQUEST,
    params: {
        dataObject,
        validationErrors
    }
})

export const getDataObjectPermissionRequest = () => ({
    type: GET_DATA_OBJECT_PERMISSIONS_REQUEST
})

export const getDataObjectPermissionSuccess = (permission) => ({
    type: GET_DATA_OBJECT_PERMISSIONS_SUCCESS,
    payload: {
        permission
    }
})

export const getDataObjectPermissionFailure = (error) => ({
    type: GET_DATA_OBJECT_PERMISSIONS_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectByUUIDRequest = (dataObjectId) => ({
    type: GET_DATA_OBJECT_BY_UUID_REQUEST,
    params: {
        dataObjectId
    }
})

export const getDataObjectByUUIDSuccess = (result) => ({
    type: GET_DATA_OBJECT_BY_UUID_SUCCESS,
    payload: {
        result
    }
})

export const getDataObjectByUUIDFailure = (error) => ({
    type: GET_DATA_OBJECT_BY_UUID_FAILURE,
    payload: {
        error
    }
})

export const getDataObjectSharedLinkRequest = (businessAreaId, schemaId, dataSetId, dataObjectId, expires, readOnly) => ({
    type: GET_DATA_OBJECT_SHARED_LINK_REQUEST,
    params: {
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId,
        readOnly,
        expires
    }
})

export const getDataObjectSharedLinkSuccess = (result) => ({
    type: GET_DATA_OBJECT_SHARED_LINK_SUCCESS,
    payload: {
        result
    }
})

export const getDataObjectSharedLinkFailure = (error) => ({
    type: GET_DATA_OBJECT_SHARED_LINK_FAILURE,
    payload: {
        error
    }
})

export const getSharedDataObjectByUUIDRequest = (tenantId, userId, readOnly, expiresAt, signatureDate, signature, dataObjectId) => ({
    type: GET_SHARED_DATA_OBJECT_BY_UUID_REQUEST,
    params: {
        tenantId,
        userId,
        readOnly,
        expiresAt,
        signatureDate,
        signature,
        dataObjectId
    }
})

export const updateSharedDataObjectRequest = (tenantId, userId, readOnly, expiresAt, signatureDate, signature, businessAreaId, schemaId, dataSetId, dataObjectId, dataObject) => ({
    type: UPDATE_SHARED_DATA_OBJECT_REQUEST,
    params: {
        tenantId,
        userId,
        readOnly,
        expiresAt,
        signatureDate,
        signature,
        businessAreaId,
        schemaId,
        dataSetId,
        dataObjectId,
        dataObject
    }
})

export const getUnmaskedDataByUUIDRequest = (dataObjectId) => ({
    type: GET_UNMASKED_DATA_BY_UUID_REQUEST,
    params: { dataObjectId },
});

export const getUnmaskedDataByUUIDSuccess = (result) => ({
    type: GET_UNMASKED_DATA_BY_UUID_SUCCESS,
    payload: { result },
});

export const getUnmaskedDataByUUIDFailure = (error) => ({
    type: GET_UNMASKED_DATA_BY_UUID_FAILURE,
    payload: { error },
});