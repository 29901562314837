import React, { Component } from 'react';
import LABELS from 'constants/labels';
import { Form, Input, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import CypressTestIds from "../../../cypress/CypressTestIds";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: '',
                password: '',
            }
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        this.props.onUserLogin(formData.email, formData.password);
    }

    render() {
        return (
            <Form layout="vertical"
                labelAlign="left"
                ref={this.formRef}
                name="basic"
                initialValues={this.state.form}
                onFinish={this.onFormSubmit}>
                <Form.Item
                    className="wrap-label"
                    {...this.getFormErrors("email")}
                    label={LABELS.CORPORATE_EMAIL_ADDRESS}
                    name={["email"]}
                    rules={[{ required: true, message: 'Email is required' }, { type: 'email' }]}
                    hasFeedback>
                    <Input
                        placeholder={LABELS.CORPORATE_EMAIL_ADDRESS_PLACE_HOLDER}
                        disabled={this.props.inProgress}
                        data-testid={CypressTestIds.USER_LOGIN_EMAIL_INPUT}
                    />
                </Form.Item>
                <Form.Item
                    className="wrap-label"
                    {...this.getFormErrors("password")}
                    label={LABELS.PASSWORD}
                    name={["password"]}
                    rules={[{ required: true, message: 'Password is required' }]}
                    hasFeedback>
                    <Input.Password
                        placeholder='*******'
                        disabled={this.props.inProgress}
                        data-testid={CypressTestIds.USER_LOGIN_PASSWORD_INPUT}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        data-testid={CypressTestIds.USER_LOGIN_SUBMIT_BUTTON}
                        block
                        type="primary"
                        htmlType="submit"
                        loading={this.props.inProgress}>
                        {this.props.progressMessage || "Login"}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Login