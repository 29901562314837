import React from "react";
import _ from 'lodash';
import {
    FormBooleanField,
    FormCurrencyField,
    FormDateDayField,
    FormDateField,
    FormDateMonthField,
    FormDateTimeField,
    FormDateYearField,
    FormEmailField,
    FormFloatField,
    FormIntegerField,
    FormPhoneField,
    FormPicklistField,
    FormStringField,
    FormTimeField,
    FormUrlField,
    FormLatLongField,
    FormLatitudeField,
    FormLongitudeField
} from 'components';

import {
    FormDomainDataPicklistField
} from 'containers';
import AttachmentField from "components/molecules/AttachmentField";

const FormField = ({ dataType, disabled, ...props }) => {
    const commonProps = { disabled, ...props };
    switch (dataType) {
        case "integer":
            return <FormIntegerField {...commonProps} />;
        case "float":
            return <FormFloatField {...commonProps} />;
        case "string":
            return <FormStringField {...commonProps} />;
        case "boolean":
            return <FormBooleanField {...commonProps} />;
        case "datetime":
            return <FormDateTimeField {...commonProps} />;
        case "date":
            return <FormDateField {...commonProps} />;
        case "time":
            return <FormTimeField {...commonProps} />;
        case "date-year":
            return <FormDateYearField {...commonProps} />;
        case "date-month":
            return <FormDateMonthField {...commonProps} />;
        case "date-day":
            return <FormDateDayField {...commonProps} />;
        case "currency":
            return <FormCurrencyField {...commonProps} />;
        case "picklist":
            if (props.picklistType === "static") {
                return <FormPicklistField items={props.staticPicklist} fetchingData={false} {...commonProps} />;
            }
            else if (props.picklistType === "dynamic") {
                return <FormDomainDataPicklistField {...commonProps} />;
            }
            return <FormPicklistField items={[]} fetchingData={false} {...commonProps} />;
        case "email":
            return <FormEmailField {...commonProps} />;
        case "url":
            return <FormUrlField {...commonProps} />;
        case "phone":
            return <FormPhoneField {...commonProps} />;
        case "latlng":
            return <FormLatLongField
                precision={4}
                format="decimal"
                separator=","
                {...commonProps}
            />;
        case "latitude":
            return <FormLatitudeField precision={7} {...commonProps} />;
        case "longitude":
            return <FormLongitudeField precision={7} {...commonProps} />;
        case "attachment":
            props.dataType = "attachment";
            return <AttachmentField {...commonProps} />;
        case "image":
            props.dataType = "image";
            return <AttachmentField {...commonProps} />;
        case "document":
            props.dataType = "document";
            return <AttachmentField {...commonProps} />;
        default:
            return <FormStringField {...commonProps} />;
        }
};

export default FormField;