import React, { Component } from 'react';
import LABELS from 'constants/labels';
import { Form, Input, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import CypressTestIds from "../../../cypress/CypressTestIds";

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: ''
            }
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: <Typography.Text data-testid={CypressTestIds.USER_RESET_PASSWORD_EMAIL_ERROR_MESSAGE}>{this.props.formErrors[fieldName]}</Typography.Text>,
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        this.props.onRecover(formData.email);
    }

    render() {
        return (
            <Form layout="vertical"
                labelAlign="left"
                ref={this.formRef}
                name="basic"
                initialValues={this.state.form}
                onFinish={this.onFormSubmit}>
                <Form.Item
                    data-testid={CypressTestIds.USER_RESET_PASSWORD_FORM}
                    className="wrap-label"
                    {...this.getFormErrors("email")}
                    name={["email"]}
                    rules={[{ required: true, message: 'Email is required' }, { type: 'email' }]}
                >
                    <Input
                        data-testid={CypressTestIds.USER_RESET_PASSWORD_EMAIL_INPUT}
                        placeholder={LABELS.CORPORATE_EMAIL_ADDRESS_PLACE_HOLDER}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        data-testid={CypressTestIds.USER_RESET_PASSWORD_SUBMIT_BUTTON}
                        block type="primary"
                        htmlType="submit"
                    >{this.props.submitText}</Button>
                </Form.Item>
            </Form>
        );
    }
}

export default ForgotPassword