import React, { useState, useCallback, useRef, useEffect } from 'react';
import { message } from 'antd';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import FilePondUploader from 'components/molecules/FilePondUploader';
import AttachmentsList from 'components/molecules/AttachmentsList';
import {
    validateFileContent,
    sanitizeFileName,
    getFileTypeDescription,
    parseValue,
    formatFileSize,
    getHumanReadableFileSize,
    BLOCKED_EXTENSIONS
} from 'common/Utility';

const FileUpload = ({ 
    tenantId, 
    createdBy, 
    businessAreaId, 
    fieldId,
    onChange,
    allowMultiple = true,
    maxFiles = 10,
    maxFileSize = 50,
    maxWidth,
    maxHeight,
    disabled,
    value = [],
    acceptedFileTypes,
    fileTypeDescription
}) => {
    const pendingUploadsRef = useRef(new Set());
    const uploadQueueRef = useRef([]);
    const processingRef = useRef(false);
    const abortControllersRef = useRef(new Map());
    const maxConcurrentUploads = 3;
    
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loadingPreview, setLoadingPreview] = useState({});
    const [uploadStatus, setUploadStatus] = useState(new Map());
    const [uploadProgress, setUploadProgress] = useState(new Map());
    const [pond, setPond] = useState(null);
    const [error, setError] = useState(null);

    const checkUploadInProgress = useCallback(() => {
        return pendingUploadsRef.current.size > 0 || uploadQueueRef.current.length > 0;
    }, []);

    const shouldDisableUpload = useCallback(() => {
        return disabled || (!allowMultiple && (checkUploadInProgress() || uploadedFiles.length > 0));
    }, [disabled, allowMultiple, checkUploadInProgress, uploadedFiles.length]);

    const updateUploadStatus = useCallback((fileName, status, message) => {
        setUploadStatus(prev => {
            const newStatus = new Map(prev);
            if (message) {
                newStatus.set(fileName, { status, message });
            } else {
                newStatus.delete(fileName);
            }
            return newStatus;
        });
    }, []);

    const cleanup = useCallback((fileName, removeFromPond = true) => {
        const controller = abortControllersRef.current.get(fileName);
        if (controller) {
            controller.abort();
            abortControllersRef.current.delete(fileName);
        }

        pendingUploadsRef.current.delete(fileName);
        
        setUploadProgress(prev => {
            const newProgress = new Map(prev);
            newProgress.delete(fileName);
            return newProgress;
        });
        
        updateUploadStatus(fileName, 'cancelled', null);

        if (removeFromPond && pond) {
            const fileToRemove = pond.getFiles().find(f => f.filename === fileName);
            if (fileToRemove) {
                pond.removeFile(fileToRemove.id, { revert: false });
            }
        }

        if (!processingRef.current) {
            processNextInQueue();
        }
    }, [pond, updateUploadStatus]);

    const validateFileType = async (file) => {
        if (!file) {
            throw new Error('No file provided');
        }

        const fileName = file.name.toLowerCase();
        const declaredMimeType = file.type.toLowerCase();

        if (!acceptedFileTypes.includes(declaredMimeType)) {
            const error = new Error(`Only ${fileTypeDescription} are allowed`);
            error.code = 'FILE_TYPE_NOT_ALLOWED';
            throw error;
        }

        if (fileName.split('.').length > 2) {
            const error = new Error('Files with multiple extensions are not allowed');
            error.code = 'INVALID_FILE_NAME';
            throw error;
        }

        const hasBlockedExtension = BLOCKED_EXTENSIONS.some(ext => 
            fileName.includes(ext.toLowerCase())
        );

        if (hasBlockedExtension) {
            const error = new Error('This file type is not allowed for security reasons');
            error.code = 'BLOCKED_EXTENSION';
            throw error;
        }

        try {
            await validateFileContent(file);
        } catch (error) {
            const err = new Error('Invalid file content');
            err.code = 'INVALID_CONTENT';
            throw err;
        }

        return true;
    };

    const processNextInQueue = useCallback(async () => {
        if (processingRef.current || uploadQueueRef.current.length === 0) {
            return;
        }

        processingRef.current = true;
        
        while (uploadQueueRef.current.length > 0 && 
               pendingUploadsRef.current.size < maxConcurrentUploads) {
            const nextUpload = uploadQueueRef.current.shift();
            if (nextUpload) {
                const { file, metadata, load, error, progress } = nextUpload;
                try {
                    await processUpload(file, metadata, load, error, progress);
                } catch (err) {
                    error(err.message || 'Upload failed');
                    updateUploadStatus(file.name, 'error', err.message || 'Upload failed');
                    cleanup(file.name);
                }
            }
        }
        
        processingRef.current = false;
    }, [cleanup, updateUploadStatus]);

    const processUpload = async (file, metadata, load, error, progress) => {
        try {
            await validateFileType(file);

            const abortController = new AbortController();
            abortControllersRef.current.set(file.name, abortController);

            const fileSizeInMB = file.size / (1024 * 1024);
            if (maxFileSize && fileSizeInMB > maxFileSize) {
                throw new Error(`File size (${getHumanReadableFileSize(file.size)}) exceeds limit of ${maxFileSize}MB`);
            }

            if (file.type.startsWith('image/')) {
                await validateImageDimensions(file);
            }
            
            const uuid = uuidv4();
            const uniqueFileName = sanitizeFileName(file.name);
            const fileName = `tenants/${tenantId}/users/${createdBy}/businessareas/${businessAreaId}/attachments/${fieldId}/${uuid}/${uniqueFileName}`;
            
            pendingUploadsRef.current.add(file.name);
            setUploadProgress(prev => new Map(prev).set(file.name, 0));
            updateUploadStatus(file.name, 'uploading', 'Preparing upload...');
            
            const fileBuffer = await readFileAsArrayBuffer(file);
            
            if (!abortControllersRef.current.has(file.name)) {
                cleanup(file.name);
                return;
            }

            updateUploadStatus(file.name, 'uploading', 'Uploading...');
            
            const result = await Storage.put(fileName, fileBuffer, {
                contentType: file.type,
                level: 'public',
                progressCallback: (progressData) => {
                    if (!abortControllersRef.current.has(file.name)) return;
                    const progressPercentage = (progressData.loaded / progressData.total) * 100;
                    progress(progressData.loaded, progressData.total);
                    setUploadProgress(prev => new Map(prev).set(file.name, progressPercentage));
                    updateUploadStatus(file.name, 'uploading', `Uploading... ${Math.round(progressPercentage)}%`);
                },
                cancelTokenSource: abortController
            });

            if (!abortControllersRef.current.has(file.name)) {
                cleanup(file.name);
                return;
            }

            const fileInfo = {
                key: result.key,
                name: uniqueFileName,
                originalName: file.name,
                size: file.size,
                type: file.type,
                typeDescription: getFileTypeDescription(file.type),
                uploadedAt: new Date().toISOString()
            };

            setUploadedFiles(prevFiles => {
                let newFiles;
                if (allowMultiple) {
                    newFiles = [...prevFiles, fileInfo];
                } else {
                    newFiles = [fileInfo];
                }
                onChange(JSON.stringify(newFiles));
                return newFiles;
            });

            updateUploadStatus(file.name, 'success', 'Upload complete');
            message.success(`${file.name} uploaded successfully`);
            setError(null);
            cleanup(file.name);
            load(fileInfo);

        } catch (err) {
            if (err.name === 'AbortError' || !abortControllersRef.current.has(file.name)) {
                updateUploadStatus(file.name, 'cancelled', 'Upload cancelled');
            } else {
                const errorMessage = err.message || `Failed to upload ${file.name}`;
                message.error(errorMessage);
                setError(errorMessage);
                updateUploadStatus(file.name, 'error', errorMessage);
                error(errorMessage);
            }

            cleanup(file.name);
            throw err;
        }
    };

    const handleCancelUpload = useCallback((fileName) => {
        const controller = abortControllersRef.current.get(fileName);
        if (controller) {
            controller.abort();
            
            uploadQueueRef.current = uploadQueueRef.current.filter(
                item => item.file.name !== fileName
            );

            cleanup(fileName, true);
            message.info(`Upload cancelled for ${fileName}`);
        }
    }, [cleanup]);

    const handleProcessFile = useCallback(async (fieldName, file, metadata, load, error, progress) => {
        if (!allowMultiple) {
            setUploadedFiles([]);
            onChange(JSON.stringify([]));
            
            uploadQueueRef.current = [];
            Array.from(pendingUploadsRef.current).forEach(fileName => {
                handleCancelUpload(fileName);
            });
        }

        uploadQueueRef.current.push({ file, metadata, load, error, progress });
        
        if (!processingRef.current) {
            processNextInQueue();
        }
    }, [allowMultiple, onChange, handleCancelUpload, processNextInQueue]);

    const handleLoadFile = useCallback(async (source, load, error) => {
        try {
            let url = '';
            if (typeof source === 'string') {
                url = source;
            } else if (source.url) {
                url = source.url;
            } else if (source.key) {
                url = await Storage.get(source.key, { level: 'public' });
            }
            load(url);
        } catch (err) {
            error('Could not load file');
        }
    }, []);

    const handleRemove = useCallback(async (fileToRemove) => {
        try {
            if (fileToRemove.key) {
                await Storage.remove(fileToRemove.key);
                setUploadedFiles(prevFiles => {
                    const newFiles = prevFiles.filter(file => file.key !== fileToRemove.key);
                    onChange(JSON.stringify(newFiles));
                    return newFiles;
                });

                if (!allowMultiple) {
                    uploadQueueRef.current = [];
                    Array.from(pendingUploadsRef.current).forEach(fileName => {
                        handleCancelUpload(fileName);
                    });
                }

                message.success('File removed successfully');
            }
        } catch (err) {
            message.error('Failed to remove file');
        }
    }, [onChange, allowMultiple, handleCancelUpload]);

    const handlePreview = useCallback(async (file) => {
        setLoadingPreview(prev => ({ ...prev, [file.key]: true }));
        try {
            const url = await Storage.get(file.key, { level: 'public' });
            window.open(url, '_blank');
        } catch (error) {
            message.error('Could not load file preview');
        }
        setLoadingPreview(prev => ({ ...prev, [file.key]: false }));
    }, []);

    const validateImageDimensions = useCallback((file) => {
        return new Promise((resolve, reject) => {
            if (!file.type.startsWith('image/')) {
                resolve(true);
                return;
            }

            const img = new Image();
            const objectUrl = URL.createObjectURL(file);

            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                if (maxWidth && img.width > maxWidth) {
                    reject(new Error(`Image width must not exceed ${maxWidth}px`));
                } else if (maxHeight && img.height > maxHeight) {
                    reject(new Error(`Image height must not exceed ${maxHeight}px`));
                } else {
                    resolve(true);
                }
            };

            img.onerror = () => {
                URL.revokeObjectURL(objectUrl);
                reject(new Error('Failed to validate image dimensions'));
            };

            img.src = objectUrl;
        });
    }, [maxWidth, maxHeight]);

    const readFileAsArrayBuffer = useCallback((file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(new Error('Failed to read file'));
            reader.readAsArrayBuffer(file);
        });
    }, []);

    useEffect(() => {
        const initialFiles = parseValue(value);
        if (initialFiles.length > 0) {
            setUploadedFiles(prevFiles => {
                const allFiles = [...prevFiles, ...initialFiles];
                return Array.from(new Map(allFiles.map(file => [file.key, file])).values());
            });
        }
    }, [value]);

    return (
        <div className="space-y-4">
            <FilePondUploader
                pond={pond}
                setPond={setPond}
                allowMultiple={allowMultiple}
                maxFiles={allowMultiple ? maxFiles : 1}
                acceptedFileTypes={acceptedFileTypes}
                fileTypeDescription={fileTypeDescription}
                maxFileSize={formatFileSize(maxFileSize)}
                disabled={shouldDisableUpload()}
                handleProcessFile={handleProcessFile}
                handleLoadFile={handleLoadFile}
                uploadProgress={uploadProgress}
                onCancelUpload={handleCancelUpload}
                uploadStatus={uploadStatus}
                error={error}
                labelFileTypeNotAllowed={`Only ${fileTypeDescription} are allowed`}
                labelMaxFileSizeExceeded={`File is too large. Maximum size is ${maxFileSize}MB`}
                labelIdle={`Drag & Drop your files or <span class="filepond--label-action">Browse</span><br/>
                    <span class="text-sm text-gray-500">Supported files: ${fileTypeDescription}</span>`}
            />

            <AttachmentsList
                files={uploadedFiles}
                onPreview={handlePreview}
                onRemove={handleRemove}
                loadingPreview={loadingPreview}
                uploadStatus={uploadStatus}
            />
        </div>
    );
};

export default FileUpload;