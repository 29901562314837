import React, { useState, useEffect } from 'react';
import { Form, TimePicker } from "antd";
import validator from 'validator';
import moment from 'moment';
import { SchemaFieldTitle } from 'components';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';

const TIME_FORMAT = "HH:mm:ss";

const getValidTimeValue = (value) => {
    let timeValue = value && moment(value, TIME_FORMAT);
    if (timeValue && timeValue.isValid() === false) {
        timeValue = null;
    }
    return timeValue;
}

const TimeInput = ({ value, onChange, format, disabled }) => {
    const [timeValue, setTimeValue] = useState(getValidTimeValue(value));

    useEffect(() => {
        setTimeValue(getValidTimeValue(value));
    }, [value]);

    const onTimeValueChange = (time, timeString) => {
        setTimeValue(time);
        onChange(time && time.format(TIME_FORMAT));
    }

    return <TimePicker value={timeValue} format={format} onChange={onTimeValueChange} disabled={disabled} />
}

function FormTimeField({ fieldErrors, fieldId, name, description, max, min, isRequired, timeFormat,  disabled = false, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
        }
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim()) {
                        let time = moment(value, TIME_FORMAT);
                        if (time.isValid() === false) {
                            return Promise.reject(`Value should be a valid time in format ${timeFormat}`);
                        }

                        if (min) {
                            let minTime = moment(moment.utc(min).format(timeFormat), timeFormat);
                            if (time.isBefore(minTime)) {
                                return Promise.reject(`Value should be greater than or equal to ${minTime.format(timeFormat)}`);
                            }
                        }

                        if (max) {
                            let maxTime = moment(moment.utc(max).format(timeFormat), timeFormat);
                            if (time.isAfter(maxTime)) {
                                return Promise.reject(`Value should be less than or equal to ${maxTime.format(timeFormat)}`);
                            }
                        }
                    }

                    return Promise.resolve();
                },
            },
        ]}
        {...fieldErrors}>
        <TimeInput format={timeFormat} disabled={disabled} />
    </Form.Item>
}

export default FormTimeField