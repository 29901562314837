import React, { useState, useEffect } from 'react';
import { Form, Radio } from "antd";
import { SchemaFieldTitle } from 'components';
import { LockOutlined } from '@ant-design/icons';
import Tooltip from '../Tooltip';

const BooleanInput = ({ value, onChange, trueValue, falseValue, disabled  }) => {
    const [booleanValue, setBooleanValue] = useState(value || trueValue);

    useEffect(() => {
        setBooleanValue(value);
    }, [value]);

    const onBooleanValueChange = (e) => {
        setBooleanValue(e.target.value);
        onChange(e.target.value);
    }

    return <Radio.Group onChange={onBooleanValueChange} value={booleanValue} disabled={disabled}>
        <Radio value={trueValue}>{trueValue}</Radio>
        <Radio value={falseValue}>{falseValue}</Radio>
    </Radio.Group>
}

function FormBooleanField({ fieldErrors, fieldId, name, description, max, min, isRequired, allowedBooleanValues, disabled, ...field }) {
    const booleanValues = (allowedBooleanValues && allowedBooleanValues.split('/')) || ['y', 'n'];

    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
        }
        tooltip={description}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value !== null && value !== undefined && value.toString().trim()) {
                        if (booleanValues.includes(value) === false) {
                            return Promise.reject(`Value should be a valid boolean value ${allowedBooleanValues}`);
                        }
                    }

                    return Promise.resolve();
                },
            }
        ]}
        {...fieldErrors}>
        <BooleanInput trueValue={booleanValues[0] || 'y'} falseValue={booleanValues[1] || 'n'}  disabled={disabled} /> 
    </Form.Item>
}

export default FormBooleanField