import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { JdbcTableList, LoadingOverlay, ProhibitedArea } from "components";
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import flatten from 'flat';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Row, Col } from "antd";

class SystemConnectionJdbcTables extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busyMessage: "",
            fetchingTables: false,
            permissionDenied: false,
        }
    }

    componentWillMount() {
        this.setState({
            fetchingTables: true
        });
        this.props.getJdbcConnectionTables(this.props.connectionId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetJdbcConnectionTablesResultUpdate(prevProps);
    }

    onGetJdbcConnectionTablesResultUpdate = (prevProps) => {
        if (this.props.getJdbcConnectionTablesResult && this.props.getJdbcConnectionTablesResult !== prevProps.getJdbcConnectionTablesResult) {
            if (!this.props.getJdbcConnectionTablesResult.success) {
                if (this.props.getJdbcConnectionTablesResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not get JDBC connection tables at this moment.");
                }
            }
            this.setState({
                fetchingTables: false
            });
        }
    }

    render() {
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.state.fetchingTables}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    <JdbcTableList
                        tables={this.props.jdbcConnectionTables}
                        onTableSelected={this.props.onTableSelected}>
                    </JdbcTableList>
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getJdbcConnectionTablesResult: state.systemConnectionJdbc.getJdbcConnectionTablesResult,
        jdbcConnectionTables: state.systemConnectionJdbc.jdbcConnectionTables
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJdbcConnectionTables: (connectionId) => dispatch(actions.getJdbcConnectionTablesRequest(connectionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemConnectionJdbcTables));