import {
    GET_JDBC_CONNECTIONS_SUCCESS,
    GET_JDBC_CONNECTIONS_FAILURE,

    CREATE_JDBC_CONNECTION_SUCCESS,
    CREATE_JDBC_CONNECTION_FAILURE,

    GET_JDBC_CONNECTION_SUCCESS,
    GET_JDBC_CONNECTION_FAILURE,

    UPDATE_JDBC_CONNECTION_SUCCESS,
    UPDATE_JDBC_CONNECTION_FAILURE,

    DELETE_JDBC_CONNECTION_SUCCESS,
    DELETE_JDBC_CONNECTION_FAILURE,

    GET_JDBC_CONNECTION_TABLES_SUCCESS,
    GET_JDBC_CONNECTION_TABLES_FAILURE,
} from "./actions";

const initialState = {
    getConnectionsResult: {
        success: true
    },
    connectionListResult: {
        Items: []
    },
    createConnectionResult: {
        success: true
    },
    createdConnection: null,
    getConnectionResult: {
        success: true
    },
    connection: null,
    updateConnectionResult: {
        success: true
    },
    deleteConnectionResult: {
        success: true
    },
    getJdbcConnectionTablesResult: {
        success: true
    },
    jdbcConnectionTables: [],
};

export default (state = initialState, action) => {
    let connectionListResult = {
        Items: []
    };
    let connectionIndex = -1;
    switch (action.type) {
        case GET_JDBC_CONNECTIONS_SUCCESS:
            return { ...state, getConnectionsResult: { success: true }, connectionListResult: action.payload.result };
        case GET_JDBC_CONNECTIONS_FAILURE:
            return { ...state, getConnectionsResult: { success: false, ...action.payload.error }, connectionListResult: { Items: [] } };
        case CREATE_JDBC_CONNECTION_SUCCESS:
            return { ...state, createConnectionResult: { success: true }, createdConnection: action.payload.connection };
        case CREATE_JDBC_CONNECTION_FAILURE:
            return { ...state, createConnectionResult: { success: false, ...action.payload.error }, createdConnection: null };
        case GET_JDBC_CONNECTION_SUCCESS:
            return { ...state, getConnectionResult: { success: true }, connection: action.payload.connection };
        case GET_JDBC_CONNECTION_FAILURE:
            return { ...state, getConnectionResult: { success: false, ...action.payload.error }, connection: null };
        case UPDATE_JDBC_CONNECTION_SUCCESS:
            connectionListResult = state.connectionListResult;
            if (action.payload.connection) {
                connectionIndex = connectionListResult.Items.findIndex(item => item.connectionId === action.payload.connection.connectionId);
                if (connectionIndex > -1) {
                    connectionListResult = { ...connectionListResult };
                    connectionListResult.Items = [...connectionListResult.Items];
                    connectionListResult.Items[connectionIndex] = action.payload.connection;
                }
            }
            return { ...state, updateConnectionResult: { success: true }, connectionListResult };
        case UPDATE_JDBC_CONNECTION_FAILURE:
            return { ...state, updateConnectionResult: { success: false, ...action.payload.error } };
        case DELETE_JDBC_CONNECTION_SUCCESS:
            connectionListResult = { ...state.connectionListResult };
            connectionListResult.Items = state.connectionListResult.Items.filter(item => item.connectionId !== action.payload.connectionId);
            return { ...state, deleteConnectionResult: { success: true }, connectionListResult };
        case DELETE_JDBC_CONNECTION_FAILURE:
            return { ...state, deleteConnectionResult: { success: false, ...action.payload.error } };
        case GET_JDBC_CONNECTION_TABLES_SUCCESS:
            return { ...state, getJdbcConnectionTablesResult: { success: true }, jdbcConnectionTables: action.payload.result };
        case GET_JDBC_CONNECTION_TABLES_FAILURE:
            return { ...state, getJdbcConnectionTablesResult: { success: false, ...action.payload.error }, jdbcConnectionTables: [] };
        default:
            return state;
    }
};
