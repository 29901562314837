import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { Form, Empty, Button, Row, Col, Space } from "antd";
import { Header } from 'semantic-ui-react';
import _ from 'lodash';
import {
    FormField,
    FullHeightContainerLayout,
    OkCancelConfirmationBox
} from 'components';

import {
    DataObjectRequestVerification
} from 'containers';
import DataObjectUnobfuscation from "containers/DataObjectUnobfuscation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserPIIPermissionRequest } from "store/pii/actions";

/** @type {import('./types').BasicFormBuilder} */
const BasicFormBuilder = forwardRef((
    {
        formDataObject,
        readOnly,
        schemaFields,
        onCreate,
        onUpdate,
        onCancel,
        onValidationErrors,
        showHeader = true,
        requestVerification = false,
        showRevealButton = false,
        showFooter = true,
        maskPIIFields = false,
        fromhyperlink
    }, ref) => {

    const [formDataObjectErrors, setFormDataObjectErrors] = useState({});
    const [showFormSaveWarning, setShowFormSaveWarning] = useState(false);
    const [showEmptyFormWarning, setShowEmptyFormWarning] = useState(false);
    const [updatedDataObject, setUpdatedDataObject] = useState(null);
    const [form] = Form.useForm();
    const [canRevealPII, setCanRevealPII] = useState(false); 
    const dispatch = useDispatch();

    const hasPIIFields = schemaFields.some(field => field.isPII);

    const piiPermission = useSelector((state) => state.pii?.permission);

useEffect(() => {
    if (piiPermission?.canView) {
        setCanRevealPII(true);
    } else {
        setCanRevealPII(false);
    }
}, [piiPermission]);

    useEffect(() => {
        dispatch(getUserPIIPermissionRequest());
    }, [dispatch]);
    


    useImperativeHandle(ref, () => ({
        async submit() {
            await submitForm();
        },
    }));

   

    useEffect(() => {
        let formDataObjectErrorDict = {};
        if (formDataObject && formDataObject.errors && formDataObject.errors.length > 0) {
            for (let error of formDataObject.errors) {
                formDataObjectErrorDict[error.name] = {
                    help: error.errors,
                    validateStatus: "error"
                }
            }
        }
        setFormDataObjectErrors(formDataObjectErrorDict);
    }, [formDataObject]);

    useEffect(() => {
        (async () => {
            if (form && formDataObject && formDataObject.dataObjectId) {
                try {
                    let result = await form.validateFields();
                }
                catch (error) {
                    getFormErrors(error);
                }
            }
        })();
    }, [form]);

    const getFormErrors = (error) => {
        let validationErrors = [];
        let formDataObjectErrorsCopy = { ...formDataObjectErrors };
        if (error && error.errorFields && error.errorFields.length > 0) {
            for (let errorField of error.errorFields) {
                formDataObjectErrorsCopy[errorField.name] = {
                    help: errorField.errors.join("\n"),
                    validateStatus: "error"
                }
            }
        }
        for (let formDataObjectErrorKey in formDataObjectErrorsCopy) {
            validationErrors.push({
                name: formDataObjectErrorKey,
                errors: formDataObjectErrorsCopy[formDataObjectErrorKey].help
            })
        }
        setFormDataObjectErrors(formDataObjectErrorsCopy);
        onValidationErrors && onValidationErrors(formDataObject, validationErrors);
        return validationErrors;
    }

    const onFormValuesChanged = (changedValues, allValues) => {
        if (changedValues && _.isObject(changedValues)) {
            let errorObject = { ...formDataObjectErrors };
            for (let key in changedValues) {
                delete errorObject[key];
            }
            setFormDataObjectErrors(errorObject);
        }
    }

    const onWarningMessageCancel = () => {
        setShowFormSaveWarning(false);
        setUpdatedDataObject(null);
    }

    const onWarningMessageConfirmed = () => {
        if (updatedDataObject) {
            if (!formDataObject.dataObjectId) {
                onCreate(updatedDataObject);
            }
            else {
                onUpdate(formDataObject.dataObjectId, updatedDataObject);
            }
        }
        onWarningMessageCancel();
    }

    const isEmptyFormDataObject = (formData) => {
        let values = [];
        for (let key in formData) {
            if (key !== "errors") {
                values.push(formData[key]);
            }
        }
        let nonEmptyDataObject = _.some(values, value => value !== null && value !== undefined && value.toString().trim() !== "");
        return !nonEmptyDataObject;
    }

    const handleUnobfuscationSave = (updatedFields) => {
        const changedFields = {};

        Object.keys(updatedFields).forEach((key) => {
            if (!_.isEqual(updatedFields[key], formDataObject[key])) {
                changedFields[key] = updatedFields[key];
            }
        });

        const newFormData = { ...formDataObject, ...changedFields };

        setUpdatedDataObject(changedFields);
        if (formDataObject.dataObjectId) {
            onUpdate(formDataObject.dataObjectId,{ ...changedFields, version: formDataObject.version});
        }

        form.setFieldsValue(newFormData);
    };

    const submitForm = async () => {
        if (readOnly) {
            return;
        }

        let formData = null;
        let formError = null;
        let validationErrors = [];

        try {
            formData = await form.validateFields();
        }
        catch (error) {
            formData = error.values;
            formError = error;
        }
        if (formData) {
            if (isEmptyFormDataObject(formData)) {
                setShowEmptyFormWarning(true);
                return;
            }
            validationErrors = getFormErrors(formError);

            const changedFields = {};
            Object.keys(formData).forEach((key) => {
                if (!_.isEqual(formData[key], formDataObject[key])) {
                    changedFields[key] = formData[key];
                }
            });

            if (validationErrors.length > 0) {
                setShowFormSaveWarning(true);
                setUpdatedDataObject({ ...changedFields, version: formDataObject.version, errors: validationErrors });
            }
            else {
                if (!formDataObject.dataObjectId) {
                    onCreate({ ...changedFields, errors: validationErrors });
                }
                else {
                    onUpdate(formDataObject.dataObjectId, { ...changedFields, version: formDataObject.version, errors: validationErrors });
                }
            }
        }
    }

    if (!formDataObject) {
        return <>
            {showHeader && <Header as='h5' dividing >Dataset Record</Header>}
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Dataset record selected" />
        </>;
    }

    return (
        <>
            <OkCancelConfirmationBox
                show={showFormSaveWarning}
                message="Do you still want to save this record with warnings?"
                onCancel={onWarningMessageCancel}
                onOk={onWarningMessageConfirmed}>
            </OkCancelConfirmationBox>
            <OkCancelConfirmationBox
                show={showEmptyFormWarning}
                message="Empty form cannot be saved."
                onOk={() => setShowEmptyFormWarning(false)}>
            </OkCancelConfirmationBox>
            <FullHeightContainerLayout
                showHeader={showHeader}
                header={<Header as='h5' dividing style={{marginBottom: 12}}>{readOnly ? "Display Dataset Record" : (formDataObject && formDataObject.dataObjectId ? "Update Dataset Record" : "Add Dataset Record")}</Header>}
                content={
                    <Form
                        form={form}
                        initialValues={formDataObject}
                        name="basic"
                        layout="vertical"
                        className="basic-form-builder"
                        onValuesChange={onFormValuesChanged}>
                        {
                            schemaFields && schemaFields.map(field =>
                                <FormField 
                                    fieldErrors={formDataObjectErrors[field.fieldId] || {}} key={field.fieldId} {...field} disabled={field.isPII}
                                >
                                </FormField>
                            )
                            
                        }
                    </Form>
                }
                showFooter={showFooter && !readOnly}
                footer={
                    <Row style={{ marginTop: "1rem" }}>
                        <Col span={24} className="footer-right-column">
                            <Space>
                                {showRevealButton && hasPIIFields && canRevealPII &&                                   
                                <DataObjectUnobfuscation
                                    schemaFields={schemaFields}
                                    selectedDataObject={formDataObject}
                                    onSave={handleUnobfuscationSave}
                                />
                               }
                            <Button onClick={() => onCancel()}>{fromhyperlink?"Close":"Cancel"}</Button>
                                <Button type="primary" onClick={submitForm}>{"Save"}</Button>
                                {
                                    requestVerification &&
                                    formDataObject.dataObjectId &&
                                    <DataObjectRequestVerification
                                        key={formDataObject.dataObjectId}
                                        dataObject={formDataObject}
                                        schemaFields={schemaFields}>
                                    </DataObjectRequestVerification>
                                }
                            </Space>
                        </Col>
                    </Row>
                }>
            </FullHeightContainerLayout>
        </>
    );
});

export default BasicFormBuilder;