import React, { Component } from 'react';
import { connect } from "react-redux";
import LABELS from 'constants/labels';
import { Row, Col } from 'antd';
import actions from 'store/actions';
import { SchemaModelList, ManageSchemaModel, OkCancelConfirmationBox, LoadingOverlay, ProhibitedArea, SchemaExport, FullHeightContainerLayout } from "components";
import { SchemaModelAudit, SchemaModelCreateAutomation, SchemaModelLineage } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import flatten from 'flat';

class SchemaModels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingUserBusinessArea: true,
            fetchingSchemaModels: true,
            fetchingSchemaModel: true,
            fetchingPermission: true,
            schemaModelsDataFetched: false,
            busy: true,
            busyMessage: "Getting schema models...",
            invalidBusinessArea: false,
            createSchemaManually: false,
            manageParsedSchemaModelCancelled: true,
            manageSchemaModelErrors: {}
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
        switch (this.props.action && this.props.action.toLowerCase()) {
            case "create":
            case "history":
            case "export":
            case "lineage":
                this.setState({
                    busyMessage: "Please wait..."
                });
                break;
            case "view":
            case "edit":
                this.setState({
                    busyMessage: "Getting schema model..."
                });
                break;
            default:
                this.setState({
                    busyMessage: "Getting schema models..."
                });
        }
        this.props.getUserSchemasPermission();
        this.props.getUserBusinessAreaByName(this.props.businessArea);
        this.setSchemaPermissions(this.props.permission, this.props.userBusinessAreas[this.props.businessArea]);
        if (this.props.userBusinessAreas[this.props.businessArea]) {
            this.fetchSchemaModelsData();
        }
    }

    componentDidMount() {
        switch (this.props.action && this.props.action.toLowerCase()) {
            case "view":
            case "edit":
                this.props.setMainColumnSpan(24);
                break;
            default:
                this.props.setMainColumnSpan(18);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserSchemaModelsPermissionResultChanged(prevProps);
        this.onGetUserBusinessAreaByNameResultChanged(prevProps);
        this.onGetSchemaModelsResultChanged(prevProps);
        this.onGetSchemaModelResultChanged(prevProps);
        this.onCreateSchemaModelResultChanged(prevProps);
        this.onDeleteSchemaModelResultChanged(prevProps);
        this.onUpdateSchemaModelResultChanged(prevProps);
    }

    componentWillUnmount() {
        this.props.onSchemaModelListUnLoaded && this.props.onSchemaModelListUnLoaded();
    }

    onGetUserSchemaModelsPermissionResultChanged(prevProps) {
        if (this.props.getUserSchemaModelsPermissionResult && this.props.getUserSchemaModelsPermissionResult !== prevProps.getUserSchemaModelsPermissionResult) {
            if (!this.props.getUserSchemaModelsPermissionResult.success) {
                this.setState({
                    permissionDenied: true,
                    fetchingPermission: false
                });
            }
            else {
                this.setState({
                    fetchingPermission: false
                });
                this.setSchemaPermissions(this.props.permission, this.props.userBusinessAreas[this.props.businessArea]);
            }
        }
    }

    onGetUserBusinessAreaByNameResultChanged(prevProps) {
        if (this.props.getUserBusinessAreaByNameResult && this.props.getUserBusinessAreaByNameResult !== prevProps.getUserBusinessAreaByNameResult) {
            if (!this.props.getUserBusinessAreaByNameResult.success) {
                this.setState({
                    invalidBusinessArea: true,
                    busy: false,
                    fetchingUserBusinessArea: false,
                    fetchingSchemaModels: false
                });
            }
            else {
                this.setState({
                    fetchingUserBusinessArea: false
                });
                this.setSchemaPermissions(this.props.permission, this.props.userBusinessAreas[this.props.businessArea]);
                if (this.props.userBusinessAreas[this.props.businessArea]) {
                    this.fetchSchemaModelsData();
                }
                else {
                    this.setState({
                        invalidBusinessArea: true,
                        fetchingSchemaModels: false,
                        busy: false
                    });
                }
            }
        }
    }

    onGetSchemaModelsResultChanged(prevProps) {
        if (this.props.getSchemaModelsResult && this.props.getSchemaModelsResult !== prevProps.getSchemaModelsResult) {
            if (!this.props.getSchemaModelsResult.success) {
                if (this.props.getSchemaModelsResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema models at this moment.");
                }
            }
            else {
                if (this.props.onSchemaModelListLoaded) {
                    this.props.onSchemaModelListLoaded();
                }
                let selectedSchemaModel = this.getSelectedSchemaModelFromHash();
                if (selectedSchemaModel) {
                    this.onSchemaModelSelected(selectedSchemaModel);
                }
            }
            this.setState({
                busy: false,
                fetchingSchemaModels: false
            });
        }
    }

    onGetSchemaModelResultChanged(prevProps) {
        if (this.props.getSchemaModelResult && this.props.getSchemaModelResult !== prevProps.getSchemaModelResult) {
            if (!this.props.getSchemaModelResult.success) {
                if (this.props.getSchemaModelResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get schema model.");
                }
            }
            else if (this.props.action === "history") {
                this.setState({
                    showHistory: true
                });
            }
            else if (this.props.action === "export") {
                this.setState({
                    showExport: true
                });
            }
            else if (this.props.action === "lineage") {
                this.setState({
                    showLineage: true
                });
            }
            this.setState({
                busy: false,
                fetchingSchemaModel: false
            });
            if (!this.props.schemaModelListResult || !this.props.schemaModelListResult.Items || this.props.schemaModelListResult.Items.length === 0) {
                this.showBreadCrumbNavigationItems();
            }
        }
    }

    onCreateSchemaModelResultChanged(prevProps) {
        if (this.props.createSchemaModelResult && this.props.createSchemaModelResult !== prevProps.createSchemaModelResult) {
            if (!this.props.createSchemaModelResult.success) {
                showError((this.props.createSchemaModelResult.fields && "Please fix the errors in the form") || this.props.createSchemaModelResult.message || "Schema model cannot be created.");
                this.setState({
                    busy: false,
                    manageSchemaModelErrors: this.props.createSchemaModelResult.fields,
                    afterSaveCallback: null
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Schema model created. Please wait..."
                });
                if (this.state.afterSaveCallback) {
                    this.state.afterSaveCallback();
                    this.setState({
                        afterSaveCallback: null
                    });
                }
                else {
                    showSuccess("Schema model created successfully.", () => {
                        if (this.state.goToEditSchema || this.state.parsedSchemaDefinition) {
                            this.showEditSchemaModel(this.props.createdSchemaModel.schemaId);
                        }
                        else {
                            this.cancelManageSchemaModel();
                        }
                    });
                }
            }
        }
    }

    onDeleteSchemaModelResultChanged(prevProps) {
        if (this.props.deleteSchemaModelResult && this.props.deleteSchemaModelResult !== prevProps.deleteSchemaModelResult) {
            if (!this.props.deleteSchemaModelResult.success) {
                showError(this.props.deleteSchemaModelResult.message || "Schema model cannot be deleted.");
            }
            else {
                showSuccess("Schema model deleted successfully.");
            }
            this.setState({
                busy: false
            });
        }
    }

    onUpdateSchemaModelResultChanged(prevProps) {
        if (this.props.updateSchemaModelResult && this.props.updateSchemaModelResult !== prevProps.updateSchemaModelResult) {
            if (!this.props.updateSchemaModelResult.success) {
                // console.log("updateSchemaModelResult", this.props.updateSchemaModelResult);
                showError((this.props.updateSchemaModelResult.fields && "Please fix the errors in the form") || this.props.updateSchemaModelResult.message || "Schema model cannot be updated.");
                this.setState({
                    busy: false,
                    manageSchemaModelErrors: this.props.updateSchemaModelResult.fields,
                    afterSaveCallback: null
                });
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Schema model updated. Please wait..."
                });
                if (this.state.afterSaveCallback) {
                    this.state.afterSaveCallback();
                    this.setState({
                        afterSaveCallback: null
                    });
                }
                else {
                    showSuccess("Schema model updated successfully.", () => {
                        this.cancelManageSchemaModel();
                    });
                }
            }
        }
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadCrumbItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
                        .replace(":businessarea", this.props.businessArea.toLowerCase()),
                    text: "Schemas"
                }
            ];
            if (this.props.action) {
                switch (this.props.action.toLowerCase()) {
                    case "create":
                        breadCrumbItems.push({
                            route: "",
                            text: "New Schema"
                        });
                        break;
                    case "view":
                    case "edit":
                        breadCrumbItems.push(...[{
                            route: "",
                            text: this.getSchemaModelName()
                        }]);
                        break;
                    case "history":
                        breadCrumbItems.push(...[{
                            route: "",
                            text: this.getSchemaModelName()
                        },
                        {
                            route: "",
                            text: "History"
                        }]);
                        break;
                    case "export":
                        breadCrumbItems.push(...[{
                            route: this.props.prevAction ? AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
                                .replace(":tenant", this.props.tenant.toLowerCase())
                                .replace(":businessarea", this.props.businessArea.toLowerCase())
                                .replace(":schemaId", this.props.schemaId)
                                .replace(":schemaAction", this.props.prevAction) : "",
                            text: this.getSchemaModelName()
                        },
                        {
                            route: "",
                            text: "export"
                        }]);
                        break;
                    case "lineage":
                        breadCrumbItems.push(...[{
                            route: "",
                            text: this.getSchemaModelName()
                        },
                        {
                            route: "",
                            text: "Relationships"
                        }]);
                        break;
                }
            }

            this.props.setBreadcrumbNavigationItems(breadCrumbItems);
        }
    }

    getSchemaModelName = () => {
        let schemaName = "";
        if (this.props.schemaModel && this.props.schemaModel.schemaId === this.props.schemaId) {
            schemaName = this.props.schemaModel.name;
        }
        if (!schemaName && this.props.schemaModelListResult && this.props.schemaModelListResult.Items && this.props.schemaModelListResult.Items.length > 0) {
            let schemaModel = this.props.schemaModelListResult.Items.find(schemModel => schemModel.schemaId === this.props.schemaId);
            if (schemaModel) {
                schemaName = schemaModel.name;
            }
        }
        if (!schemaName && this.props.recentSchemaModelListResult && this.props.recentSchemaModelListResult.Items && this.props.recentSchemaModelListResult.Items.length > 0) {
            let schemaModel = this.props.recentSchemaModelListResult.Items.find(schemModel => schemModel.schemaId === this.props.schemaId);
            if (schemaModel) {
                schemaName = schemaModel.name;
            }
        }
        if (!schemaName) {
            schemaName = this.props.schemaId;
        }
        return schemaName;
    }

    setSchemaPermissions = (schemaPermission, userBusinessArea) => {
        let permission = { canAdd: false, canView: false, canEdit: false, canDelete: false };
        if (schemaPermission && userBusinessArea && userBusinessArea.user) {
            permission = {
                canAdd: schemaPermission.canAdd && userBusinessArea.user.canAdd,
                canView: schemaPermission.canView && userBusinessArea.user.canView,
                canEdit: schemaPermission.canEdit && userBusinessArea.user.canEdit,
                canDelete: schemaPermission.canDelete && userBusinessArea.user.canDelete,
            };
        }
        this.setState({
            permission
        });
    }

    fetchSchemaModelsData = () => {
        if (this.state.schemaModelsDataFetched === false) {
            if (this.props.action) {
                this.props.getBusinessAreaDataForBusinessArea(this.props.userBusinessAreas[this.props.businessArea].businessAreaId);
            }

            if (!this.props.action) {
                this.props.getSchemaModels(this.props.userBusinessAreas[this.props.businessArea].businessAreaId);
            }
            else {
                this.props.getSchemaModel(this.props.userBusinessAreas[this.props.businessArea].businessAreaId, this.props.schemaId);
                if (this.props.action === "view" || this.props.action === "edit") {
                    this.setState({
                        busy: true,
                        busyMessage: "Getting schema model..."
                    });
                }
                else if (this.props.action === "history") {
                    this.setState({
                        busy: true,
                        busyMessage: "Please wait...",
                        showHistory: false
                    });
                }
                else if (this.props.action === "export") {
                    this.setState({
                        busy: true,
                        busyMessage: "Please wait...",
                        showExport: false
                    });
                }
                else if (this.props.action === "lineage") {
                    this.setState({
                        busy: true,
                        busyMessage: "Please wait...",
                        showLineage: false
                    });
                }
                else {
                    this.setState({
                        busy: false
                    });
                }
            }
            this.setState({
                schemaModelsDataFetched: true
            })
        }
    }

    onSchemaModelSelected = (schemaModel) => {
        this.props.onSchemaModelSelected({ schemaModel, userBusinessArea: this.props.userBusinessAreas[this.props.businessArea] });
    }

    showCreateSchemaModel = () => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schemaAction", "create"));
    }

    showViewSchemaModel = (schemaId) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schemaId", schemaId)
            .replace(":schemaAction", "view"));
    }

    showEditSchemaModel = (schemaId) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schemaId", schemaId)
            .replace(":schemaAction", "edit"));
    }

    showSchemaModelHistory = (schemaId) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schemaId", schemaId)
            .replace(":schemaAction", "history"));
    }

    showExportSchemaModel = (schemaId) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schemaId", schemaId)
            .replace(":schemaAction", "export"));
    }

    showSchemaModelLineage = (schemaId) => {
        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA_ID_ACTION
            .replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase())
            .replace(":schemaId", schemaId)
            .replace(":schemaAction", "lineage"));
    }

    cancelManageSchemaModel = () => {
        if (this.state.parsedSchemaDefinition) {
            this.setState({
                parsedSchemaDefinition: null,
                manageParsedSchemaModelCancelled: true,
                scrollManageParsedSchemeDefinition: false
            });
            this.props.setMainColumnSpan(18);
            return
        }

        this.props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
            .replace(":businessarea", this.props.businessArea.toLowerCase()));
    }

    cancelExportSchemaModel = () => {
        this.props.history.goBack();
    }

    createSchemaModel = (schemaModel, afterSaveCallback) => {
        schemaModel.active = true;
        this.setState({
            busy: true,
            busyMessage: "Creating schema model...",
            afterSaveCallback
        });
        this.props.createSchemModel(this.props.userBusinessAreas[this.props.businessArea].businessAreaId, schemaModel);
    }

    onAutomatedCreateSchemaModel = (schemaModel) => {
        schemaModel.active = true;
        this.setState({
            busy: true,
            busyMessage: "Creating schema model...",
            goToEditSchema: true
        });
        this.props.createSchemModel(this.props.userBusinessAreas[this.props.businessArea].businessAreaId, schemaModel);
    }

    onUploadSchemaDefinitionFile = (dataFile) => {
        this.props.uploadSchemaModelDefinition(this.props.userBusinessAreas[this.props.businessArea].businessAreaId, dataFile);
    }

    updateSchemaModel = (schemaId, updateSchemaModelData, fieldIndexMappings, afterSaveCallback) => {
        this.setState({
            busy: true,
            busyMessage: "Updating schema model...",
            afterSaveCallback
        });
        this.props.updateSchemaModel(this.props.userBusinessAreas[this.props.businessArea].businessAreaId, schemaId, updateSchemaModelData, fieldIndexMappings);
    }

    showDeleteSchemaModelConfirmation = (schemaModel) => {
        this.setState({
            deletedSchemaModel: schemaModel,
            showSchemaModelDeleteConfirmation: true,
            deleteSchemaModelConfirmMessage: `Are you sure, you want to delete '${schemaModel.name}' schema model?`
        })
    }

    onDeleteSchemaModelConfirmed = () => {
        this.setState({
            busy: true,
            busyMessage: "Deleting schema model..."
        });
        this.props.deleteSchemaModel(this.state.deletedSchemaModel.businessAreaId, this.state.deletedSchemaModel.schemaId);
        this.onDeleteSchemaModelCancel();
    }

    onDeleteSchemaModelCancel = () => {
        this.setState({
            deletedSchemaModel: null,
            showSchemaModelDeleteConfirmation: false,
            deleteSchemaModelConfirmMessage: ""
        })
    }

    onSchemaModelValuesChanged = (changedValues, allValues) => {
        if (_.isEmpty(this.state.manageSchemaModelErrors) === false) {
            let manageSchemaModelErrors = { ...this.state.manageSchemaModelErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete manageSchemaModelErrors[key];
            }
            this.setState({
                manageSchemaModelErrors
            })
        }
    }

    onSchemaModelsPageSelect = (pagination) => {
        if (this.props.schemaModelListResult.pageKey
            && (pagination.current * pagination.pageSize) >= this.props.schemaModelListResult.Items.length) {
            this.props.getSchemaModelsNextPageRequest(
                this.props.userBusinessAreas[this.props.businessArea].businessAreaId,
                this.props.schemaModelListResult.pageKey);
        }
    }

    isBusy = () => {
        let isBusy = (this.state.busy || this.state.fetchingUserBusinessArea || this.state.fetchingPermission);
        if (this.props.action && (this.props.action.toLowerCase() === "view" ||
            this.props.action.toLowerCase() === "edit")) {
            isBusy = isBusy || this.state.fetchingSchemaModel;
        }
        return isBusy;
    }

    getSelectedSchemaModelFromHash = () => {
        if (this.props.history.location.hash) {
            let hashValue = decodeURIComponent(this.props.history.location.hash);
            if (this.props.schemaModelListResult.Items && this.props.schemaModelListResult.Items.length > 0) {
                let selectedSchemaName = hashValue.slice(1);
                if (selectedSchemaName) {
                    let selectedSchemaModel = this.props.schemaModelListResult.Items.find(schemaModel => schemaModel.name.toLowerCase() === selectedSchemaName.toLowerCase());
                    if (selectedSchemaModel) {
                        return selectedSchemaModel;
                    }
                }
            }
        }
        return null;
    }

    manuallyCreateSchema = () => {
        this.props.setMainColumnSpan(24);
        this.setState({
            createSchemaManually: true
        })
    }

    onManageParsedSchemaModel = (schemaName, schemaFields) => {
        if (schemaFields) {
            this.setState({
                parsedSchemaDefinition: {
                    name: schemaName,
                    description: schemaName,
                    fields: schemaFields
                },
                manageParsedSchemaModelCancelled: false
            },
                () => {
                    this.setState({
                        scrollManageParsedSchemeDefinition: true
                    });
                });
            this.props.setMainColumnSpan(24);
        }
    }

    onCancelManageParsedSchemaModel = () => {
        this.setState({
            parsedSchemaDefinition: null,
            manageParsedSchemaModelCancelled: true,
            scrollManageParsedSchemeDefinition: false
        });
        this.props.setMainColumnSpan(18);
    }


    getSchemaComponent = () => {
        if (!this.props.action) {
            return <>
                <OkCancelConfirmationBox
                    show={this.state.showSchemaModelDeleteConfirmation}
                    message={this.state.deleteSchemaModelConfirmMessage}
                    onCancel={this.onDeleteSchemaModelCancel}
                    onOk={this.onDeleteSchemaModelConfirmed}>
                </OkCancelConfirmationBox>
                {!this.state.fetchingSchemaModels
                    ?
                    <SchemaModelList
                        schemaModelList={this.props.schemaModelListResult.Items}
                        onCreateNewSchemaModel={this.showCreateSchemaModel}
                        onDeleteSchemaModel={this.showDeleteSchemaModelConfirmation}
                        onViewSchemaModel={this.showViewSchemaModel}
                        onEditSchemaModel={this.showEditSchemaModel}
                        permission={this.state.permission}
                        onPageSelect={this.onSchemaModelsPageSelect}
                        onViewSchemaModelHistory={this.showSchemaModelHistory}
                        onViewSchemaModelLineage={this.showSchemaModelLineage}
                        onSchemaModelSelected={this.onSchemaModelSelected}
                        selectedSchemaModel={this.getSelectedSchemaModelFromHash()}>
                    </SchemaModelList>
                    :
                    <></>}
            </>
        }
        switch (this.props.action.toLowerCase()) {
            case "create":
                return (
                    this.state.createSchemaManually
                        ?
                        <ManageSchemaModel
                            action={this.props.action}
                            formErrors={this.state.manageSchemaModelErrors}
                            onValuesChanged={this.onSchemaModelValuesChanged}
                            onCancel={this.cancelManageSchemaModel}
                            createSchemaModel={this.createSchemaModel}
                            permission={this.state.permission}
                            getBusinessAreaDataForBusinessAreaResult={this.props.getBusinessAreaDataForBusinessAreaResult}
                            businessAreaDataList={this.props.businessAreaDataList}></ManageSchemaModel>
                        :
                        <FullHeightContainerLayout
                            showHeader={false}
                            showFooter={false}
                            content={
                                <>
                                    <FullHeightContainerLayout
                                        showHeader={false}
                                        showFooter={false}
                                        content={
                                            <SchemaModelCreateAutomation
                                                tenant={this.props.tenant}
                                                businessArea={this.props.businessArea}
                                                onManuallyCreateSchema={this.manuallyCreateSchema}
                                                onCreateSchemaModel={this.onAutomatedCreateSchemaModel}
                                                onUploadSchemaDefinitionFile={this.onUploadSchemaDefinitionFile}
                                                uploadSchemaModelDefinitionResult={this.props.uploadSchemaModelDefinitionResult}
                                                schemaModelDefinitionFile={this.props.schemaModelDefinitionFile}
                                                getParsedSchemaDefinition={this.props.getParsedSchemaDefinition}
                                                getParsedSchemaDefinitionResult={this.props.getParsedSchemaDefinitionResult}
                                                schemaDefinition={this.props.schemaDefinition}
                                                onManageParsedSchemaModel={this.onManageParsedSchemaModel}
                                                onCancelManageParsedSchemaModel={this.onCancelManageParsedSchemaModel}
                                                manageParsedSchemaModelCancelled={this.state.manageParsedSchemaModelCancelled}>
                                            </SchemaModelCreateAutomation>
                                        }>
                                    </FullHeightContainerLayout>
                                    {
                                        this.state.parsedSchemaDefinition
                                            ?
                                            <FullHeightContainerLayout
                                                scrollContentToBottom={this.state.scrollManageParsedSchemeDefinition}
                                                showHeader={false}
                                                showFooter={false}
                                                contentStyle={{ paddingTop: "1rem" }}
                                                content={
                                                    <ManageSchemaModel
                                                        defaultValue={this.state.parsedSchemaDefinition}
                                                        action={this.props.action}
                                                        formErrors={this.state.manageSchemaModelErrors}
                                                        onValuesChanged={this.onSchemaModelValuesChanged}
                                                        onCancel={this.cancelManageSchemaModel}
                                                        createSchemaModel={this.createSchemaModel}
                                                        permission={this.state.permission}
                                                        getBusinessAreaDataForBusinessAreaResult={this.props.getBusinessAreaDataForBusinessAreaResult}
                                                        businessAreaDataList={this.props.businessAreaDataList}>
                                                    </ManageSchemaModel>
                                                }>
                                            </FullHeightContainerLayout>
                                            :
                                            <></>
                                    }
                                </>
                            }>
                        </FullHeightContainerLayout>

                );
            case "view":
            case "edit":
                return !this.state.fetchingSchemaModel ?
                    (this.props.schemaModel
                        ?
                        <ManageSchemaModel
                            action={this.props.action}
                            schemaModel={this.props.schemaModel}
                            onValuesChanged={this.onSchemaModelValuesChanged}
                            onCancel={this.cancelManageSchemaModel}
                            formErrors={this.state.manageSchemaModelErrors}
                            updateSchemaModel={this.updateSchemaModel}
                            permission={this.state.permission}
                            getBusinessAreaDataForBusinessAreaResult={this.props.getBusinessAreaDataForBusinessAreaResult}
                            businessAreaDataList={this.props.businessAreaDataList}
                            exportSchema={this.showExportSchemaModel}>
                        </ManageSchemaModel>
                        :
                        (this.props.getSchemaModelResult.success
                            ?
                            <Message negative floating size='big'>
                                <Message.Header>Schema Model not found.</Message.Header>
                                <p>The Schema Model you are looking for does not exist.</p>
                            </Message>
                            :
                            <></>
                        )
                    )
                    :
                    <></>;
            case "history":
                return this.state.showHistory ?
                    <SchemaModelAudit schemaModel={this.props.schemaModel}></SchemaModelAudit>
                    :
                    <></>
            case "export":
                return this.state.showExport ?
                    <SchemaExport schemaModel={this.props.schemaModel} cancelExportSchema={this.cancelExportSchemaModel}></SchemaExport>
                    :
                    <></>
            case "lineage":
                return this.state.showLineage ?
                    <SchemaModelLineage
                        schemaModel={this.props.schemaModel}
                        businessArea={this.props.businessArea}
                        tenant={this.props.tenant}></SchemaModelLineage>
                    :
                    <></>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    getPageContainerClasses = () => {
        let classes = ["page-container"];
        if (this.props.displaySize === "half") {
            classes.push("half-container");
        }
        return classes.join(" ");
    }

    render() {
        if (this.state.invalidBusinessArea || this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        return (
            <Row style={{ flexDirection: "column", flexGrow: 1, height: this.props.displaySize === "half" ? "50%" : "100%" }}>
                <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.state.fetchingUserBusinessArea ? this.getSchemaComponent() : <></>}
                </Col>
            </Row>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userBusinessAreas: state.schemaModels.userBusinessAreas,
        getUserBusinessAreaByNameResult: state.schemaModels.getUserBusinessAreaByNameResult,
        schemaModelListResult: state.schemaModels.schemaModelListResult,
        recentSchemaModelListResult: state.schemaModels.recentSchemaModelListResult,
        getSchemaModelsResult: state.schemaModels.getSchemaModelsResult,
        createSchemaModelResult: state.schemaModels.createSchemaModelResult,
        createdSchemaModel: state.schemaModels.createdSchemaModel,
        deleteSchemaModelResult: state.schemaModels.deleteSchemaModelResult,
        getSchemaModelResult: state.schemaModels.getSchemaModelResult,
        schemaModel: state.schemaModels.schemaModel,
        updateSchemaModelResult: state.schemaModels.updateSchemaModelResult,
        getUserSchemaModelsPermissionResult: state.schemaModels.getUserSchemaModelsPermissionResult,
        permission: state.schemaModels.permission,
        getBusinessAreaDataForBusinessAreaResult: state.schemaModels.getBusinessAreaDataForBusinessAreaResult,
        businessAreaDataList: state.schemaModels.businessAreaDataList,
        uploadSchemaModelDefinitionResult: state.schemaModels.uploadSchemaModelDefinitionResult,
        schemaModelDefinitionFile: state.schemaModels.schemaModelDefinitionFile,
        getParsedSchemaDefinitionResult: state.dataParser.getParsedSchemaDefinitionResult,
        schemaDefinition: state.dataParser.schemaDefinition
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreaByName: (businessAreaName) => dispatch(actions.getUserBusinessAreaByNameRequest(businessAreaName)),
        getSchemaModels: (businessAreaId) => dispatch(actions.getSchemaModelsRequest(businessAreaId)),
        getSchemaModelsNextPageRequest: (businessAreaId, pageKey) => dispatch(actions.getSchemaModelsNextPageRequest(businessAreaId, pageKey)),
        createSchemModel: (businessAreaId, schemaModel) => dispatch(actions.createSchemModelRequest(businessAreaId, schemaModel)),
        deleteSchemaModel: (businessAreaId, schemaId) => dispatch(actions.deleteSchemaModelRequest(businessAreaId, schemaId)),
        getSchemaModel: (businessAreaId, schemaId) => dispatch(actions.getSchemaModelRequest(businessAreaId, schemaId)),
        updateSchemaModel: (businessAreaId, schemaId, updateSchemaModelData, fieldIndexMappings) => dispatch(actions.updateSchemaModelRequest(businessAreaId, schemaId, updateSchemaModelData, fieldIndexMappings)),
        getUserSchemasPermission: () => dispatch(actions.getUserSchemaModelsPermissionRequest()),
        getBusinessAreaDataForBusinessArea: (businessAreaId) => dispatch(actions.getBusinessAreaDataForBusinessAreaRequest(businessAreaId)),
        uploadSchemaModelDefinition: (businessAreaId, dataFile) => dispatch(actions.uploadSchemaModelDefinitionRequest(businessAreaId, dataFile)),
        getParsedSchemaDefinition: (parseConfig) => dispatch(actions.getParsedSchemaDefinitionRequest(parseConfig)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SchemaModels));