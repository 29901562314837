import { GET_USER_PII_PERMISSION_SUCCESS, GET_USER_PII_PERMISSION_FAILURE } from "./actions";

const initialState = {
    permission: null, 
    error: null,
};

const piiPermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PII_PERMISSION_SUCCESS:
            return {
                ...state,
                permission: action.payload, 
            };
        case GET_USER_PII_PERMISSION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default piiPermissionReducer;
