import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Row, Col, Button, Space, Tabs, Typography } from 'antd';
import LABELS from 'constants/labels';
import actions from 'store/actions';
import * as EmailValidator from 'email-validator';
import { BusinessAreaUserList, ProhibitedArea, FullHeightContainerLayout, BusinessAreaAppClientList, BusinessAreaSystemConnectionsList } from "components";
import { showError, showSuccess, showInfo } from 'common/ToastNotifications';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import moment from 'moment';
import _ from 'lodash';
import flatten from 'flat';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { TabPane } = Tabs;

class ManageBusinessArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            readOnlyView: this.props.action === "view",
            formErrors: {}
        };
        this.defaultBusinessArea = {
            name: '',
            description: '',
            users: []
        };
        this.emptyObject = {};
        this.formRef = React.createRef();
    }

    componentDidMount() {
        switch (this.props.action) {
            case "view":
            case "edit":
                this.populateBusinessArea();
                break;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.businessArea && prevProps.businessArea !== this.props.businessArea) {
            this.populateBusinessArea();
        }
    }

    populateBusinessArea = () => {
        if (this.props.businessArea && this.formRef.current) {
            switch (this.props.action) {
                case "view":
                case "edit":
                    this.formRef.current.resetFields();
                    this.formRef.current.setFieldsValue(this.props.businessArea)
                    break;
            }
        }
    }

    onFormSubmit = (businessArea) => {
        if (!this.isSaveAllowed()) {
            return;
        }

        if (!this.validateBusinessArea(businessArea)) {
            return;
        }

        if (this.props.action === "edit") {
            let updateBusinessAreaData = {
                businessArea: null,
                newUsers: [],
                deletedUsers: [],
                updatedUsers: [],
                newAppClients: [],
                deletedAppClients: [],
                updatedAppClients: [],
                newSystemConnections:[],
                deletedSystemConnections: [],
                updatedSystemConnections: [],
            };
            let originalBusinessArea = { ...this.props.businessArea };
            let updatedBusinessArea = { ...businessArea };

            let updatedUserData = this.getModifiedBusinessAreaUsers(originalBusinessArea.users, updatedBusinessArea.users);

            let updatedAppClientData = this.getModifiedBusinessAreaAppClients(originalBusinessArea.appClients, updatedBusinessArea.appClients);

            let updatedSystemConnectionsData = this.getModifiedBusinessAreaSystemConnectionss(originalBusinessArea.systemConnections, updatedBusinessArea.systemConnections);

            updateBusinessAreaData = { ...updateBusinessAreaData, ...updatedUserData, ...updatedAppClientData, ...updatedSystemConnectionsData };

            delete originalBusinessArea.users;
            delete updatedBusinessArea.users;

            delete originalBusinessArea.appClients;
            delete updatedBusinessArea.appClients;

            delete originalBusinessArea.systemConnections;
            delete updatedBusinessArea.systemConnections;

            updatedBusinessArea = { ...originalBusinessArea, ...updatedBusinessArea };
            if (_.isEqual(originalBusinessArea, updatedBusinessArea) === false) {
                updateBusinessAreaData.businessArea = updatedBusinessArea;
            }

            if (updateBusinessAreaData.businessArea ||
                updateBusinessAreaData.newUsers.length > 0 ||
                updateBusinessAreaData.deletedUsers.length > 0 ||
                updateBusinessAreaData.updatedUsers.length > 0 ||
                updateBusinessAreaData.newAppClients.length > 0 ||
                updateBusinessAreaData.deletedAppClients.length > 0 ||
                updateBusinessAreaData.updatedAppClients.length > 0 ||
                updateBusinessAreaData.newSystemConnections.length > 0 ||
                updateBusinessAreaData.deletedSystemConnections.length > 0 ||
                updateBusinessAreaData.updatedSystemConnections.length > 0) {
                this.props.updateBusinessArea(this.props.businessArea.businessAreaId, updateBusinessAreaData);
            }
        }
        else {
            let { users, appClients, systemConnections } = businessArea;
            delete businessArea.users;
            delete businessArea.appClients;
            delete businessArea.systemConnections;
            this.props.createBusinessArea({ ...businessArea, active: true }, users, appClients, systemConnections);
        }
    }

    getModifiedBusinessAreaUsers = (originalUsers, modifiedUsers) => {
        let updatedBusinessAreaData = {
            newUsers: [],
            deletedUsers: [],
            updatedUsers: []

        }
        if (originalUsers && modifiedUsers) {
            let originalBusinessAreaUsersDict = originalUsers.reduce((userObject, user) => {
                userObject[user.key] = user;
                return userObject;
            }, {});

            let updatedBusinessAreaUsersDict = {};
            let newBusinessAreaUsersIndexMappings = {};
            let updatedBusinessAreaUsersIndexes = {};
            for (let index = 0; index < modifiedUsers.length; index++) {
                let businessAreaUser = { ...modifiedUsers[index] };
                if (businessAreaUser.key) {
                    updatedBusinessAreaUsersDict[businessAreaUser.key] = businessAreaUser;
                    updatedBusinessAreaUsersIndexes[businessAreaUser.key] = index;
                }
                else {
                    newBusinessAreaUsersIndexMappings[updatedBusinessAreaData.newUsers.length] = index;
                    updatedBusinessAreaData.newUsers.push(businessAreaUser);
                }
            }

            let updatedBusinessAreaUsersIndexMapping = {};
            for (let userKey in originalBusinessAreaUsersDict) {
                if (!updatedBusinessAreaUsersDict[userKey]) {
                    updatedBusinessAreaData.deletedUsers.push(originalBusinessAreaUsersDict[userKey]);
                }
                else if (_.isEqual(originalBusinessAreaUsersDict[userKey], updatedBusinessAreaUsersDict[userKey]) === false) {
                    updatedBusinessAreaUsersIndexMapping[updatedBusinessAreaData.updatedUsers.length] = updatedBusinessAreaUsersIndexes[userKey];
                    updatedBusinessAreaData.updatedUsers.push(updatedBusinessAreaUsersDict[userKey]);
                }
            }
        }
        return updatedBusinessAreaData;
    }

    getModifiedBusinessAreaAppClients = (originalAppClients, modifiedAppClients) => {
        let updatedBusinessAreaData = {
            newAppClients: [],
            deletedAppClients: [],
            updatedAppClients: []

        }
        if (originalAppClients && modifiedAppClients) {
            let originalBusinessAreaAppClientsDict = originalAppClients.reduce((appClientObject, appClient) => {
                appClientObject[appClient.key] = appClient;
                return appClientObject;
            }, {});

            let updatedBusinessAreaAppClientsDict = {};
            let newBusinessAreaAppClientsIndexMappings = {};
            let updatedBusinessAreaAppClientsIndexes = {};
            for (let index = 0; index < modifiedAppClients.length; index++) {
                let businessAreaAppClient = { ...modifiedAppClients[index] };
                if (businessAreaAppClient.key) {
                    updatedBusinessAreaAppClientsDict[businessAreaAppClient.key] = businessAreaAppClient;
                    updatedBusinessAreaAppClientsIndexes[businessAreaAppClient.key] = index;
                }
                else {
                    newBusinessAreaAppClientsIndexMappings[updatedBusinessAreaData.newAppClients.length] = index;
                    updatedBusinessAreaData.newAppClients.push(businessAreaAppClient);
                }
            }

            let updatedBusinessAreaAppClientIndexMapping = {};
            for (let appClientKey in originalBusinessAreaAppClientsDict) {
                if (!updatedBusinessAreaAppClientsDict[appClientKey]) {
                    updatedBusinessAreaData.deletedAppClients.push(originalBusinessAreaAppClientsDict[appClientKey]);
                }
                else if (_.isEqual(originalBusinessAreaAppClientsDict[appClientKey], updatedBusinessAreaAppClientsDict[appClientKey]) === false) {
                    updatedBusinessAreaAppClientIndexMapping[updatedBusinessAreaData.updatedAppClients.length] = updatedBusinessAreaAppClientsIndexes[appClientKey];
                    updatedBusinessAreaData.updatedAppClients.push(updatedBusinessAreaAppClientsDict[appClientKey]);
                }
            }
        }
        return updatedBusinessAreaData;
    }

    getModifiedBusinessAreaSystemConnectionss = (originalSystemConnections, modifiedSystemConnections) => {
        let updatedBusinessAreaData = {
            newSystemConnections: [],
            deletedSystemConnections: [],
            updatedSystemConnections: []

        }
        if (originalSystemConnections && modifiedSystemConnections) {
            let originalBusinessAreaSystemConnectionsDict = originalSystemConnections.reduce((systemConnectionObject, systemConnection) => {
                systemConnectionObject[systemConnection.key] = systemConnection;
                return systemConnectionObject;
            }, {});

            let updatedBusinessAreaSystemConnectionsDict = {};
            let newBusinessAreaSystemConnectionsIndexMappings = {};
            let updatedBusinessAreaSystemConnectionsIndexes = {};
            for (let index = 0; index < modifiedSystemConnections.length; index++) {
                let businessAreaSystemConnection = { ...modifiedSystemConnections[index] };
                if (businessAreaSystemConnection.key) {
                    updatedBusinessAreaSystemConnectionsDict[businessAreaSystemConnection.key] = businessAreaSystemConnection;
                    updatedBusinessAreaSystemConnectionsIndexes[businessAreaSystemConnection.key] = index;
                }
                else {
                    newBusinessAreaSystemConnectionsIndexMappings[updatedBusinessAreaData.newSystemConnections.length] = index;
                    updatedBusinessAreaData.newSystemConnections.push(businessAreaSystemConnection);
                }
            }

            let updatedBusinessAreaSystemConnectionIndexMapping = {};
            for (let systemConnectionKey in originalBusinessAreaSystemConnectionsDict) {
                if (!updatedBusinessAreaSystemConnectionsDict[systemConnectionKey]) {
                    updatedBusinessAreaData.deletedSystemConnections.push(originalBusinessAreaSystemConnectionsDict[systemConnectionKey]);
                }
                else if (_.isEqual(originalBusinessAreaSystemConnectionsDict[systemConnectionKey], updatedBusinessAreaSystemConnectionsDict[systemConnectionKey]) === false) {
                    updatedBusinessAreaSystemConnectionIndexMapping[updatedBusinessAreaData.updatedSystemConnections.length] = updatedBusinessAreaSystemConnectionsIndexes[systemConnectionKey];
                    updatedBusinessAreaData.updatedSystemConnections.push(updatedBusinessAreaSystemConnectionsDict[systemConnectionKey]);
                }
            }
        }
        return updatedBusinessAreaData;
    }

    onUserChange = (value, field) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`users.${field.name}.userId`];
        let users = this.formRef.current.getFieldValue("users");
        for (let index = 0; index < users.length; index++) {
            if (index !== field.name && users[index].userId === value) {
                formErrors[`users.${field.name}.userId`] = "User already added";
                break;
            }
        }
        let selectedUser = this.props.userList.find(item => item.userId === value);
        if (selectedUser) {
            users[field.name].email = selectedUser.email;
            users[field.name].firstName = selectedUser.firstName;
            users[field.name].lastName = selectedUser.lastName;
            users[field.name].version = selectedUser.version;
            this.formRef.current.setFieldsValue({
                users
            });
        }
        this.setState({
            formErrors
        });
    }

    onAppClientChange = (value, field) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`appClients.${field.name}.clientId`];
        let appClients = this.formRef.current.getFieldValue("appClients");
        for (let index = 0; index < appClients.length; index++) {
            if (index !== field.name && appClients[index].clientId === value) {
                formErrors[`appClients.${field.name}.clientId`] = "Credential already added";
                break;
            }
        }
        let selectedAppClient = this.props.appClientList.find(item => item.clientId === value);
        if (selectedAppClient) {
            appClients[field.name].clientId = selectedAppClient.clientId;
            appClients[field.name].clientName = selectedAppClient.clientName;
            appClients[field.name].active = selectedAppClient.active;
            appClients[field.name].version = selectedAppClient.version;
            this.formRef.current.setFieldsValue({
                appClients
            });
        }
        this.setState({
            formErrors
        });
    }

    onSystemConnectionChange = (value, field) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`systemConnections.${field.name}.connectionId`];
        let systemConnections = this.formRef.current.getFieldValue("systemConnections");
        for (let index = 0; index < systemConnections.length; index++) {
            if (index !== field.name && systemConnections[index].connectionId === value) {
                formErrors[`systemConnections.${field.name}.connectionId`] = "System connection already added";
                break;
            }
        }
        let selectedSystemConnection = this.props.systemConnectionList.find(item => item.connectionId === value);
        if (selectedSystemConnection) {
            systemConnections[field.name].connectionId = selectedSystemConnection.connectionId;
            systemConnections[field.name].name = selectedSystemConnection.name;
            systemConnections[field.name].description = selectedSystemConnection.description;
            systemConnections[field.name].active = selectedSystemConnection.active;
            systemConnections[field.name].type = selectedSystemConnection.type;
            systemConnections[field.name].version = selectedSystemConnection.version;
            this.formRef.current.setFieldsValue({
                systemConnections
            });
        }
        this.setState({
            formErrors
        });
    }

    validateBusinessArea = (businessArea) => {
        if (Object.keys(this.state.formErrors).length > 0) {
            return false;
        }
        let formErrors = { ...this.state.formErrors };
        for (let index = 0; index < businessArea.users.length; index++) {
            if (!businessArea.users[index].userId) {
                formErrors[`users.${index}.userId`] = "Please select a user";
            }
        }
        if (Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors
            });
            return false;
        }
        return true;
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    getFormErrors = fieldName => {
        let formErrors = { ...this.props.formErrors, ...this.state.formErrors };
        if (formErrors[fieldName]) {
            return {
                help: formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onDeleteUser = (field, callback = null) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`users.${field.name}.userId`];
        this.setState({
            formErrors
        });
        if (callback) {
            callback();
        }
    }

    onDeleteAppClient = (field, callback = null) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`appClients.${field.name}.clientId`];
        this.setState({
            formErrors
        });
        if (callback) {
            callback();
        }
    }

    onDeleteSystemConnection = (field, callback = null) => {
        let formErrors = { ...this.state.formErrors };
        delete formErrors[`systemConnections.${field.name}.connectionId`];
        this.setState({
            formErrors
        });
        if (callback) {
            callback();
        }
    }

    render() {
        if (!this.props.permission || !(this.props.permission.canEdit || this.props.permission.canAdd || this.props.permission.canView)) {
            return <ProhibitedArea></ProhibitedArea>
        }
        return (
            <Form
                ref={this.formRef}
                name="basic"
                initialValues={this.defaultBusinessArea}
                onFinish={this.onFormSubmit}
                onValuesChange={this.props.onValuesChanged}
                style={{ height: "100%" }}>
                <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }} wrap={false}>
                    <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                        <FullHeightContainerLayout
                            showHeader={true}
                            showFooter={true}
                            header={<Row>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'left' }}>
                                            <Form.Item
                                                {...this.getFormErrors("name")}
                                                label="Name"
                                                name={["name"]}
                                                validateFirst={true}
                                                rules={nameRules}>
                                                <Input
                                                    readOnly={this.isReadOnlyView()}
                                                    data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_NAME_INPUT}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                            <Form.Item
                                                label="Description"
                                                name={["description"]}
                                                rules={descriptionRules}>
                                                <Input
                                                    readOnly={this.isReadOnlyView()}
                                                    data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_DESCRIPTION_INPUT}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}

                            contentStyle={{ paddingBottom: "5px" }}
                            content={
                                <Tabs defaultActiveKey={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_USER} style={{ height: "100%" }}>
                                    <TabPane
                                        tab={<Typography.Text data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_USER}>Users</Typography.Text>}
                                        key={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_USER}
                                        style={{ height: "100%" }}
                                    >
                                        <Form.List name={["users"]}>
                                            {(users, { add, remove }) => {
                                                return <BusinessAreaUserList
                                                    readOnly={this.isReadOnlyView()}
                                                    userList={this.props.userList}
                                                    users={users}
                                                    formRef={this.formRef}
                                                    add={add}
                                                    remove={remove}
                                                    onUserChange={this.onUserChange}
                                                    onDeleteUser={this.onDeleteUser}
                                                    getFormErrors={this.getFormErrors} />;
                                            }}
                                        </Form.List>
                                    </TabPane>
                                    <TabPane
                                        tab={<Typography.Text data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_CREDENTIALS}>Credentials</Typography.Text>}
                                        key={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_CREDENTIALS}
                                        style={{ height: "100%" }}
                                    >
                                        <Form.List name={["appClients"]}>
                                            {(appClients, { add, remove }) => {
                                                return <BusinessAreaAppClientList
                                                    readOnly={this.isReadOnlyView()}
                                                    appClientList={this.props.appClientList}
                                                    appClients={appClients}
                                                    formRef={this.formRef}
                                                    add={add}
                                                    remove={remove}
                                                    onAppClientChange={this.onAppClientChange}
                                                    onDeleteAppClient={this.onDeleteAppClient}
                                                    getFormErrors={this.getFormErrors} />;
                                            }}
                                        </Form.List>
                                    </TabPane>
                                    <TabPane
                                        tab={<Typography.Text data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_SYSTEM_CONNECTIONS}>System Connections</Typography.Text>}
                                        key={CypressTestIds.MANAGE_BUSINESS_AREA_TAB_SYSTEM_CONNECTIONS}
                                        style={{ height: "100%" }}
                                    >
                                        <Form.List name={["systemConnections"]}>
                                            {(systemConnections, { add, remove }) => {
                                                return <BusinessAreaSystemConnectionsList
                                                    readOnly={this.isReadOnlyView()}
                                                    systemConnectionList={this.props.systemConnectionList}
                                                    systemConnections={systemConnections}
                                                    formRef={this.formRef}
                                                    add={add}
                                                    remove={remove}
                                                    onSystemConnectionChange={this.onSystemConnectionChange}
                                                    onDeleteSystemConnection={this.onDeleteSystemConnection}
                                                    getFormErrors={this.getFormErrors} />;
                                            }}
                                        </Form.List>
                                    </TabPane>
                                </Tabs>}
                            footer={
                                <Row>
                                    <Col span={24} className="footer-right-column">
                                        <Space>
                                            <Button
                                                onClick={this.props.onCancel}
                                                data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_CANCEL_BUTTON}
                                            >Cancel</Button>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={this.isReadOnlyView() || !this.isSaveAllowed()}
                                                data-testid={CypressTestIds.MANAGE_BUSINESS_AREA_SAVE_BUTTON}
                                            >Save</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            }>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default ManageBusinessArea