import react from 'react';
import { Row, Col, Alert, Typography } from 'antd';
import { UserAccountRecoveryForm } from 'components';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Title, Text } = Typography;

const UserAccountRecoveryView = ({ isEmailSent, recoveryType, onRecover, recoverErrors }) => {
    if (recoveryType !== "FORGOT_PASSWORD" && recoveryType !== "RESEND_VERIFICATION_LINK") {
        return <></>
    }
    return <Row className="full-height-container middle-row">
        <Col className="full-height-container" span={24}>
            <Row className="full-height-container center-align-items">
                <Col span={24}>
                    <Row>
                        <Col span={24} className="full-height-container center-align-items">
                            <Title level={3}>{recoveryType === "FORGOT_PASSWORD" ? "Forgot Your Password?" : "Resend Verification Email"}</Title>
                        </Col>
                    </Row>
                    {isEmailSent === false ?
                        <>
                            <Row className="middle-row">
                                <Col span={24} className="full-height-container center-align-items">
                                    <Text>{`Please enter your email address and we will send you an email with ${recoveryType === "FORGOT_PASSWORD" ? "password reset" : "verification"} link`}</Text>
                                </Col>
                            </Row>
                            <Row className="middle-row">
                                <Col span={24}>
                                    <UserAccountRecoveryForm onRecover={onRecover} formErrors={recoverErrors} submitText={recoveryType === "FORGOT_PASSWORD" ? "Reset" : "Send"}></UserAccountRecoveryForm>
                                </Col>
                            </Row>
                        </>
                        :
                        <Alert
                            message={<Typography.Text data-testid={CypressTestIds.USER_RESET_PASSWORD_LINK_SENT_MESSAGE}>{recoveryType === "FORGOT_PASSWORD" ? "Password reset link sent" : "Verification email link sent"}</Typography.Text>}
                            description={recoveryType === "FORGOT_PASSWORD" ? "Please check your inbox for password reset link" : "Please check your inbox for email verification link"}
                            type="success"
                            showIcon
                        />
                    }
                </Col>
            </Row>
        </Col>
    </Row>
}

export default UserAccountRecoveryView