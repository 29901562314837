import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { SchemaFieldTitle } from 'components';
import FileUpload from 'components/organisms/FileUpload';
import { FILE_TYPE_CONFIGS } from 'common/Utility';
import Tooltip from 'components/atoms/Tooltip';
import { LockOutlined } from '@ant-design/icons';

function AttachmentField({ 
  fieldErrors, 
  fieldId, 
  name, 
  description, 
  isRequired,
  maxSize,
  maxWidth,
  maxHeight,
  isMultivalue,
  tenantId,
  createdBy,
  businessAreaId,
  value,
  onChange,
  disabled,
  dataType,
  ...field 
}) {
  const [fileList, setFileList] = useState([]);
  const fileTypeConfig = FILE_TYPE_CONFIGS[dataType] || FILE_TYPE_CONFIGS.attachment;
  
  useEffect(() => {
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        const normalizedValue = Array.isArray(parsedValue) ? parsedValue : [parsedValue];
        setFileList(normalizedValue);
      } catch (err) {
        setFileList([]);
      }
    } else {
      setFileList([]);
    }
  }, [value, fieldId]);

  const handleFileChange = (newValue) => {
    try {
      const parsedValue = JSON.parse(newValue);
      const newList = Array.isArray(parsedValue) ? parsedValue : [parsedValue];
      
      setFileList(newList);
      
      if (onChange) {
        onChange(newValue);
      }
    } catch (err) {
      console.error('Error handling file change:', err);
    }
  };

  const handleFileDelete = async (keyToDelete) => {
    const updatedFiles = fileList.filter(file => file.key !== keyToDelete);
    setFileList(updatedFiles);
    
    const newValue = updatedFiles.length > 0 ? JSON.stringify(updatedFiles) : undefined;
    onChange?.(newValue);
  };

  return (
    <Form.Item
      name={fieldId}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>  
      }
      tooltip={description}
      validateFirst={true}
      rules={[
        {
          required: isRequired,
          validator: (_, value) => {
            if (isRequired && (!value || value === '[]')) {
              return Promise.reject('Please upload at least one file');
            }
            return Promise.resolve();
          }
        }
      ]}
      {...fieldErrors}
    >
      <FileUpload
        maxFileSize={maxSize}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        acceptedFileTypes={fileTypeConfig.acceptedTypes}
        fileTypeDescription={fileTypeConfig.description}
        allowMultiple={isMultivalue}
        disabled={disabled}
        tenantId={tenantId}
        createdBy={createdBy}
        businessAreaId={businessAreaId}
        fieldId={fieldId}
        onChange={handleFileChange}
        onDelete={handleFileDelete}
        value={fileList}
      />
    </Form.Item>
  );
}

export default AttachmentField;