import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Select, InputNumber, Tooltip } from "antd";
import { Segment } from 'semantic-ui-react'
import { InfoCircleTwoTone } from '@ant-design/icons';
import { SchemaMaxLengthFieldProperty, SchemaMinLengthFieldProperty, SchemaClassifiersFieldProperty } from 'components';
import fieldDataTypes from 'common/data/fieldDataTypes';
import _ from 'lodash';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Option } = Select;

const PhoneSeperatorInput = ({ value, onChange }) => {
    let phoneSeperatorDefaultValue = "";
    if (value && _.isArray(value)) {
        phoneSeperatorDefaultValue = value.join(",");
    }
    const [phoneSeperatorValue, setPhoneSeperatorValue] = useState(phoneSeperatorDefaultValue);

    const onPhoneSeperatorValueChange = (e) => {
        let seperatorValue = e.target.value;
        if (seperatorValue) {
            let phoneSeperatorArray = seperatorValue.split(',').map(item => {
                if (item.length === 0) {
                    return "";
                }

                if (!item.trim()) {
                    return "[space]";
                }
                return item.toLowerCase();
            });
            setPhoneSeperatorValue(phoneSeperatorArray.reduce((arr, item) => {
                arr.push(item);
                let set = new Set(arr);
                return [...set];
            }, []).join(","));
            onChange(phoneSeperatorArray.reduce((arr, item) => {
                if (item) {
                    arr.push(item);
                    let set = new Set(arr);
                    return [...set];
                }
                return arr;
            }, []));
        }
        else {
            setPhoneSeperatorValue("");
            onChange([]);
        }
    }

    return <Input value={phoneSeperatorValue} onChange={onPhoneSeperatorValueChange} />
}

const PhoneFieldProperties = ({ formRef, selectedFieldRow, readOnly, dataType, getFormErrors }) => {
    return (
        <>
            <Segment.Group>
                <Segment>
                    <SchemaMinLengthFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} dataType={dataType} getFormErrors={getFormErrors}></SchemaMinLengthFieldProperty>
                </Segment>
                <Segment>
                    <SchemaMaxLengthFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} dataType={dataType} getFormErrors={getFormErrors}></SchemaMaxLengthFieldProperty>
                </Segment>
                <Segment>
                    <SchemaClassifiersFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} getFormErrors={getFormErrors}></SchemaClassifiersFieldProperty>
                </Segment>
                {/* <Segment>
                    <Form.Item
                        label="Display and Export Format"
                        name={["fields", selectedFieldRow.name, "phoneFormat"]}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.phoneFormat`)}
                        initialValue={fieldDataTypes["phone"].phoneFormats[0].value}
                        rules={[
                            {
                                required: true,
                                message: `Input required`
                            }
                        ]}>
                        <Select {...(() => (readOnly ? { open: false } : {}))()}>
                            {fieldDataTypes["phone"].phoneFormats.map(format => (<Option key={format.value} value={format.value}>{format.name}</Option>))}
                        </Select>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Allowed Separators"
                        name={["fields", selectedFieldRow.name, "phoneSeparators"]}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.phoneSeparators`)}
                        initialValue={["[space]"]}
                        tooltip="Comma separated seperators. for e.g. /,[space],-,."
                        validateFirst={true}
                        rules={[
                            {
                                required: true,
                                message: `Input required`
                            },
                            {
                                validator(rule, value) {
                                    if (value && _.isArray(value)) {
                                        if (value.length === 0) {
                                            return Promise.reject("Input required");
                                        }
                                        return Promise.resolve();
                                    }

                                    return Promise.reject("Invalie value");
                                },
                            }
                        ]}>
                        <PhoneSeperatorInput />
                    </Form.Item>
                </Segment> */}
                <Segment>
                    <Form.Item
                        label="Multi Value"
                        name={["fields", selectedFieldRow.name, "isMultivalue"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_MULTIVALUE_PROPERTY_CHECKBOX}></Checkbox>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="PII"
                        name={["fields", selectedFieldRow.name, "isPII"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_PII_PROPERTY_CHECKBOX}><Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data"><InfoCircleTwoTone /></Tooltip></Checkbox>
                    </Form.Item>

                </Segment>
            </Segment.Group>
        </>
    );
};

export default PhoneFieldProperties;