import { Handle, Node, NodeProps } from "@xyflow/react";
import { useAppSelector } from "hooks";
import { memo } from "react";

type ISourceNodeDataType = {
    label: string;
    column: string;
};
export type SourceNodeType = Node<ISourceNodeDataType, "sourceNode">;

function SourceNode({
    data,
    sourcePosition,
    targetPosition,
    isConnectable,
}: NodeProps<SourceNodeType>) {
    const { data: sampleData, row } = useAppSelector(
        (state) => state.dataMapperFlow,
    );

    return (
        <>
            {isConnectable && targetPosition && (
                <Handle
                    id="handle-source-input"
                    type="target"
                    position={targetPosition}
                    className="!-left-2 !top-8 !size-4 !bg-sky-600"
                />
            )}
            <div className="flex min-h-16 min-w-40 flex-col items-center justify-center gap-1 rounded-lg rounded-tr-none bg-cyan-100 p-3">
                <span className="text-sm">{data.label}</span>
                {sampleData &&
                    sampleData.length > 0 &&
                    (sampleData[row][data.column] ? (
                        <span className="text-[0.8em] text-gray-500">
                            ({sampleData[row][data.column]})
                        </span>
                    ) : (
                        <span className="text-[0.8em] italic text-gray-400">
                            empty
                        </span>
                    ))}
                <div className="absolute -top-2 right-0 rounded-bl-lg rounded-tl-sm rounded-tr-lg bg-cyan-200 px-2 py-[0.3em] text-[0.6em]">
                    source
                </div>
            </div>
            {isConnectable && sourcePosition && (
                <Handle
                    id="handle-source-output"
                    type="source"
                    position={sourcePosition}
                    className="!-right-2 !top-8 !size-4 !bg-sky-600"
                />
            )}
        </>
    );
}

export default memo(SourceNode);
