import {
    Handle,
    Node,
    NodeProps,
    Position,
    useConnection,
    useHandleConnections,
} from "@xyflow/react";
import { memo } from "react";

export type ITargetNodeDataType = {
    field: IDataMappingField;
};

export type ITargetNodeType = Node<ITargetNodeDataType, "targetNode">;

function TargetNode(targetProps: NodeProps<ITargetNodeType>) {
    const connection = useConnection();
    const { id, isConnectable, data, targetPosition } = targetProps;

    const targetConnections = useHandleConnections({
        type: "target",
        id: "handle-target-input",
    });

    const mappedConnections = useHandleConnections({
        type: "source",
        id: "handle-target-output",
    });

    const isTarget =
        connection.inProgress &&
        connection.fromNode.id !== id &&
        connection.fromNode.type === "sourceNode";

    return (
        <>
            {isConnectable && targetPosition && (
                <Handle
                    id="handle-target-input"
                    type="target"
                    position={targetPosition}
                    className="!-left-2 !top-8 !size-4 !bg-emerald-600"
                    style={{
                        visibility:
                            targetConnections.length > 0 || isTarget
                                ? "visible"
                                : "hidden",
                    }}
                />
            )}
            <div
                className={[
                    "flex min-h-16 min-w-40 flex-col items-center justify-center gap-1 rounded-lg rounded-tr-none bg-emerald-100 p-3",
                    mappedConnections.length === 0
                        ? "outline-dashed outline-1 outline-offset-1 outline-red-400"
                        : "",
                ].join(" ")}
            >
                <span className="text-sm">{data.field.name}</span>
                {data.field.dataType && (
                    <div className="absolute -top-2 right-0 rounded-bl-lg rounded-tl-sm rounded-tr-lg bg-emerald-200 px-2 py-[0.3em] text-[0.6em]">
                        {data.field.dataType}
                    </div>
                )}
            </div>
            {isConnectable && (
                <Handle
                    id="handle-target-output"
                    type="source"
                    position={Position.Right}
                    isConnectable={targetConnections.length === 0}
                    className={[
                        "!-right-2 !top-8 flex !size-4 items-center justify-center",
                        targetConnections.length > 0
                            ? "!bg-emerald-600"
                            : "!bg-red-800",
                    ].join(" ")}
                />
            )}
        </>
    );
}

export default memo(TargetNode);
