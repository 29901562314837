import React from 'react';
import { Tooltip } from 'antd';

const ActionButton = ({ 
  icon: Icon, 
  onClick, 
  tooltip, 
  disabled = false,
  className = ""
}) => (
  <Tooltip title={tooltip}>
    <button 
      onClick={onClick}
      disabled={disabled}
      className={`p-1.5 rounded-full transition-colors disabled:opacity-50 ${className}`}
    >
      <Icon className="text-lg" />
    </button>
  </Tooltip>
);

export default ActionButton;