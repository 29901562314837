import React, { useState, useEffect } from 'react';
import { Form, DatePicker } from "antd";
import moment from 'moment';
import _ from 'lodash';
import { SchemaFieldTitle } from 'components';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';

const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const getValidDateValue = (value) => {
    let dateValue = value && moment(value, ISO_FORMAT);
    if (dateValue && dateValue.isValid() === false) {
        dateValue = null;
    }
    return dateValue;
}

const DateInput = ({ value, onChange, format, disabled  }) => {
    const [dateValue, setDateValue] = useState(getValidDateValue(value));

    useEffect(() => {
        setDateValue(getValidDateValue(value));
    }, [value]);

    const onDateValueChange = (date, dateString) => {
        setDateValue(date);
        onChange(date && date.format(ISO_FORMAT));

    }

    return <DatePicker value={dateValue} onChange={onDateValueChange} format={format} disabled={disabled} allowClear />
}

function FormDateField({ fieldErrors, fieldId, name, description, max, min, isRequired, dateFormat, dateSeparator, disabled = false, ...field }) {
    const format = `${_.replace(dateFormat, /\$/g, dateSeparator)}`;

    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
        }
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                validator(_, value) {
                    if (value && value.toString().trim()) {
                        let dateValue = moment(value, ISO_FORMAT);
                        if (dateValue.isValid() === false) {
                            return Promise.reject(`Value should be a valid date in format ${format}`);
                        }
                        if (min && dateValue.isBefore(moment(min, ISO_FORMAT))) {
                            return Promise.reject(`Value should be greater than or equal to ${moment(min, ISO_FORMAT).format(format)}`);
                        }

                        if (max && dateValue.isAfter(moment(max, ISO_FORMAT))) {
                            return Promise.reject(`Value should be less than or equal to ${moment(max, ISO_FORMAT).format(format)}`);
                        }
                    }

                    return Promise.resolve();
                },
            },
        ]}
        {...fieldErrors}>
        <DateInput format={format} disabled={disabled}/>
    </Form.Item>
}

export default FormDateField