import React, { useState, useEffect } from 'react';
import { Form, Input } from "antd";
import _ from 'lodash';
import fieldDataTypes from 'common/data/fieldDataTypes';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { SchemaFieldTitle } from 'components';
import Tooltip from '../Tooltip';
import { LockOutlined } from '@ant-design/icons';

/* const PhoneInput = ({ value, onChange }) => {
    const [phoneValue, setPhoneValue] = useState(value);

    const onPhoneValueChange = (e) => {
        let phoneNumberValue = e.target.value;
        setPhoneValue(phoneNumberValue);
        onChange(phoneNumberValue);
    }

    return <Input value={phoneValue} onChange={onPhoneValueChange} />
}

function FormPhoneField({ fieldErrors, fieldId, name, description, max, min, phoneFormat, phoneSeparators, isRequired, ...field }) {
    let phoneNumberFormat = null;
    let phoneExpression = null;
    if (phoneFormat && phoneSeparators) {
        const phoneSeparatorExpression = phoneSeparators.map(separator => {
            if (separator) {
                if (separator.toLowerCase() === "[space]") {
                    return '\\s';
                }
                else if (separator.toLowerCase() === ".") {
                    return '\\.'
                }
            }
            return separator;
        }).join('');
        phoneNumberFormat = fieldDataTypes["phone"].phoneFormats.find(format => format.value === phoneFormat);
        phoneExpression = _.replace(phoneNumberFormat.expression, /SEPARATERS/g, phoneSeparatorExpression);
    }
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={name}
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                min: min,
                message: `Value should be minimum ${min} characters long ${phoneNumberFormat && `and value should be in format ${phoneNumberFormat.name} and with separators '${phoneSeparators.join(',')}'`}`
            },
            {
                max: max,
                message: `Value should be maximum ${max} characters long ${phoneNumberFormat && `and value should be in format ${phoneNumberFormat.name} and with separators '${phoneSeparators.join(',')}'`}`
            },
            {
                validator(rule, value) {
                    if (phoneExpression) {
                        if (value && value.toString().trim()) {
                            const re = new RegExp(phoneExpression, 'im');
                            if (re.test(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(`Invalid value. Value should be in format ${phoneNumberFormat.name} and with separators '${phoneSeparators.join(',')}'`);
                        }
                    }
                    return Promise.resolve();
                },
            },
        ]}
        {...fieldErrors}>
        <PhoneInput />
    </Form.Item>
} */

const PhoneNumberInput = ({ value, onChange, disabled }) => {
    const [phoneValue, setPhoneValue] = useState(value || undefined);

    const onPhoneValueChange = (value) => {
        setPhoneValue(value);
        onChange(value);
    }

    return <PhoneInput placeholder="Enter phone number" value={phoneValue} onChange={onPhoneValueChange} />
}

function FormPhoneField({ fieldErrors, fieldId, name, description, max, min, isRequired, disabled=false, ...field }) {
    return <Form.Item
        key={fieldId}
        name={[fieldId]}
        label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SchemaFieldTitle
                        name={name}
                        tags={field.classifiers}
                        orientation="horizontal"
                        tagSummary={false}
                    />
                    {field.isPII && (
                        <Tooltip title="Masked data due to PII restriction"> 
                            <LockOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                    )}
                </div>
        }
        tooltip={description}
        validateFirst={true}
        rules={[
            {
                required: isRequired,
                message: `Value is required`,
            },
            {
                min: min,
                message: `Value should be minimum ${min} characters long`
            },
            {
                max: max,
                message: `Value should be maximum ${max} characters long`
            },
            {
                validator(rule, value) {
                    if (value && value.trim()) {
                        try {
                            const phoneNumber = parsePhoneNumber(value)
                            if (phoneNumber && (phoneNumber.isPossible() || phoneNumber.isValid())) {
                                return Promise.resolve();
                            }
                            else {
                                return Promise.reject(`Invalid value`);
                            }
                        }
                        catch {
                            return Promise.reject(`Invalid value`);
                        }
                    }
                    return Promise.resolve();
                },
            },
        ]}
        {...fieldErrors}>
        <PhoneNumberInput disabled={disabled} />
    </Form.Item>
}

export default FormPhoneField