import { ExportOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { memo } from "react";

type RowInputPanelProps = {
    label: string;
    note?: string;
    max?: number;
    onChange: (v: string) => void;
};

function RowInputPanel({ label, note, max, onChange }: RowInputPanelProps) {
    return (
        <div className="flex flex-col gap-2 rounded-lg bg-indigo-100 p-4 shadow-md">
            <div className="text-sm font-bold">{label}</div>
            <Input
                className="nodrag"
                type="number"
                size="small"
                defaultValue={1}
                onChange={(e) => onChange(e.currentTarget.value)}
                min={1}
                max={max}
            />
            {note && <div className="text-xs italic">{note}</div>}
            <Button
                size="small"
                type="primary"
                href="https://formulajs.info/functions/"
                target="_blank"
                icon={<ExportOutlined />}
                iconPosition="end"
                style={{
                    textDecoration: "none",
                    fontSize: "0.8em",
                }}
            >
                Supported Formulas
            </Button>
        </div>
    );
}

export default memo(RowInputPanel);
