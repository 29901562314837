import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, Upload, Radio, Select, Table, AutoComplete, Checkbox, Typography } from 'antd';
import CypressTestIds from "../../../cypress/CypressTestIds";

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                newPassword: '',
                confirmNewPassword: ''
            }
        };
        this.formRef = React.createRef();
        this.emptyObject = {};
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    onFormSubmit = (formData) => {
        this.props.onUpdateUserPassword(formData.newPassword);
    }

    render() {
        return (
            <Form layout="vertical"
                labelAlign="left"
                ref={this.formRef}
                name="basic"
                initialValues={this.state.form}
                onFinish={this.onFormSubmit}>
                <Form.Item
                    className="wrap-label"
                    {...this.getFormErrors("newPassword")}
                    label="New password"
                    name={["newPassword"]}
                    rules={[{ required: true, message: 'New password is required' }]}
                    hasFeedback>
                    <Input.Password
                        placeholder='*******'
                        data-testid={CypressTestIds.USER_CHANGE_PASSWORD_INPUT}
                    />
                </Form.Item>
                <Form.Item
                    className="wrap-label"
                    {...this.getFormErrors("confirmNewPassword")}
                    label="Confirm new password"
                    name={["confirmNewPassword"]}
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Confirm new password is required'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        })
                    ]}>
                    <Input.Password
                        placeholder='*******'
                        data-testid={CypressTestIds.USER_CHANGE_PASSWORD_CONFIRM_INPUT}
                    />
                </Form.Item>
                <Form.Item>
                    <Button data-testid={CypressTestIds.USER_CHANGE_PASSWORD_SUBMIT_BUTTON} block type="primary" htmlType="submit">Change Password</Button>
                </Form.Item>
            </Form>
        );
    }
}

export default ChangePassword