import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Checkbox, Select, Button, Tooltip, Space } from "antd";
import { Segment } from 'semantic-ui-react'
import { InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { StaticPickListModal, SchemaClassifiersFieldProperty } from 'components';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Option } = Select;

function usePreviousStaticPicklist(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


const PicklistFieldProperties = ({ formRef, selectedFieldRow, readOnly, getBusinessAreaDataForBusinessAreaResult, businessAreaDataList, getFormErrors, onDataDomainChange, onPicklistTypeChange }) => {
    const [showPicklistForm, setShowPicklistForm] = useState(false);
    const previousStaticPicklist = usePreviousStaticPicklist(formRef.current.getFieldValue(["fields", selectedFieldRow.name, "staticPicklist"]));
    const onDataDomainValueChange = (dataDomainId, fieldKey) => {
        onDataDomainChange(dataDomainId);
        let field = formRef.current.getFieldValue(fieldKey);
        let currentDataDomain = businessAreaDataList.find(item => item.dataDomainId === dataDomainId);
        if (currentDataDomain) {
            let dataDomainClassifiers = currentDataDomain.classifiers;
            if (dataDomainClassifiers && dataDomainClassifiers.length > 0) {
                let existingFieldClassifiers = new Set();
                let fieldClassifiers = field.classifiers ? [...field.classifiers] : [];
                if (field.classifiers && field.classifiers.length > 0) {
                    for (let classifier of field.classifiers) {
                        existingFieldClassifiers.add(classifier.tagId);
                    }
                }

                for (let classifier of dataDomainClassifiers) {
                    if (existingFieldClassifiers.has(classifier.tagId) === false) {
                        fieldClassifiers.push({ ...classifier });
                    }
                }
                if ((field.classifiers || []).length !== fieldClassifiers.length) {
                    let fields = formRef.current.getFieldValue([fieldKey[0]]);
                    if (fields && fields.length > 0) {
                        fields = [...fields];
                        fields[fieldKey[1]] = { ...field, classifiers: fieldClassifiers };
                        formRef.current.setFieldValue([fieldKey[0]], fields);
                    }
                }
            }
        }

    }
    return (
        <>
            <Form.List name={["fields", selectedFieldRow.name, "staticPicklist"]}>
                {(fields, { add, remove }, { errors }) => {
                    return <>
                        <StaticPickListModal
                            formRef={formRef}
                            fields={fields}
                            fieldErrors={errors}
                            readOnly={readOnly}
                            visible={showPicklistForm}
                            maxLength={25}
                            onAddRow={add}
                            onRemoveRow={remove}
                            onCreate={async () => {
                                try {
                                    let result = await formRef.current.validateFields();
                                    setShowPicklistForm(false);
                                    onPicklistTypeChange("static");
                                }
                                catch (error) {
                                }
                            }}
                            onCancel={() => {
                                setShowPicklistForm(false);
                                let formData = { ...formRef.current.getFieldsValue() };
                                formData["fields"] = [...formData["fields"]];
                                formData["fields"][selectedFieldRow.name] = { ...formData["fields"][selectedFieldRow.name] };
                                formData["fields"][selectedFieldRow.name]["staticPicklist"] = previousStaticPicklist;
                                formRef.current.setFieldsValue(formData);
                                onPicklistTypeChange("static");
                            }}>
                        </StaticPickListModal>
                    </>
                }}
            </Form.List>
            <Segment.Group>
                <Segment>
                    <Form.Item
                        label="Picklist Type"
                        name={["fields", selectedFieldRow.name, "picklistType"]}
                        initialValue="dynamic">
                        <Select {...(() => (readOnly ? { open: false } : {}))()} onChange={onPicklistTypeChange}>
                            <Option key={"dynamic"} value={"dynamic"}>Dynamic</Option>
                            <Option key={"static"} value={"static"}>Static</Option>
                        </Select>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues["fields"][selectedFieldRow.name]["picklistType"] !== currentValues["fields"][selectedFieldRow.name]["picklistType"]}>
                        {({ getFieldValue }) =>
                            (getFieldValue(["fields", selectedFieldRow.name, "picklistType"]) === 'dynamic') || !getFieldValue(["fields", selectedFieldRow.name, "picklistType"])
                                ?
                                <Form.Item
                                    label="Domain Data"
                                    name={["fields", selectedFieldRow.name, "dataDomainId"]}
                                    rules={[{ required: true, message: 'Please select domain data' }]}
                                    {...getFormErrors(`fields.${selectedFieldRow.name}.dataDomainId`)}>
                                    <Select loading={getBusinessAreaDataForBusinessAreaResult.inProgress} {...(() => (readOnly ? { open: false } : {}))()} onChange={(value) => onDataDomainValueChange(value, ["fields", selectedFieldRow.name])}>
                                        {businessAreaDataList.map(businessAreaData => <Option key={businessAreaData.dataDomainId} value={businessAreaData.dataDomainId}>{businessAreaData.domainName}</Option>)}
                                    </Select>
                                </Form.Item>
                                :
                                <Form.Item
                                    shouldUpdate={true}
                                    label="Picklist Values">
                                    {({ getFieldValue }) => {
                                        let picklistValues = getFieldValue(["fields", selectedFieldRow.name, "staticPicklist"]);
                                        picklistValues = (picklistValues && picklistValues.map(item => item && item.code).join(", ")) || "";
                                        return (
                                            <Space>
                                                <Input value={picklistValues} readOnly={true} />
                                                <Button
                                                    block
                                                    onClick={() => {
                                                        setShowPicklistForm(true);
                                                    }}
                                                    disabled={readOnly}>
                                                    ...
                                                </Button>
                                            </Space>
                                        )
                                    }}
                                </Form.Item>
                        }
                    </Form.Item>

                </Segment>
                <Segment>
                    <SchemaClassifiersFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} getFormErrors={getFormErrors}></SchemaClassifiersFieldProperty>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Multi Value"
                        name={["fields", selectedFieldRow.name, "isMultivalue"]}
                        valuePropName="checked">
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_MULTIVALUE_PROPERTY_CHECKBOX}></Checkbox>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="PII"
                        name={["fields", selectedFieldRow.name, "isPII"]}
                        valuePropName="checked">
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_PII_PROPERTY_CHECKBOX}><Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data"><InfoCircleTwoTone /></Tooltip></Checkbox>
                    </Form.Item>
                </Segment>
            </Segment.Group>
        </>
    );
};

export default PicklistFieldProperties;