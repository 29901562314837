import { Edge, MarkerType } from "@xyflow/react";

interface ICreateEdgeProps {
    id: string;
    source: string;
    target: string;
    sourceHandle?: string | null;
    targetHandle?: string | null;
    deletable?: boolean;
}

export default function createEdge({
    id,
    source,
    target,
    deletable = true,
    sourceHandle,
    targetHandle,
}: ICreateEdgeProps): Edge {
    return {
        id,
        source,
        target,
        sourceHandle,
        targetHandle,
        deletable,
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#b1b1b7",
        },
    };
}
