export const GET_USER_PII_PERMISSION_REQUEST = "GET_USER_PII_PERMISSION_REQUEST";
export const GET_USER_PII_PERMISSION_SUCCESS = "GET_USER_PII_PERMISSION_SUCCESS";
export const GET_USER_PII_PERMISSION_FAILURE = "GET_USER_PII_PERMISSION_FAILURE";

export const getUserPIIPermissionRequest = () => ({
    type: GET_USER_PII_PERMISSION_REQUEST,
});

export const getUserPIIPermissionSuccess = (permission) => ({
    type: GET_USER_PII_PERMISSION_SUCCESS,
    payload: { permission },
});

export const getUserPIIPermissionFailure = (error) => ({
    type: GET_USER_PII_PERMISSION_FAILURE,
    payload: { error },
});
