import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const AttachmentCell = ({ value }) => {
  if (!value) return null;
  
  let files = [];
  try {
    files = JSON.parse(value);
    if (!Array.isArray(files)) {
      files = [files];
    }
  } catch (err) {
    console.error('Error parsing attachment value:', err);
    return null;
  }

  if (files.length === 0) return null;

  return (
    <Text>{`${files.length} file(s) attached`}</Text>
  );
};

export default AttachmentCell;