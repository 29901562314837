import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Button, Space, Select, Tooltip, Alert } from 'antd';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faLock } from '@fortawesome/free-solid-svg-icons';
import CypressTestIds from "../../../cypress/CypressTestIds";

const { Option } = Select;

const keyTypesMap = {
    'Google Maps': 'GOOGLE_MAP_KEY',
    'Open AI': 'OPEN_AI_KEY',
};

const ManageKeys = ({ action, keyData, updateKey, createKey, onCancel, formErrors }) => {
    const [form] = Form.useForm();
    const [readOnlyView, setReadOnlyView] = useState(action === 'view');

    useEffect(() => {
        if (keyData) {
            form.setFieldsValue({ ...keyData });
        }
    }, [keyData, form]);

    const onFormSubmit = (formData) => {
        if (action === 'edit' && keyData) {
            formData.version = keyData.version;
        }
        const jsonData = JSON.stringify(formData);

        if (action === 'edit') {
            updateKey(jsonData, keyData.keyId);
        } else {
            createKey(jsonData);
        }
    };

    const keyRules = action === 'edit' ? [] : [{ required: true, message: 'Please input the API key!' }];

    const getFormErrors = (fieldName) => {
        return formErrors && formErrors[fieldName] ? { help: formErrors[fieldName], validateStatus: 'error' } : {};
    };

    return (
        <>
            {/* {readOnlyView && (
                <Alert
                    message="View Mode"
                    description="You are currently in view mode. All fields are read-only."
                    type="info"
                    showIcon
                    icon={<FontAwesomeIcon icon={faEye} />}
                    style={{ marginBottom: 16, borderRadius: 10, backgroundColor: '#e6f7ff', borderColor: '#91d5ff', color: '#096dd9' }}
                />
            )}
            {action === 'create' && (
                <Alert
                    message="Your API key is safe with us!"
                    description="We ensure that your API key is encrypted and securely stored. It will only be used for managing your credentials."
                    type="success"
                    showIcon
                    icon={<FontAwesomeIcon icon={faLock} />}
                    style={{ marginBottom: 16, borderRadius: 10, backgroundColor: '#f6ffed', borderColor: '#b7eb8f', color: '#389e0d' }}
                />
            )} */}
            <Form 
                labelCol={{ span: 3 }} 
                layout="horizontal" 
                labelAlign="left"
                form={form} 
                name="manageKeys" 
                onFinish={onFormSubmit} 
                initialValues={keyData}
            >
                <Row gutter={[16, 8]}>
                    <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item 
                            label="Name" 
                            name="name" 
                            rules={nameRules} 
                            {...getFormErrors('name')}
                        >
                            <Input readOnly={readOnlyView} placeholder='Enter the name' data-testid={CypressTestIds.SYSTEM_KEYS_MANAGE_NAME_INPUT} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Form.Item 
                            label="Description" 
                            name="description" 
                            rules={descriptionRules} 
                            {...getFormErrors('description')}
                        >
                            <Input readOnly={readOnlyView} placeholder='Enter the description' data-testid={CypressTestIds.SYSTEM_KEYS_MANAGE_DESCRIPTION_INPUT} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Form.Item 
                            label="Type" 
                            name="type" 
                            rules={[{ required: true, message: 'Please select the key type!' }]} 
                            {...getFormErrors('type')}
                        >
                            <Select disabled={readOnlyView || action === 'edit'} placeholder="Select the key type" data-testid={CypressTestIds.SYSTEM_KEYS_MANAGE_TYPE_SELECT} >
                                {Object.entries(keyTypesMap).map(([label, value]) => (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Form.Item 
                            label={
                                <span>
                                    API Key&nbsp;
                                    {action === "edit" && (
                                        <Tooltip title="In edit mode, entering a new API key is optional. Leave this field blank if you do not wish to change the key.">
                                            <FontAwesomeIcon icon="info-circle" style={{ color: "#3881ff" }} cursor={'pointer'} />
                                        </Tooltip>
                                    )}
                                </span>
                            }  
                            name="key" 
                            rules={keyRules}  
                            {...getFormErrors('key')}
                        >
                            <Input.Password readOnly={readOnlyView} placeholder={action === 'edit' ? 'Enter the new API key here (optional)' : 'Enter the API key here'} data-testid={CypressTestIds.SYSTEM_KEYS_MANAGE_API_KEY_INPUT} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24} className="footer-right-column">
                        <Space>
                            <Button onClick={onCancel} data-testid={CypressTestIds.SYSTEM_KEYS_MANAGE_CANCEL_BUTTON}>Cancel</Button>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                disabled={readOnlyView}
                                data-testid={CypressTestIds.SYSTEM_KEYS_MANAGE_SAVE_BUTTON}
                                >
                                Save
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default ManageKeys;
